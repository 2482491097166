import React, { useState, useEffect, useContext, useRef } from 'react'
import LoadingSpinner from '../Spinner/Spinner'
import { Button, Col, Column } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import 'bootstrap/dist/css/bootstrap.min.css'
import tapPayMessagePopupStyle from './styles/TapPayMessagePopup.module.css'
import { Typography } from 'antd';
const { Title } = Typography;

const TapPayMessagePopup = (props) => {
    const {tapPayMessagePopupShow, setTapPayMessagePopupShow, tappaySwitchContentString, tappayMessage, setTappayBtnState, tappayParam1, tappayParam2, tappayParam3} = props;
    /*
    ** tappayParam1: teamId
    ** tappayParam2: pidSelectedArray
    ** tappayParam3: userProductsArray
    */
    useEffect(() => {
        // console.log('TapPayMessagePopup tapPayMessagePopupShow: ', tapPayMessagePopupShow);
        // console.log('TapPayMessagePopup tappaySwitchContentString: ', tappaySwitchContentString);
        // console.log('TapPayMessagePopup tappayMessage: ', tappayMessage);
        // console.log('TapPayMessagePopup tappayParam1: ', tappayParam1);
        // console.log('TapPayMessagePopup ! tappayParam1: ', ! tappayParam1);
        // console.log('TapPayMessagePopup tappayParam2: ', tappayParam2);
        // console.log('TapPayMessagePopup tappayParam3: ', tappayParam3);
    }, [tapPayMessagePopupShow, tappaySwitchContentString, tappayMessage, tappayParam1, tappayParam2, tappayParam3]);
    // useEffect(() => {
    //     props.setTapPayMessagePopupShow(false);
    //   }, []);
    // const [show, setShow] = useState(tapPayMessagePopupShow);
    // const handleCloseTapPayResult = () => setShowTapPayResult(false);
    // const handleShowTapPayResult = () => setShowTapPayResult(true);
    // const [tapPayResult, setTapPayResult] = useState('');
    // const [tappayMessage, setTappayMessage] = useState('');
    return (
        <Modal
            show={tapPayMessagePopupShow}
            onHide={() => setTapPayMessagePopupShow(false)}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Col className={tapPayMessagePopupStyle.modal}>
                <div className={tapPayMessagePopupStyle.modalClose} onClick={(event)=>{setTapPayMessagePopupShow(false)}}>x</div>
                <div className={tapPayMessagePopupStyle.modalHeader} >
                    <Modal.Title className={tapPayMessagePopupStyle.modalTitle}></Modal.Title>
                    <div className={tapPayMessagePopupStyle.PopupBanner}>
                        <img src={"https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/307b61fa-e830-473f-55f4-c2446e462c00/public"}></img>
                    </div>
                </div>
                <Modal.Body className={tapPayMessagePopupStyle.modalBody} >
                    { tappaySwitchContentString === "Success" ? (
                        <div className={tapPayMessagePopupStyle.modalBodyContent}>
                            <Title level={4} className={tapPayMessagePopupStyle.titleH4}>信用卡授權成功，您已成功建立或加入團購！</Title>
                            <Title level={4} className={tapPayMessagePopupStyle.titleH4}>請將短網址發送給您的親朋好友，讓他們可以加入您的團購群組。</Title>  
                            <div className={tapPayMessagePopupStyle.modalButtonDiv}>
                                <Button className={tapPayMessagePopupStyle.modalButton} onClick={(e) => {
                                    // const createTeamSection = document.getElementById("createTeam");
                                    // createTeamSection.scrollIntoView({behavior: 'smooth'}, true);
                                    setTapPayMessagePopupShow(false)
                                    if( ! tappayParam1 ) {
                                        window.location.href = `/GroupBuy#createTeam`;
                                    } else {
                                        window.location.href = `/GroupBuy#${tappayParam1}`;
                                    }
                                }}>OK</Button>
                            </div>
                        </div>
                    ) : tappaySwitchContentString === "Failure" ? (
                        <div className={tapPayMessagePopupStyle.modalBodyContent}>
                            <Title level={4} className={tapPayMessagePopupStyle.titleH4}>信用卡授權失敗！</Title>
                            <Title level={4} className={tapPayMessagePopupStyle.titleH4}>{`( ${tappayMessage} )`}</Title>
                            <div className={tapPayMessagePopupStyle.modalButtonDiv}>
                                { ! tappayParam1 && (( Array.isArray(tappayParam2) && tappayParam2?.length > 0 ) || ( Array.isArray(tappayParam3) && tappayParam3?.length > 0 )) ? (
                                    <Button className={tapPayMessagePopupStyle.modalButton} onClick={(e) => {
                                        setTappayBtnState('CheckoutPageCreateTeam');
                                        setTapPayMessagePopupShow(false);
                                    }}>OK</Button>
                                ) : !! tappayParam1 && (( Array.isArray(tappayParam2) && tappayParam2?.length > 0 ) || ( Array.isArray(tappayParam3) && tappayParam3?.length > 0 )) ? (
                                    <Button className={tapPayMessagePopupStyle.modalButton} onClick={(e) => {
                                        setTappayBtnState('CheckoutPageAddTeam');
                                        setTapPayMessagePopupShow(false);
                                    }}>OK</Button>
                                ) : (
                                    <Button className={tapPayMessagePopupStyle.modalButton} onClick={(e) => {
                                        window.location.href = "/GroupBuy";
                                        setTapPayMessagePopupShow(false);
                                    }}>OK</Button>
                                )}
                            </div>
                        </div>
                    ) : tappaySwitchContentString === "FailureOther" ? (
                        <div className={tapPayMessagePopupStyle.modalBodyContent}>
                            <Title level={4} className={tapPayMessagePopupStyle.titleH4}>信用卡授權失敗！</Title>
                            <Title level={4} className={tapPayMessagePopupStyle.titleH4}>{`( ${tappayMessage} )`}</Title>
                            <div className={tapPayMessagePopupStyle.modalButtonDiv}>
                                <Button className={tapPayMessagePopupStyle.modalButton} onClick={(e) => {
                                    setTapPayMessagePopupShow(false)
                                }}>OK</Button>
                            </div>
                        </div>
                    ) : (
                        <div className={tapPayMessagePopupStyle.modalBodyContent}>
                            <LoadingSpinner></LoadingSpinner>                       
                            <div className={tapPayMessagePopupStyle.modalButtonDiv}> 
                                <Button className={tapPayMessagePopupStyle.modalButton} onClick={(e) => {
                                    setTapPayMessagePopupShow(false)
                                }}>關閉</Button>
                            </div>
                        </div>
                    )}                                  
                </Modal.Body>
            </Col>
        </Modal>
    );
}

export default TapPayMessagePopup
