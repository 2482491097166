import React, { useState, useEffect, useContext } from "react";
// router
import {
  HashRouter,
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";
// 活動表單頁面
import SubmitPage from "./SubmitPage/SubmitPageIndex";
// 進度條頁面
import CountingPage from "./SubmitPage/CountingPage";
// 活動宣傳頁面
import AdvertisingPage from "../src/AdvertisingPage/AdvertisingPage";
// 已參加過活動阻擋頁面
import Forbidden from "../src/SubmitPage/Forbidden";
// line login encode頁面
import LoginEncode from "../src/UaaPage/login/LoginEncode";
// 檢查使用者是否參加過活動
import CheckActivity from "../src/UaaPage/login/CheckActivity";

// IndexedDB頁面
// import IDBDemo1 from '../src/IndexedDB/demo1_createDB';
// import IDBDemo2 from '../src/IndexedDB/demo2_keyGenerator';
// import IDBDemo3 from '../src/IndexedDB/demo3_addGetDeleteData';
// import IDBDemo4 from '../src/IndexedDB/demo4_index';
// import IDBDemo5 from '../src/IndexedDB/demo5_cursor';

import "bootstrap/dist/css/bootstrap.min.css";
/* 由uaa整合的部分 */
import Container from "react-bootstrap/Container";
import Navigationbar from "./Components/Navigationbar";
import ThemeProvider from "react-bootstrap/ThemeProvider";
import Popup from "./Components/Popup";
import { Nav } from "react-bootstrap";
import navStyle from "./styles/navbar.module.css";

import InitFacebookSdk from "./UaaPage/login/components/InitFacebookSdk";

import InitLine from "./UaaPage/login/components/InitLine";

import AuthContextProvider from "./UaaPage/login/components/AuthConextProvider";

// import BackStage from './BackStage/BackStage';
import AccountExist from "./UaaPage/login/AccountExist";
import NoLineDataLogin from "./UaaPage/login/NoLineDataLogin";
import Steps from "./AdvertisingPage/StepsDEMO";
import Typography from "./AdvertisingPage/TypographyDEMO";
import GrantMembershipPage from "./GrantMembershipActivity/GrantMembershipPage";
import BindLine from "./GrantMembershipActivity/BindLine";
import GroupBuyRedir from "./GroupBuy/GroupBuyRedir";
import GroupBuy from "./GroupBuy/GroupBuy";
import GroupBuyCreateGroup from "./GroupBuy/GroupBuyCreateGroup";
import GroupBuyCheckoutCreateTeam from "./GroupBuy/GroupBuyCheckoutCreateTeam";
import GroupBuyCheckoutAddTeam from "./GroupBuy/GroupBuyCheckoutAddTeam";
import GroupBuyCheckoutAddTeamForRedirect from "./GroupBuy/GroupBuyCheckoutAddTeamForRedirect";
import GroupBuyListTeams from "./GroupBuy/GroupBuyListTeams";
import Footer from "./Components/Footer/Footer";
import NotFound from "./NotFound";

/*** UTM ***/
import UtmConfig from "./Components/Utm/UtmConfig";
import UtmConfigKidPro from "./Components/Utm/UtmConfig";

// Test
import GroupBuyCheckoutTest from "./GroupBuy/GroupBuyCheckoutTest";
import GroupBuyCheckoutTestMui from "./GroupBuy/GroupBuyCheckoutTestMui";
import GroupBuyCheckoutTestMui2 from "./GroupBuy/GroupBuyCheckoutTestMui2";

import ReactGA from "react-ga4"; // 引入React GA4
import { useCookies } from "react-cookie"; // 引入useCookies hook

// wait for facebook and Line sdk before startup
InitFacebookSdk();
InitLine();

function App() {
  /*** UTM Configuration ***/
  let Utm = "";
  let UtmKidPro = "";
  let UtmKidPro2 = "";
  const configUTM = async () => {
    try {
      Utm = await UtmConfig(
        process.env.REACT_APP_FB_PIXEL_ID,
        process.env.REACT_APP_GA4_ID
      );
      UtmKidPro = await UtmConfig(
        process.env.REACT_APP_FB_PIXEL_ID_KIDPRO,
        process.env.REACT_APP_GA4_ID_KIDPRO
      );
      UtmKidPro2 = await UtmConfig(
        process.env.REACT_APP_FB_PIXEL_ID_KIDPRO2,
        ""
      );
      // console.log({UtmKidPro})
      return { Utm, UtmKidPro, UtmKidPro2 };
    } catch (error) {
      throw new Error(error);
    }
  };
  useEffect(() => {
    configUTM();
  }, []);
  // // UtmConfigKidPro({pixelId: pixelIdKidpro, ga4Id: ga4IdKidPro});

  // /***** UTM(舊版) *****/
  // /*** Cookie設定 ***/
  // const [cookies, setCookie] = useCookies(['utm']);
  // // console.log('cookies._ga',cookies._ga);
  // /*** END Cookie設定 ***/

  // /*** GA4設定 ***/
  // const GA4ID = process.env.REACT_APP_GA4_ID;
  // const GA4Name = process.env.REACT_APP_GA4_NAME;
  // // console.log('GA4ID',GA4ID);
  // /* Ga4初始化 */
  // try{
  //   // const cid = 'mkc-' + Math.floor( 1000000000 * Math.random() );
  //   // setCookie( 'cid', cid );
  //   ReactGA.initialize( GA4ID, {
  //     gaOptions: {
  //       name: GA4Name,
  //       clientId: cookies._ga,
  //     }
  //   });
  //   // Send pageview with a custom path
  //   // ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  //   // ReactGA.send({ hitType: "scrolls", page: window.location.pathname });

  //   // ReactGA.event('login');

  //   // Send a custom event
  //   // ReactGA.event({
  //   //   category: "testCategory",
  //   //   action: "testAction",
  //   //   label: "testLabel",
  //   // });
  //   // console.log(window.location.pathname);
  //   // console.log(ReactGA);
  // }catch(e){
  //   console.log(e);
  // }
  // /* 初始化只在渲染時執行一次 */
  // // useEffect(() => {
  // //   Ga4Initial();
  // // }, []);
  // /*** END GA4設定 ***/
  // /***** END UTM *****/

  return (
    <>
      <BrowserRouter>
        <AuthContextProvider>
          <ThemeProvider
            breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xxs"]}
            minBreakpoint="xxs"
          >
            <section className={navStyle.navbarSec}>
              <Navigationbar></Navigationbar>
              <Popup></Popup>
            </section>
            <Routes>
              {/* 首頁 */}
              {/* <Route path="/" element={<AdvertisingPage />} /> */}
              <Route path="/" element={<GroupBuyRedir />} />

              <Route path="*" element={<NotFound />} />
              <Route path="/AccountExist" element={<AccountExist />} />
              <Route path="/NoLineDataLogin" element={<NoLineDataLogin />} />
              <Route path="/LoginEncode" element={<LoginEncode />} />

              {/* 瑜伽球活動 */}
              {/* <Route path="/CountingPage" element={<CountingPage />} />
              <Route path="/SubmitPage" element={<SubmitPage />} />
              <Route path="/Forbidden" element={<Forbidden />} />
              <Route path="/CheckActivity" element={<CheckActivity />} />
              <Route path="/Steps" element={<Steps />} />
              <Route path="/Typography" element={<Typography />} /> */}

              {/* 綁定LINE送銀卡會員 */}
              {/* <Route path="/GrantMembership" element={<GrantMembershipPage />} />
                  <Route path="/BindLine" element={<BindLine />} />
                  <Route path="/GroupBuyListTeams" element={<GroupBuyListTeams />} /> */}

              {/* 團購活動 */}
              <Route path="/GroupBuy" element={<GroupBuy />} />
              <Route
                path="/GroupBuyCreateGroup"
                element={<GroupBuyCreateGroup />}
              />
              <Route
                path="/GroupBuyCheckoutCreateTeam"
                element={<GroupBuyCheckoutCreateTeam />}
              />
              <Route
                path="/GroupBuyCheckoutAddTeam"
                element={<GroupBuyCheckoutAddTeam />}
              />
              <Route
                path="/GroupBuyCheckout"
                element={<GroupBuyCheckoutAddTeamForRedirect />}
              />

              {/* <Route path="/BackStage" element={<BackStage />} /> */}
              {/* <Route path="/GroupBuyCheckoutTest" element={<GroupBuyCheckoutTest />} /> */}
              {/* <Route path="/GroupBuyCheckoutCreateTeam" element={<GroupBuyCheckoutCreateTeam />} /> */}
              {/* <Route path="/GroupBuyCheckoutTestMui" element={<GroupBuyCheckoutTestMui />} />
                  <Route path="/GroupBuyCheckoutTestMui2" element={<GroupBuyCheckoutTestMui2 />} /> */}
              {/* <Route path="/Footer" element={<Footer />} /> */}
              {/* <Route path="/IDBDemo1" element={<IDBDemo1 />} />
                  <Route path="/IDBDemo2" element={<IDBDemo2 />} />
                  <Route path="/IDBDemo3" element={<IDBDemo3 />} />
                  <Route path="/IDBDemo4" element={<IDBDemo4 />} />
                  <Route path="/IDBDemo5" element={<IDBDemo5 />} /> */}
            </Routes>
          </ThemeProvider>
        </AuthContextProvider>
      </BrowserRouter>
    </>
  );
}
export default App;
