import React, { useState, useContext, useEffect, useRef } from "react";
import qs from "qs";
import AuthContext from "../UaaPage/login/components/AuthContext";
import style from "./styles/GroupBuyListTeams.module.css";
import "./styles/GroupBuyListTeams.css";
import "./styles/AdvertisingPage.css";
import "./styles/antStyle.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import LoadingSpinner from "../Components/Spinner/Spinner";
import Modal from "react-bootstrap/Modal";
import popupStyle from "../Components/styles/popup.module.css";
import LoginParent from "../UaaPage/login/LoginParent";
import "bootstrap/dist/css/bootstrap.min.css";
import CheckMark from "./components/CheckMark";
import * as Scroll from "react-scroll";
import {
  Element,
  Events,
  animateScroll as scroll,
  scroller,
} from "react-scroll";
import dayjs from "dayjs";
// import MemberSvg from './imgs/member.svg'

/*** GroupBuy Page ***/
import GroupBuyCreateGroup from "./GroupBuyCreateGroup";
import GroupBuyCheckoutCreateTeam from "./GroupBuyCheckoutCreateTeam";
import GroupBuyCheckoutAddTeam from "./GroupBuyCheckoutAddTeam";
import jwt_decode from "jwt-decode";

/***  antd ***/
import {
  Steps,
  Typography,
  Layout,
  Collapse,
  Button as AntButton,
  Table,
  Space,
  Checkbox,
} from "antd";
import {
  CaretUpOutlined,
  CaretDownOutlined,
  CaretUpFilled,
} from "@ant-design/icons";
import "./styles/antStyle.css";

import { useCookies } from "react-cookie"; // 引入useCookies hook
import ReactGA from "react-ga4"; // GA4
import ReactPixel from "react-facebook-pixel"; // FB Pixel

import liff from "@line/liff"; // liff SDK
import Footer from "../Components/Footer/Footer";

/*** UTM ***/
import UtmConfig from "../Components/Utm/UtmConfig";
import UtmEvents, {
  utmEventsInit,
  utmPageView,
} from "../Components/Utm/UtmEvents";
import { ErrorMessage } from "formik";

function GroupBuyListTeams(props) {
  const { Sheet, setSheet, gbPage, setGbPage } = props;
  const {
    authorized,
    email,
    setEmail,
    auth,
    setAuth,
    userNormalLoggedIn,
    setUserNormalLoggedIn,
  } = useContext(AuthContext);
  const [NOuaabindLine, setNOuaabindLine] = useState(false); // 設定登入頁面line login 無綁定uaa
  const [Loginparent, setLoginparent] = useState(0); // 設定login parent
  const [LoginToken, setLoginToken] = useState(""); // 設定login token
  const [RegxpError, setRegxpError] = useState(""); // 顯示錯誤訊息

  const backendURL = process.env.REACT_APP_BACKENDURL; // 首頁URL
  const apiURL = process.env.REACT_APP_APIURL; // API URL
  const microservApiURL = process.env.REACT_APP_MICROSERV_APIURL; // microserve API URL
  let LIFF_STORE = window.localStorage.getItem("LIFF_STORE");
  let LIFF_STOREregister = window.localStorage.getItem("LIFF_STOREregister");
  const lineAccessToken = !!window.localStorage.getItem("lat")
    ? window.localStorage.getItem("lat")
    : "";
  const [ShowCheckMark, setShowCheckMark] = useState(false); // 設定copy按鈕反饋checkMark
  const [loginTokenState, setloginTokenState] = useState("");
  const [pidSelectedArray, setPidSelectedArray] = useState([]); // 設定選擇的課程pid
  const [userProductsArray, setUserProductsArray] = useState([]); // 設定使用者已購買的課程pid
  const [orderNum, setOrderNum] = useState(undefined); // 設定訂單編號(tappay驗證錯誤重刷)

  /***  Ant API ***/
  const { Title } = Typography; // 標題
  const { Content } = Layout; // 主要內容區塊(Layout本身是組件，也可當API使用)
  const { StepItem } = Steps;
  const { Panel } = Collapse;

  const kidprologinURL = process.env.REACT_APP_KIDPROLOGINURL; // KidPro 登入導向URL
  const uaaApiURL = process.env.REACT_APP_UAA_APIURL; // UAA API URL
  const lineid = process.env.REACT_APP_LINE_APP_ID;
  const kidprocertiURL = "https://kid-pro.com/my-account/my-certificates/"; // KidPro 完課證明頁面
  const cc02URL = "https://kid-pro.com/product/cc-0-2/";
  const si02URL = "https://kid-pro.com/product/si0-2/";
  const si212URL = "https://kid-pro.com/product/si2-12/";
  const ae31URL = "https://kid-pro.com/product/ae3-1/";

  let loginToken = window.localStorage.getItem("loginToken")
    ? window.localStorage.getItem("loginToken")
    : ""; // 取得Login Token
  let gotoKidproCerti = window.localStorage.getItem("loginToken")
    ? kidprologinURL + loginToken + "&redirectUrl=" + kidprocertiURL
    : kidprocertiURL; // 若Login Token存在，則KidPro LOGO導向首頁並登入；否則僅導向完課證明頁面
  const userNormalLoggedInStorage =
    window.localStorage.getItem("userNormalLoggedIn"); // 知道使用者是否先前是用一般登入
  let cc02 = window.localStorage.getItem("loginToken")
    ? kidprologinURL + loginToken + "&redirectUrl=" + cc02URL
    : cc02URL;
  let si02 = window.localStorage.getItem("loginToken")
    ? kidprologinURL + loginToken + "&redirectUrl=" + si02URL
    : si02URL;
  let si212 = window.localStorage.getItem("loginToken")
    ? kidprologinURL + loginToken + "&redirectUrl=" + si212URL
    : si212URL;
  let ae31 = window.localStorage.getItem("loginToken")
    ? kidprologinURL + loginToken + "&redirectUrl=" + ae31URL
    : ae31URL;

  /***** Ant Table *****/
  const [data, setData] = useState([
    {
      amount: NaN,
      createdAt: "",
      discount: NaN,
      members: [],
      products: [],
      shortUrlCode: "",
      teamName: "",
      _id: "",
    },
  ]); // 放入Table的資料狀態
  useEffect(() => {
    // // console.log('data', data)
  }, [data]);
  const [loading, setLoading] = useState(false); // Table Loading Spinner
  const [tableParams, setTableParams] = useState({
    // 頁碼轉換
    pagination: {
      current: 1, // 目前頁碼
      pageSize: 5, // 一頁幾筆
    },
  });
  const [filterDropdownOpenStatus, setFilterDropdownOpenStatus] =
    useState(false); // 受控篩選表單顯示狀態
  const [filteredInfo, setFilteredInfo] = useState({}); // 受控篩選值狀態
  useEffect(() => {
    // // console.log('filteredInfo', filteredInfo);
  }, [filteredInfo]);
  const [sortedInfo, setSortedInfo] = useState({}); // 受控排序狀態
  useEffect(() => {
    // // console.log('sortedInfo', sortedInfo);
  }, [sortedInfo]);
  /*** ACSEND DESCEND 狀態，用於顯示ICON向上或向下 ***/
  const [timeOrder, setTimeOrder] = useState("ASC");
  const [memberOrder, setMemberOrder] = useState("DESC");
  const [courseOrder, setCourseOrder] = useState("ASC");

  /*** 課程篩選器選取狀態 ***/
  const [courseCheckboxStatusTemp, setCourseCheckboxStatusTemp] = useState([]);
  useEffect(() => {
    // console.log('courseCheckboxStatusTemp',courseCheckboxStatusTemp)
  }, [courseCheckboxStatusTemp]);
  const [courseCheckboxStatus, setCourseCheckboxStatus] = useState([]);
  useEffect(() => {
    // console.log('courseCheckboxStatus',courseCheckboxStatus)
  }, [courseCheckboxStatus]);

  /*** 改重置按鈕文字 ***/
  const changeResetBtnText = () => {
    // // console.log("changeResetBtnText");
    const antBtn = document?.querySelector(".ant-btn-link");
    if (antBtn?.innerText === "Reset") {
      antBtn.innerHTML = "<span>重置</span>";
    }
  };
  // useEffect(() => {
  //   changeResetBtnText();
  // }, [])

  // const dataTest = [
  //   {
  //     key: "1",
  //     name: "John Brown",
  //     age: 32,
  //     address: "New York No. 1 Lake Park"
  //   },
  //   {
  //     key: "2",
  //     name: "Jim Green",
  //     age: 42,
  //     address: "London No. 1 Lake Park"
  //   },
  //   {
  //     key: "3",
  //     name: "Joe Black",
  //     age: 32,
  //     address: "Sydney No. 1 Lake Park"
  //   },
  //   {
  //     key: "4",
  //     name: "Jim Red",
  //     age: 32,
  //     address: "London No. 2 Lake Park"
  //   }
  // ];

  /*** 取得未成團資料 ***/
  const fetchTeamsList = async (pIds) => {
    setLoading(true);
    const BearerToken = "Bearer " + loginToken;
    const createdTimeAsc = true;
    const memberCountAsc = false;
    // *   33589 嬰幼兒 父母必修課
    // *   20307 嬰幼兒 感覺統合啟蒙課程
    // *   100358 兒童 感覺統合訓練課程
    // *   60464 兒童 注意力與執行力訓練課程( 附教具 )
    // *   231206 兒童/成人 玩美臉型自信開口 全方位口肌訓練課程( 附教具 )
    // const courseIds = ['33589', '20307', '100358', '60464', '231206'];
    const courseIds = pIds ?? courseCheckboxStatus ?? [];
    const limit = 600; // 最多撈出幾筆
    const params = new URLSearchParams();
    params.append("createdTimeAsc", createdTimeAsc);
    params.append("memberCountAsc", memberCountAsc);
    for (const courseId of courseIds) {
      params.append("courseIds", courseId);
    }
    params.append("limit", limit);
    const queryString = params.toString();
    // // // console.log(queryString);
    // const getUrlString = new URLSearchParams(queryString);
    // const getCourseIds = getUrlString.getAll('courseIds')
    // // // console.log("getCourseIds", getCourseIds)
    await fetch("https://" + microservApiURL + "/team/notfull?" + queryString, {
      method: "GET",
      headers: {
        Authorization: BearerToken,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json())
      .then((result) => {
        // // console.log('fetchTeamsList', result);
        // result[2]?.members?.push({userId: '12345'}); // 測試用
        // result[0]?.products?.pop(); // 測試用
        // result[0]?.products?.pop(); // 測試用
        // result[0]?.products?.pop(); // 測試用
        // result[0]?.products?.pop(); // 測試用
        // result[1]?.products?.pop(); // 測試用
        // result[1]?.products?.pop(); // 測試用
        // result[1]?.products?.pop(); // 測試用
        // result[2]?.products?.pop(); // 測試用
        // result[2]?.products?.pop(); // 測試用
        // result[3]?.products?.pop(); // 測試用
        setData(result?.thisPageData);
        // setData(dataTest); // 測試用
        setLoading(false);
      })
      .catch((error) => {
        // // console.log('error',error);
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchTeamsList();
  }, []);
  useEffect(() => {
    fetchTeamsList();
  }, [courseCheckboxStatus]);

  // const fetchData = async() => {
  //   // // console.log('tableParams', tableParams)
  //   // // console.log('getRandomuserParams(tableParams)', getRandomuserParams(tableParams))
  //   // // console.log('qs.stringify(getRandomuserParams(tableParams)', qs.stringify(getRandomuserParams(tableParams)))
  //   fetch(`https://randomuser.me/api?${qs.stringify(getRandomuserParams(tableParams))}`)
  //   .then((res) => res.json())
  //   .then(({ results }) => {
  //     // // console.log(results);
  //     setData(results);
  //     setLoading(false);
  //     setTableParams({
  //       ...tableParams,
  //       pagination: {
  //         ...tableParams.pagination,
  //         total: 200,
  //         // 200 is mock data, you should read it from server
  //         // total: data.totalCount,
  //       },
  //     });
  //   });
  // }
  // useEffect(() => {
  //   fetchData();
  // }, [JSON.stringify(tableParams)]);

  /*** 課程篩選器Checkbox內容 ***/
  const courseCheckboxOptions = [
    { label: "嬰幼兒 父母必修課", value: "33589" },
    { label: "嬰幼兒 感覺統合啟蒙課程", value: "20307" },
    { label: "兒童 感覺統合訓練課程", value: "100358" },
    { label: "兒童 注意力與執行力訓練課程( 附教具 )", value: "60464" },
    {
      label: "兒童/成人 玩美臉型自信開口 全方位口肌訓練課程( 附教具 )",
      value: "231206",
    },
  ];

  /*** 課程篩選器變化時觸發 ***/
  const courseCheckboxOnChange = (values) => {
    setCourseCheckboxStatusTemp(values);
  };

  /*** 課程篩選器重置按鈕觸發 ***/
  const courseCheckboxReset = (e) => {
    e?.preventDefault();
    setCourseCheckboxStatusTemp([]);
    setCourseCheckboxStatus([]);
  };

  /*** 課程篩選器重置按鈕觸發 ***/
  const courseCheckboxConfirm = (e) => {
    e?.preventDefault();
    setCourseCheckboxStatus(courseCheckboxStatusTemp);
  };

  /*** 自定義篩選器 ***/
  const customizeFilter = (
    <>
      <Checkbox.Group
        className={style.gbCustomizeFilter}
        options={courseCheckboxOptions}
        value={courseCheckboxStatusTemp}
        onChange={courseCheckboxOnChange}
      />
      <div className={style.gbCustomizeFilterBtnDiv}>
        <AntButton
          className={style.gbCustomizeFilterBtn}
          size="small"
          onClick={(e) => {
            courseCheckboxReset(e);
          }}
        >
          重置
        </AntButton>
        <AntButton
          className={style.gbCustomizeFilterBtn}
          size="small"
          onClick={(e) => {
            courseCheckboxConfirm(e);
          }}
        >
          確認
        </AntButton>
      </div>
      {/* <div class="ant-dropdown css-dev-only-do-not-override-j0nf2s ant-dropdown-placement-bottomRight " style="min-width: 22px; left: 6px; top: 914px;">
      <div class="ant-table-filter-dropdown">
        <ul class="ant-dropdown-menu ant-dropdown-menu-root ant-dropdown-menu-vertical ant-dropdown-menu-light ant-dropdown-menu-without-submenu css-dev-only-do-not-override-j0nf2s" role="menu" tabindex="0" data-menu-list="true">
          <li class="ant-dropdown-menu-item" role="menuitem" tabindex="-1" data-menu-id="rc-menu-uuid-78872-1-33589">
            <span class="ant-dropdown-menu-title-content">
              <label class="ant-checkbox-wrapper css-dev-only-do-not-override-j0nf2s">
                <span class="ant-checkbox css-dev-only-do-not-override-j0nf2s">
                  <input type="checkbox" class="ant-checkbox-input" value=""></input>
                  <span class="ant-checkbox-inner"></span>
                </span>
              </label>
              <span>嬰幼兒 父母必修課</span>
            </span>
          </li>
          <li class="ant-dropdown-menu-item" role="menuitem" tabindex="-1" data-menu-id="rc-menu-uuid-78872-1-20307">
            <span class="ant-dropdown-menu-title-content">
              <label class="ant-checkbox-wrapper css-dev-only-do-not-override-j0nf2s">
                <span class="ant-checkbox css-dev-only-do-not-override-j0nf2s">
                  <input type="checkbox" class="ant-checkbox-input" value=""></input>
                  <span class="ant-checkbox-inner"></span>
                </span>
              </label>
            <span>嬰幼兒 感覺統合啟蒙課程</span>
            </span>
          </li>
          <li class="ant-dropdown-menu-item" role="menuitem" tabindex="-1" data-menu-id="rc-menu-uuid-78872-1-100358">
            <span class="ant-dropdown-menu-title-content">
              <label class="ant-checkbox-wrapper css-dev-only-do-not-override-j0nf2s">
                <span class="ant-checkbox css-dev-only-do-not-override-j0nf2s">
                  <input type="checkbox" class="ant-checkbox-input" value=""></input>
                  <span class="ant-checkbox-inner"></span>
                </span>
              </label>
              <span>兒童 感覺統合訓練課程</span>
            </span>
          </li>
          <li class="ant-dropdown-menu-item" role="menuitem" tabindex="-1" data-menu-id="rc-menu-uuid-78872-1-60464">
            <span class="ant-dropdown-menu-title-content">
              <label class="ant-checkbox-wrapper css-dev-only-do-not-override-j0nf2s">
                <span class="ant-checkbox css-dev-only-do-not-override-j0nf2s">
                  <input type="checkbox" class="ant-checkbox-input" value=""></input>
                    <span class="ant-checkbox-inner"></span>
                  </span>
              </label>
              <span>兒童 注意力與執行力訓練課程( 附教具 )</span>
            </span>
          </li>
          <li class="ant-dropdown-menu-item" role="menuitem" tabindex="-1" data-menu-id="rc-menu-uuid-78872-1-231206">
            <span class="ant-dropdown-menu-title-content">
              <label class="ant-checkbox-wrapper css-dev-only-do-not-override-j0nf2s">
                <span class="ant-checkbox css-dev-only-do-not-override-j0nf2s">
                  <input type="checkbox" class="ant-checkbox-input" value=""></input>
                    <span class="ant-checkbox-inner"></span>
                </span>
              </label>
              <span>兒童/成人 玩美臉型自信開口 全方位口肌訓練課程( 附教具 )</span>
            </span>
          </li>
        </ul>
        <div aria-hidden="true" style="display: none;"></div>
        <div class="ant-table-filter-dropdown-btns">
          <button type="button" class="ant-btn css-dev-only-do-not-override-j0nf2s ant-btn-link ant-btn-sm" disabled="">
            <span>重置</span>
          </button>
          <button type="button" class="ant-btn css-dev-only-do-not-override-j0nf2s ant-btn-primary ant-btn-sm">
            <span>OK</span>
          </button>
        </div>
      </div>
    </div> */}
    </>
  );

  /*** Table 點選頁碼、篩選、排序(按OK或重置)時觸發  ***/
  const handleTableChange = (pagination, filters, sorter) => {
    // console.log({pagination, filters, sorter})
    // changeResetBtnText(); // 改變Reset Button文字
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
    // `dataSource` is useless since `pageSize` changed
    // // console.log(filters?.courseIds);
    fetchTeamsList(filters?.courseIds);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  // const setAgeSort = () => {
  //   if( ! sortedInfo?.order || sortedInfo?.order === "descend" ) {
  //     setSortedInfo({
  //       order: 'ascend',
  //       columnKey: 'age',
  //     });
  //   } else {
  //     setSortedInfo({
  //       order: 'descend',
  //       columnKey: 'age',
  //     });
  //   }
  // };

  /*** 時間排序功能 ***/
  const setTimeSort = () => {
    // // console.log("setTimeSort: sortedInfo?.order", sortedInfo?.order)
    if (!sortedInfo?.order || sortedInfo?.order === "descend") {
      setSortedInfo({
        order: "ascend",
        columnKey: "createdAt",
      });
      setTimeOrder("ASC");
    } else {
      setSortedInfo({
        order: "descend",
        columnKey: "createdAt",
      });
      setTimeOrder("DESC");
    }
  };

  /*** 人數排序功能 ***/
  const setMemberCountSort = () => {
    // // console.log("setMemberCountSort: sortedInfo?.order", sortedInfo?.order)
    if (!sortedInfo?.order || sortedInfo?.order === "ascend") {
      setSortedInfo({
        order: "descend",
        columnKey: "memberCount",
      });
      setMemberOrder("DESC");
    } else {
      setSortedInfo({
        order: "ascend",
        columnKey: "memberCount",
      });
      setMemberOrder("ASC");
    }
  };

  /*** 課程數量排序功能 ***/
  const setCourseCountSort = (order) => {
    // // console.log("setMemberCountSort: sortedInfo?.order", sortedInfo?.order)
    if (!order) {
      if (!sortedInfo?.order || sortedInfo?.order === "descend") {
        setSortedInfo({
          order: "ascend",
          columnKey: "courseCount",
        });
        setCourseOrder("ASC");
      } else {
        setSortedInfo({
          order: "descend",
          columnKey: "courseCount",
        });
        setCourseOrder("DESC");
      }
    } else {
      setSortedInfo({
        order: order,
        columnKey: "courseCount",
      });
      if (order === "ascend") {
        setCourseOrder("ASC");
      } else {
        setCourseOrder("DESC");
      }
    }
  };
  useEffect(() => {
    // 預設課程數遞增
    setCourseCountSort("ascend");
  }, [JSON.stringify(data)]);

  /*** 依課程名稱篩選 ***/
  const filterCourse = () => {
    setFilterDropdownOpenStatus(!filterDropdownOpenStatus);
    // // console.log(filterDropdownOpen)
    // // // console.log("sortedInfo?.order", sortedInfo?.order)
    // if( ! sortedInfo?.order || sortedInfo?.order === "descend" ) {
    //   setSortedInfo({
    //     order: 'ascend',
    //     columnKey: 'createdAt',
    //   });
    // } else {
    //   setSortedInfo({
    //     order: 'descend',
    //     columnKey: 'createdAt',
    //   });
    // }
  };
  // const timeList = [dayjs('2018-04-04T16:00:00.000Z'), dayjs('2019-04-04T16:00:00.000Z'), dayjs('2020-04-04T16:00:00.000Z')];
  // useEffect(() => {
  //   // // console.log('timeList', timeList);
  // }, [timeList])
  // *   33589 嬰幼兒 父母必修課
  // *   20307 嬰幼兒 感覺統合啟蒙課程
  // *   100358 兒童 感覺統合訓練課程
  // *   60464 兒童 注意力與執行力訓練課程( 附教具 )
  // *   231206 兒童/成人 玩美臉型自信開口 全方位口肌訓練課程( 附教具 )
  // const courseList = {
  //   '33589': <img className={`${style.gbImgCoursePhone}`} src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/8d6b5aa3-343e-4635-7133-c77801ab8200/public" onClick={() => { window.open("https://kid-pro.com/product/cc-0-2/") }}></img>,
  //   '20307': <img className={style.gbImgCoursePhone} src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/8b0380e3-4473-41f5-40df-5f19d1262200/public" onClick={() => { window.open("https://kid-pro.com/product/si0-2/") }}></img>,
  //   '100358': <img className={style.gbImgCoursePhone} src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/16fe41e6-39b5-4739-ec3f-b9014e474800/public" onClick={() => { window.open("https://kid-pro.com/product/si-2-12/") }}></img>,
  //   '60464': <img className={style.gbImgCoursePhone} src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/f75dbe6d-2a89-457b-3018-e10af6302700/public" onClick={() => { window.open("https://kid-pro.com/product/ae3-1/") }}></img>,
  //   '231206': <img className={style.gbImgCoursePhone} src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/ac858e5e-5d4f-4d8f-31d2-9f5a64842800/public" onClick={() => { window.open("https://kid-pro.com/product/oral-2-12/") }}></img>,
  // };
  // const courseList = {
  //   '33589': <span className={`${style.gbTableLink}`} onClick={() => { window.open("https://kid-pro.com/product/cc-0-2/") }}>．嬰幼兒 父母必修課</span>,
  //   '20307': <span className={style.gbTableLink} onClick={() => { window.open("https://kid-pro.com/product/si0-2/") }}>．嬰幼兒 感覺統合啟蒙課程</span>,
  //   '100358': <span className={style.gbTableLink} onClick={() => { window.open("https://kid-pro.com/product/si-2-12/") }}>．兒童 感覺統合訓練課程</span>,
  //   '60464': <span className={style.gbTableLink} onClick={() => { window.open("https://kid-pro.com/product/ae3-1/") }}>．兒童 注意力與執行力訓練課程（附教具）</span>,
  //   '231206': <span className={style.gbTableLink} onClick={() => { window.open("https://kid-pro.com/product/oral-2-12/") }}>．兒童/成人 玩美臉型自信開口 全方位口肌訓練課程（附教具）</span>,
  // };
  /*** Table課程列表 ***/
  const courseList = {
    33589: <span className={`${style.gbTableSpan}`}>．嬰幼兒 父母必修課</span>,
    20307: <span className={style.gbTableSpan}>．嬰幼兒 感覺統合啟蒙課程</span>,
    100358: <span className={style.gbTableSpan}>．兒童 感覺統合訓練課程</span>,
    60464: (
      <span className={style.gbTableSpan}>
        ．兒童 注意力與執行力訓練課程（附教具）
      </span>
    ),
    231206: (
      <span className={style.gbTableSpan}>
        ．兒童/成人 玩美臉型自信開口 全方位口肌訓練課程（附教具）
      </span>
    ),
  };
  /*** Table縱向欄位設定 ***/
  const columns = [
    // {
    //   title: "Age",
    //   dataIndex: "age",
    //   key: "age",
    //   sorter: (a, b) => a.age - b.age,
    //   sortOrder: sortedInfo.columnKey === "age" ? sortedInfo.order : null,
    //   ellipsis: true
    // },
    {
      dataIndex: "createdAt",
      key: "createdAt",
      showSorterTooltip: false,
      sorter: (a, b) => +dayjs(a?.createdAt) - +dayjs(b?.createdAt),
      sortOrder: sortedInfo.columnKey === "createdAt" ? sortedInfo.order : null,
      // render: (createdAt) => <>{dayjs(createdAt).format('YYYY-MM-DD HH:mm')}</>,
      render: (createdAt) => <></>,
      width: "0",
    },
    {
      key: "memberCount",
      showSorterTooltip: false,
      sorter: (a, b) => a?.members?.length - b?.members?.length,
      sortOrder:
        sortedInfo.columnKey === "memberCount" ? sortedInfo.order : null,
      render: (members) => <></>,
      width: "0",
    },
    {
      key: "courseCount",
      showSorterTooltip: false,
      sorter: (a, b) => a?.products?.length - b?.products?.length,
      sortOrder:
        sortedInfo.columnKey === "courseCount" ? sortedInfo.order : null,
      render: (products) => <></>,
      width: "0",
    },
    {
      title: (
        <Title level={3} className={style.titleH3_7}>
          課程
        </Title>
      ),
      dataIndex: "products",
      key: "courseIds",
      showSorterTooltip: false,
      filters: [
        { text: "嬰幼兒 父母必修課", value: "33589" },
        { text: "嬰幼兒 感覺統合啟蒙課程", value: "20307" },
        { text: "兒童 感覺統合訓練課程", value: "100358" },
        { text: "兒童 注意力與執行力訓練課程( 附教具 )", value: "60464" },
        {
          text: "兒童/成人 玩美臉型自信開口 全方位口肌訓練課程( 附教具 )",
          value: "231206",
        },
      ],
      // 受控filter
      // filteredValue: filteredInfo.courseIds || null,
      // onFilter: (value, record) => {
      //   // // console.log({value, record})
      //   if(Array.isArray(record?.products)) {
      //     const filteredProducts = record?.products?.filter((product) => product?.id === value);
      //     return filteredProducts?.length > 0;
      //   }
      // },
      onFilterDropdownOpenChange: (visible) => {
        changeResetBtnText();
      }, // 自定義篩選表顯示時，改reset為重置
      render: (products) => (
        <div className={style.gbCourseListDiv}>
          {Array.isArray(products) &&
            products?.map((product) => courseList[product?.id])}
        </div>
      ),
      width: "45%",
      ellipsis: true,
    },
    // {
    //   dataIndex: 'memberCount',
    //   sorter: true,
    //   render: (memberCount) => <></>,
    // },
    // {
    //   dataIndex: 'courseId',
    //   sorter: true,
    //   render: (courseId) => <></>,
    // },
    {
      title: (
        <Title level={3} className={style.titleH3_7}>
          折數
        </Title>
      ),
      dataIndex: "discount",
      key: "discount",
      render: (discount) => (
        <Title level={3} className={style.titleH3_8}>
          {discount ? Math.round(discount * 100) / 10 : NaN}折
        </Title>
      ),
      width: "15%",
      ellipsis: true,
    },
    {
      title: (
        <Title level={3} className={style.titleH3_7}>
          成員人數
        </Title>
      ),
      dataIndex: "members",
      key: "memberCount",
      showSorterTooltip: false,
      render: (members, record) => (
        <div className={style.gbMemberDiv}>
          <div className={style.gbMemberCount}>
            {members.map(() => (
              <img
                className={style.gbMemberSvg}
                src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/624edcff-ca4d-485d-6957-5461c15af700/public"
                alt="member"
              />
            ))}
          </div>
          <div className={style.gbMemberGroupId}>
            <div className={style.gbMemberGroupIdTitle}>團購編號 :</div>
            <div title={record?.teamName} className={style.gbMemberGroupIdText}>
              {record?.teamName}
            </div>
          </div>
        </div>
      ),
      width: "22%",
    },
    {
      dataIndex: "addTeam",
      key: "addTeam",
      showSorterTooltip: false,
      render: (addTeam, record) => (
        <AntButton
          className={style.gbAntButtonAddTeam}
          onClick={(e) => {
            e?.preventDefault();
            window.open(`https://kidp.ro/${record?.shortUrlCode}`);
          }}
        >
          加入團購
        </AntButton>
      ),
      width: "20%",
    },
  ];
  // 測試用
  // const getRandomuserParams = (params) => ({
  //   results: params.pagination?.pageSize,
  //   page: params.pagination?.current,
  //   ...params,
  // });
  /***** END Ant Table *****/

  /*** 取得登入者資料 ***/
  // const getUserData = async() => {
  //   const decoded = jwt_decode(loginToken);
  //   // // // // // console.log(decoded);
  //   const uid = decoded?.id || "";
  //   const email = decoded?.email || "";
  //   const atIndex = email.indexOf('@');
  //   const adminUsername = atIndex !== -1 ? email.substring(0, atIndex) : "";
  //   // // // // // // console.log(adminUsername);
  //   // setUserId(uid);
  //   return uid;
  // }
  // useEffect(() => {
  //   getUserData();
  // }, []);
  // useEffect(() => {
  //   getUserData();
  // }, [loginToken]);
  /*** END 取得使用者資料 ***/

  /*** Login Popup相關 ***/
  const [show, setShow] = useState(false); // 設定Modal 顯示與否
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const Popup = (props) => {
    const { NOuaabindLine, UserData, setNOuaabindLine, LoginToken } = props;
    return (
      <>
        <>
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
          >
            <Col className={popupStyle.modal}>
              <div
                className={popupStyle.modalClose}
                onClick={(event) => {
                  handleClose();
                  clearStorage(event);
                }}
              >
                x
              </div>
              <div className={popupStyle.modalHeader}>
                <Modal.Title className={popupStyle.modalTitle}></Modal.Title>
                <div className={popupStyle.PopupBanner}>
                  <img
                    src={
                      "https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/307b61fa-e830-473f-55f4-c2446e462c00/public"
                    }
                  ></img>
                </div>
              </div>
              <Modal.Body className={popupStyle.modalBody}>
                <LoginParent
                  className={popupStyle.modalLoginParent}
                  NOuaabindLine={NOuaabindLine}
                  loginTokenState={loginTokenState}
                  Loginparent={Loginparent}
                  UserData={email}
                  setNOuaabindLine={setNOuaabindLine}
                  LoginToken={LoginToken}
                ></LoginParent>
              </Modal.Body>
            </Col>
          </Modal>
        </>
      </>
    );
  }; /*** END Login Popup相關 ***/

  /* 返回上一頁清除使用者資料 */
  const previousPageClearStorage = () => {
    // window.addEventListener("popstate", function (e) {
    window.localStorage.removeItem("username");
    window.localStorage.removeItem("smsVeriStatus");
    window.localStorage.removeItem("phoneNumber");
    window.localStorage.removeItem("url");
    // }, false);
  };

  /* 案X清除Local Storage並登出LINE、FB */
  const clearStorage = (event) => {
    event.preventDefault();
    // 如果LINE有登入，執行LINE登出
    const isLoggedIn = liff.isLoggedIn();
    if (isLoggedIn) {
      liff.logout();
    }
    // 如果FB物件存在，且為登入中，將FB登出
    if (!!window.FB) {
      window.FB.getLoginStatus(function (response) {
        if (response.status === "connected") {
          window.FB.logout(function (response) {
            // // // // // // // // console.log('handleFBLogout', response);
          });
        }
      });
    }
    // localStorage.clear(); // 20230802 註解此段，避免自動登出
    setNOuaabindLine(false);
    setEmail(null);
    // setTimeout(()=>{
    //   setSheet('LoginIndex');
    // }, 3000);
  };

  //設定loading 轉圈圈
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    previousPageClearStorage(); // 回上一頁清除關於個資的local storage
  }, []);

  return (
    <>
      <Col className={style.gbMain}>
        <Col className={style.memberTableCol}>
          <div className={style.memberDescriptDiv}>
            <Title level={3} className={style.titleH3_6}>
              可跟團清單
            </Title>
          </div>
        </Col>
        <Row className={style.gbTeamsList}>
          <Space className={style.gbTableSortBtnSpace}>
            <Button className={style.gbTableSortBtn} onClick={setTimeSort}>
              開團時間
              {timeOrder === "ASC" ? (
                <CaretUpOutlined style={{ marginLeft: "4px" }} />
              ) : (
                <CaretDownOutlined style={{ marginLeft: "4px" }} />
              )}
            </Button>
            <Button
              className={style.gbTableSortBtn}
              onClick={setMemberCountSort}
            >
              成員數
              {memberOrder === "ASC" ? (
                <CaretUpOutlined style={{ marginLeft: "4px" }} />
              ) : (
                <CaretDownOutlined style={{ marginLeft: "4px" }} />
              )}
            </Button>
            {/* <Button className={style.gbTableSortBtn} onClick={setCourseCountSort}>課程數{courseOrder==="ASC"?(<CaretUpOutlined style={{marginLeft: '4px'}}/>):(<CaretDownOutlined style={{marginLeft: '4px'}}/>)}</Button> */}
            <Button className={style.gbTableSortBtn} onClick={filterCourse}>
              依課程找團
            </Button>
          </Space>
          <section className={style.gbCustomizeFilterSec}>
            {filterDropdownOpenStatus ? (
              <>
                {/* <div className={style.gbCustomizeFilterDivTop}><CaretUpFilled /></div> */}
                <div className={style.gbCustomizeFilterDiv}>
                  {customizeFilter}
                </div>
              </>
            ) : (
              <></>
            )}
          </section>
          <Table
            id="antTable"
            // onHeaderCell={(columns) => {
            //   // console.log('onHeaderCell')
            //   return {
            //     onClick: () => {
            //       // console.log('onHeaderCell')
            //     }, // 点击表头行
            //   };
            // }}
            // onHeaderRow={(columns, index) => {
            //   // console.log('onHeaderCell')
            //   return {
            //     onClick: () => {
            //       // console.log('onHeaderCell')
            //     }, // 点击表头行
            //   };
            // }}
            filterDropdownOpen={filterDropdownOpenStatus}
            columns={columns}
            rowKey={(record) => record._id} // record: data array中的其中一個object element
            dataSource={data}
            pagination={tableParams.pagination}
            loading={loading}
            onChange={handleTableChange}
          />
          <div className={style.gbErrMsgDiv}>
            <span className={style.gbErrMsg}>{RegxpError}</span>
          </div>
        </Row>
      </Col>
      {!loginToken ? (
        <>
          {/* <Row className={style.gbBottomRow0}>
              <Col className={style.gbBottomRow0Col}></Col>
          </Row>
          <Row className={`${style.gbRowAntButton}`}> 
              <AntButton className={style.gbAntButton} onClick={(e) => {
                e?.preventDefault();
                // // console.log("揪新團＋")
                if( ! loginToken ) {
                  // // console.log("! loginToken")
                  handleShow();
                } else {
                  // window.location.href = 'https://' + window.location.host + '/groupBuy';
                  // // console.log("!! loginToken")
                  setGbPage("CreateGroupPage");
                }
              }}>揪新團＋</AntButton>
          </Row> */}
        </>
      ) : (
        <>
          {/* <Row className={style.gbBottomRow1}>
            <Col className={style.gbBottomRow1Col}></Col>
          </Row> */}
        </>
      )}
      <Popup NOuaabindLine={NOuaabindLine} LoginToken={LoginToken}></Popup>
    </>
  );
}

export default GroupBuyListTeams;
