import { Link } from "react-router-dom";

export default function NotFound() {
    return (
        <section style={{margin: '1%', width: '100%', display: 'flex', justifyContent: 'center'}}>
            <div style={{width: '95%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <h1 style={{fontWeight: '800', margin: '1%'}}>404－無效的連結</h1>
                <h3 style={{margin: '1%'}}>建議您可以前往以下網站：</h3>
                {/* <Link style={{margin: '1%'}} to='/GrantMembership'><h5>綁定LINE享3個月體驗銀卡9折會員</h5></Link>
                <Link style={{margin: '1%'}} to='/GroupBuy'><h5>三人成團購課最高享3折優惠</h5></Link> */}
                <a style={{margin: '1%'}} href='https://kid-pro.com' target="_blank"><h5>KidPro官方網站</h5></a>
            </div>
        </section>
    )
}