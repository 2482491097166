import React from 'react'
import TapPayMessagePopup from './TapPayMessagePopup'

export const TapPayMessagePopupCondition = (tapPayMessagePopupShow, setTapPayMessagePopupShow, tappaySwitchContentString, tappayMessage, setTappayBtnState, tappayParam1, tappayParam2, tappayParam3) => {
  if (tapPayMessagePopupShow) {
    return (
      <TapPayMessagePopup
        tapPayMessagePopupShow={tapPayMessagePopupShow}
        setTapPayMessagePopupShow={setTapPayMessagePopupShow}
        tappaySwitchContentString={tappaySwitchContentString}
        tappayMessage={tappayMessage}
        setTappayBtnState={setTappayBtnState}
        tappayParam1={tappayParam1}
        tappayParam2={tappayParam2}
        tappayParam3={tappayParam3}
      ></TapPayMessagePopup>
    );
  }
  return null;
}

