import React, {useState, useEffect} from 'react'
import { useCookies } from 'react-cookie'; // 引入useCookies hook
import ReactGA from "react-ga4"; // GA4
import ReactPixel from 'react-facebook-pixel'; // FB Pixel

/*
 * Pixel, GA4 Configuration
 * @param {string} pixelId - FB Pixel ID (process.env.REACT_APP_FB_PIXEL_ID)
 * @param {string} ga4Id - GA4 ID (process.env.REACT_APP_GA4_ID)
 */ 
// export let Utm = [];
const UtmConfig = async(pixelId, ga4Id) => {
    // useEffect(() => {
        // console.log('{ pixelId, ga4Id }', { pixelId, ga4Id });
    // }, [pixelId, ga4Id]);

    /* FB Pixel Config */
    // const pixelConfig = async() => {
    //     try{
    //         const options = {
    //             autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    //             debug: false, // enable logs
    //         };
    //         ReactPixel.init(pixelId, {}, options);
    //         return ReactPixel;
    //     } catch(e) {
    //         throw new Error(e);
    //     }
    //     // // console.log('ReactPixel', ReactPixel);
    // }
    const pixelConfigFbq = async() => {
        // console.log('pixelConfigFbq')
        const f = window;
        const b = document;
        const e = 'script';
        const v = 'https://connect.facebook.net/en_US/fbevents.js';
        let n, t, s;
        n = t = s = undefined;
        // console.log('{f, b, e, v, n, t, s}', {f, b, e, v, n, t, s})
        // console.log('f.fbq', f.fbq)
        // console.log('!!f.fbq', !!f.fbq)
        if(f.fbq) return f.fbq;
        n = f.fbq = function() {
            n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)
        };
        if(!f._fbq) f._fbq=n;
        n.push=n;
        n.loaded=!0;
        n.version='2.0';
        n.queue=[];
        t=b.createElement(e);
        t.async=!0;
        t.src=v;
        s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
        f.fbq('init', pixelId);
        // console.log('f.fbq', f.fbq)
        return f.fbq
    }
    // fbq('track', 'PageView');

    /* GA4 Config */
    const ga4Config = async() => {
        try{
            // console.log('ga4Id', ga4Id);
            ReactGA.initialize(ga4Id, {
                gaOptions: {
                    name: window.location.pathname
                }
            });
            // console.log('ReactGA', ReactGA);
            return ReactGA;
        } catch(e) {
            throw new Error(e);
        }
        // // console.log('ReactGA', ReactGA);
    }
    // await pixelConfig();
    // await ga4Config();
    // useEffect(() => {
    //     let utmObj = {
    //         pixel: pixelConfig(),
    //         ga4: ga4Config()
    //     };
    // }, [pixelId, ga4Id]);

    /* UTM組態設定在第一次渲染後執行 */
    // useEffect(() => {
    //     if(pixelId) {
    //         pixelConfig();
    //     }
    //     if(ga4Id) {
    //         ga4Config();
    //     }
    // }, [pixelId, ga4Id]);
    let utmPixel, utmPixelFbq, utmGa4;
    utmPixel = utmPixelFbq = utmGa4 = "";
    if(pixelId) {
        // utmPixel = await pixelConfig();
        // console.log('utmPixel', utmPixel);
        utmPixelFbq = await pixelConfigFbq();
        // console.log('utmPixelFbq', utmPixelFbq);
    }
    if(ga4Id) {
        utmGa4 = await ga4Config();
    }
    // console.log('utmGa4', utmGa4);
    const returnData = {
        pixel: utmPixel,
        pixelFbq: utmPixelFbq,
        ga4: utmGa4,
        pixelId: pixelId,
        ga4Id: ga4Id,
    }
    // const returnData = {
    //     pixel: await pixelConfig(),
    //     ga4: await ga4Config()
    // }
    // console.log('returnData', returnData);
    return returnData;
    // return {
    //     pixelConfig,
    //     ga4Config
    // }
}

export default UtmConfig