/***
 * 直接套用請將<Form>及</Form>取消註解 
 ***/
import React, { useState, useContext, useEffect, useRef } from 'react'
import ScriptTag from 'react-script-tag';
import style from './styles/TapPay.module.css';
import './styles/TapPay.css';
import * as Scroll from 'react-scroll';
import { Element, Events, animateScroll as scroll,  scroller } from 'react-scroll'

/***  antd ***/
import { Layout, Form, Input, Select, Steps, Checkbox, Typography, Collapse, Button as AntButton } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';

import { useCookies } from 'react-cookie'; // 引入useCookies hook
import ReactGA from "react-ga4"; // GA4
import ReactPixel from 'react-facebook-pixel'; // FB Pixel

import liff from '@line/liff'; // liff SDK
import AuthContext from '../../UaaPage/login/components/AuthContext';

/*** TapPay Parameter ***/
const tappayAppId = process.env.REACT_APP_TAPPAY_APP_ID;
const tappayAppKey = process.env.REACT_APP_TAPPAY_APP_KEY;
const tappayAppDeploy = process.env.REACT_APP_TAPPAY_DEPLOY;

/*** 由TapPay SDK取得TPDirect元件 ***/
const getTPDirect = async() => {
  return new Promise((resolve, reject) => {
      if (typeof window.TPDirect !== 'undefined') {
          return resolve(window.TPDirect)
      } else {
          const script = window.document.createElement('script')
          script.src = "https://js.tappaysdk.com/sdk/tpdirect/v5.14.0"
          script.async = true
          script.onload = () => {
              if(typeof window.TPDirect !== 'undefined') {
                resolve(window.TPDirect);
              } else {
                reject(new Error('failed to load TapPay sdk'));
              }
          }
          script.onerror = reject
          window.document.body.appendChild(script);
      }
  })
}

/*** TPDirect元件組態 ***/
export const tappayConfig = async()  => {
  getTPDirect().then((TPDirect) => {
    // // console.log(TPDirect);
    TPDirect.setupSDK(
        tappayAppId,
        tappayAppKey,
        tappayAppDeploy
    )
    let fields = {
      number: {
          // css selector
          element: '#card-number',
          placeholder: '**** **** **** ****'
      },
      expirationDate: {
          // DOM object
          element: document.getElementById('card-expiration-date'),
          placeholder: 'MM / YY'
      },
      ccv: {
          element: '#card-ccv',
          placeholder: 'ccv'
      }
    }
    TPDirect.card.setup({
      fields: fields,   
      // 此設定會顯示卡號輸入正確後，會顯示前六後四碼信用卡卡號
      isMaskCreditCardNumber: true,
      maskCreditCardNumberRange: {
          beginIndex: 6,
          endIndex: 11
      }
    })
  })
}

/*** TapPay提交 ***/
// export const tappaySubmit = async(e) => {
//   // // console.log(e);
//   e?.preventDefault();
//   // await tappayConfig();
//   getTPDirect().then((TPDirect) => {
//     new Promise((resolve, reject) => {
//       // 取得 TapPay Fields 的 status
//       try {
//         const tappayStatus = TPDirect.card.getTappayFieldsStatus();
//         let submitMsg = "";
//         // 確認是否可以 getPrime
//         if (tappayStatus.canGetPrime === false) {
//             // // console.log('can not get prime');
//             submitMsg = "請檢查您的信用卡資料是否填寫正確。"
//             // // console.log(submitMsg);
//             // // console.log("請檢查您的信用卡資料是否填寫正確。");
//             return resolve("請檢查您的信用卡資料是否填寫正確。");
//         }
//       }
//       catch{
//         // Get prime
//         TPDirect.card.getPrime((result) => {
//           if (result.status !== 0) {
//               // alert('get prime error ' + result.msg)
//               return resolve(`請檢查您的信用卡資料是否填寫正確。 ( ${result.msg} )`);
//           }
//           // alert('get prime 成功，prime: ' + result.card.prime)
//           return resolve("信用卡授權成功！");
//           // send prime to your server, to pay with Pay by Prime API .
//           // Pay By Prime Docs: https://docs.tappaysdk.com/tutorial/zh/back.html#pay-by-prime-api
//         })
//     }

//     })
//   });
// }

export const tappaySubmit = async (e) => {
  // // console.log(e);
  e.preventDefault();
  // Get the TPDirect instance
  const TPDirect = await getTPDirect();
  // // console.log(JSON.stringify(TPDirect));
  // // console.log(TPDirect);
  return new Promise((resolve, reject) => {
    // 取得 TapPay Fields 的 status
    try {
      const tappayStatus = TPDirect.card.getTappayFieldsStatus();
      // let submitMsg = "";
      // 確認是否可以 getPrime
      if (tappayStatus.canGetPrime === false) {
        // // // console.log('can not get prime');
        // submitMsg = "請檢查您的信用卡資料是否填寫正確。";
        // // // console.log(submitMsg);
        // // // console.log("請檢查您的信用卡資料是否填寫正確。");
        return resolve(["","請檢查您的信用卡資料是否填寫正確。"]);
      }
      // Get prime
      TPDirect.card.getPrime((result) => {
        // console.log(result);
        if (result.status !== 0) {
          // alert('get prime error ' + result.msg)
          return resolve(["",`請檢查您的信用卡資料是否填寫正確。 ( ${result.msg} )`]);
        }
        return resolve([result.card.prime,""]);
      })
    } catch (error) {
      return resolve(["","請檢查您的信用卡資料是否填寫正確。"]);
    }
      // alert('get prime 成功，prime: ' + result.card.prime)
      // return resolve([result.card.prime,"信用卡授權成功！"]);
      // send prime to your server, to pay with Pay by Prime API .
      // Pay By Prime Docs: https://docs.tappaysdk.com/tutorial/zh/back.html#pay-by-prime-api
  });
}



function TapPay(props) {
  const {gbPage, setGbPage} = props;
  useEffect(()=>{
    // // console.log(gbPage);
  },[gbPage]);


  const kidprologinURL = process.env.REACT_APP_KIDPROLOGINURL // KidPro 登入導向URL
  const kidprocertiURL = 'https://kid-pro.com/my-account/my-certificates/';  // KidPro 完課證明頁面
  const cc02URL = 'https://kid-pro.com/product/cc-0-2/';
  const si02URL = 'https://kid-pro.com/product/si0-2/';
  const si212URL = 'https://kid-pro.com/product/si2-12/';
  const ae31URL = 'https://kid-pro.com/product/ae3-1/';

  let loginToken = window.localStorage.getItem('loginToken') ? window.localStorage.getItem('loginToken') : ""; // 取得Login Token

  const { setSheet } = props;
  const { authorized, setAuth, auth, setEmail, email } = useContext(AuthContext);
  const [NOuaabindLine, setNOuaabindLine] = useState(false); // 設定登入頁面line login 無綁定uaa
  const [Loginparent, setLoginparent] = useState(0); // 設定login parent
  const [LoginToken, setLoginToken] = useState(''); // 設定login token
  const [RegxpError, setRegxpError] = useState(''); // 顯示錯誤訊息
  const [isLoading, setIsLoading] = useState(false); //設定loading 轉圈
  const [cookies, setCookie] = useCookies(['utm']); // useCookies hook

  const backendURL = process.env.REACT_APP_BACKENDURL; // 首頁URL
  const apiURL = process.env.REACT_APP_APIURL; // API URL
  const lineid = process.env.REACT_APP_LINE_APP_ID;
  let LIFF_STORE = window.localStorage.getItem('LIFF_STORE')
  let LIFF_STOREregister = window.localStorage.getItem('LIFF_STOREregister')
  const lineAccessToken = !! window.localStorage.getItem('lat') ? window.localStorage.getItem('lat') : '';
  const [ShowCheckMark, setShowCheckMark] = useState(false); // 設定copy按鈕反饋checkMark
  const [loginTokenState, setloginTokenState] = useState('');
  const [errMsg, setErrMsg] = useState("");

  /*** TapPay更新欄位狀態 ***/
  const tappayUpdate = async() => {
    // // console.log("tappayUpdate");
    await tappayConfig();
    await getTPDirect().then((TPDirect) => {
      // // console.log(TPDirect);
      TPDirect.card.onUpdate(function (update) {
        // // console.log(update);
        // update.canGetPrime === true
        // --> you can call TPDirect.card.getPrime()
        if (update.canGetPrime) {
          setErrMsg("");
          // // console.log("Enable submit Button to get prime.");
          // submitButton.removeAttribute('disabled')
        } else {
          setErrMsg("信用卡欄位格式輸入錯誤！");
          // return [false, "信用卡欄位格式輸入錯誤，無法取得Prime！"];
          // Disable submit Button to get prime.
          // submitButton.setAttribute('disabled', true)
        }
    
        // cardTypes = ['mastercard', 'visa', 'jcb', 'amex', 'unknown']
        if (update.cardType === 'visa') {
            // Handle card type visa.
        }
    
        // number fields is error
        if (update.status.number === 2) {
          setErrMsg("信用卡號格式輸入錯誤！");
          // return [false, "信用卡號格式輸入錯誤！"];
          // setNumberFormGroupToError()
        } else if (update.status.number === 0) {
          // setNumberFormGroupToSuccess()
        } else {
          // setNumberFormGroupToNormal()
        }
    
        if (update.status.expiry === 2) {
          // setNumberFormGroupToError()
          setErrMsg("信用卡有效期格式錯誤或已失效！");
          // return [false, "信用卡有效期格式錯誤或已失效！"];
        } else if (update.status.expiry === 0) {
          // setNumberFormGroupToSuccess()
        } else {
          // setNumberFormGroupToNormal()
        }
    
        if (update.status.ccv === 2) {
            // setNumberFormGroupToError()
            setErrMsg("CCV格式錯誤！");
            // return [false, "CCV格式錯誤！"];
        } else if (update.status.ccv === 0) {
            // setNumberFormGroupToSuccess()
        } else {
            // setNumberFormGroupToNormal()
        }
      })
    })
  }

  /* 返回上一頁清除使用者資料 */
  const previousPageClearStorage = () => {
    // window.addEventListener("popstate", function (e) {
      window.localStorage.removeItem('username');
      window.localStorage.removeItem('smsVeriStatus');
      window.localStorage.removeItem('phoneNumber');
      window.localStorage.removeItem('url');
    // }, false);
  }


  useEffect(() => {
    tappayUpdate();
  }, []);
  
  /*** 表單提交 ***/
  const onFinish = (values) => {
    // console.log('Form values:', values);
  };

  return (
    <>
      <Layout className={style.gbLayout}>

          {/* <Container fluid className={style.container_fluid}>
            <Row className={style.gbContainerRow}>
              <Col className={style.container_customMargin}>
                <Col className={style.gbTop}>
                  <Col className={style.gbTopCol1}>
                    <Title level={3} className={style.titleH3_7_3}>信用卡付款</Title>
                  </Col>
                  <Col className={style.gbTopCol2}>
                    <Title level={3} className={style.titleH3_7_4}>( 組團時只會進行授權，成團後才會進行請款 )</Title>
                  </Col>
                </Col>
                <Col className={style.gbMain}>    */}
                
                {/* <Content className={`${style.gbRowCourseImg} ${style.gbContentBox}`}>
                  <section className={`${style.gbColCourseImg} ${style.gbContentSecTapPay}`}> */}
                    <Form className={`${style.gbForm}`}>
                      <Form.Item 
                        className={`${style.gbFormRow} ${style.gbFormRowCardNumber}`}
                        name="card-number" 
                        label="卡號 "
                        rules={[{ required: true, message: '請輸入信用卡號' }]}
                      >
                        <div id="card-number" className={`${style.gbInputText} ${style.gbInputTextCardNumber} tpfield`}></div>      
                      </Form.Item>
                      <Form.Item 
                        className={`${style.gbFormRow} ${style.gbFormRowCardExpirationDate}`}
                        name="card-expiration-date"
                        label="有效期 "
                        rules={[{ required: true, message: '請輸入信用卡有效期' }]}
                      >
                        <div id="card-expiration-date" className={`${style.gbInputText} ${style.gbInputTextCardExpirationDate} tpfield mui-textfield`}></div>
                      </Form.Item>
                      <Form.Item 
                        className={`${style.gbFormRow} ${style.gbFormRowCardCcv}`}
                        name="card-ccv"
                        label="安全代碼 ( CCV ) "
                        rules={[{ required: true, message: '請輸入信用卡安全代碼(CCV)' }]}
                      >
                        <div id="card-ccv" className={`${style.gbInputText} ${style.gbInputTextCardCcv} tpfield`}></div>
                      </Form.Item>
                      <div className='tpErrMsg'>{errMsg}</div>
                    </Form>
                  {/* </section>   
                </Content>  */}
                  
                  {/* <Row className={style.gbRowCourseImg}>
                    <Col className={style.gbColCourseImg}>
                      <div className={`${style.gbColCourseDescription} ${style.gbColCourseTotal}`}>
                        <Title level={3} className={`${style.titleH3_5Tool} ${style.titleH3_5Total}`}>總計　<span className={style.gbTotal}>65950</span>元</Title>             
                      </div>                 
                    </Col>      
                  </Row>                           
                </Col>
              </Col>  
            </Row>      
            <Row id="tapPaySubmit" className={`${style.gbRowAntButton}`}>
              <AntButton className={`${style.gbAntButton} ${style.gbAntButtonConfirm}`} onClick={(e) => {
                e?.preventDefault();
                if( ! loginToken ) {
                  handleShow();
                } else {
                  tappayUpdate();
                }
              }}>確認</AntButton>
              <Row className={style.gbBottomRow1}>
                <Title level={4} className={style.titleH4_7} onClick={(e) => {
                  e?.preventDefault();
                  if(window.confirm("確定要返回揪團頁面嗎？\n( 表單內容可能會被清空 )")) {
                    setGbPage("CreateGroupPage");
                  }
                }}>返回揪團頁面</Title>
              </Row>
            </Row>
            <Row className={style.gbBottomRow2}>
              <Title level={4} className={style.titleH4}>KidPro團隊保留活動最終解釋權</Title>
            </Row>
          </Container> */}
        
        {/* <Popup NOuaabindLine={NOuaabindLine} LoginToken={LoginToken} ></Popup> */}
      </Layout>
    </>
  )
}

export default TapPay
  // TapPay 更新時記錄欄位狀態
