import React from 'react'
import { useEffect } from 'react';
import style from './styles/Forbidden.module.css';
import { Container,Row,Col } from 'react-bootstrap';
//liff SDK
import liff from '@line/liff';

function Forbidden() {
  const backendURL = process.env.REACT_APP_BACKENDURL; // 首頁URL
  const apiURL = process.env.REACT_APP_APIURL; // API URL
    const BackToPrepage=()=>{
        window.history.back();
    }

      /* Line 驗證是否登入，未登入則登入 */
      const LineAuth = () => {
        const isLoggedIn = liff.isLoggedIn();
        //// // console.log('isLoggedIn',isLoggedIn);
        if(!isLoggedIn){
            liff.login({ redirectUri: "https://"+backendURL+"/LoginEncode?page=homepage"  });
        }
    }

    const linelogin = async() => {

      ////// // console.log('我點')
      // setSheet(1);
      const init = await liff.init({
          liffId: '1657654086-dmjPoeJg', // Use own liffId
          
      }).catch((err) => {
          // Todo: retry init.
          //// // console.log('err',err);
      });
  }
  

    let line_bindData = {};

    const liffLogin = async() => {
      const init =  await liff.init({
          //liffId: '1657653348-OBD562Nn', // Use own liffId
          liffId: '1657654086-dmjPoeJg',
          // withLoginOnExternalBrowser: true, // Enable automatic login process
      }).catch((err) => {
         // console.log('err',err);
      });
      const isLoggedIn = liff.isLoggedIn();
      //// console.log('isLoggedIn',isLoggedIn);
      
      // if(isLoggedIn ===true){

      //   let redirectUrl = window.location.href;
      //   // setIfLineLogin(true);
      //   const accessToken = liff.getAccessToken();
      //   //// console.log('accessToken',accessToken);
      //   //// console.log('formdata',formdata);
      //   if(formdata){
      //     line_bindData= {
      //       email: formEmail,
      //       redirectUrl:redirectUrl,
      //       accessToken:accessToken,
      //     };
          
      //     friendstatus();
      //     const loginToken = window.localStorage.getItem('loginToken');
      //     const BearerToken = 'Bearer ' + loginToken;
      //     fetch('https://uaa.kid-pro.com/api/uaa/line_bind', {
      //       method: 'POST',
      //       body: JSON.stringify(line_bindData),
      //       headers: {
      //         'Content-Type': 'application/json',
      //         'Authorization': BearerToken
      //       },
      //     })
      //       .then((r) => r.json())
      //       .then((result) => {
      //         ////// // // console.log('result', result);
      //         if(result.status === 200){
      //           ////// // // console.log('成功 200'); 
      //         }else if(result.status === 400){
      //           ////// // // console.log('失敗，錯誤代碼 400')
      //         }else if(result.status === 403){
      //           ////// // // console.log('失敗，錯誤代碼 403')
      //         }else{
      //           alert('失敗。')
      //         }
      //       });
      //   }
      // }
  }    

  //     /* Line 驗證是否登入，未登入則登入 */
  //     const LineAuth = () => {
  //       const isLoggedIn = liff.isLoggedIn();
  //       //// // console.log('isLoggedIn',isLoggedIn);
  //       if(!isLoggedIn){
  //           liff.login({ redirectUri: "https://localhost:3000/Forbidden" });
  //       }
  //   }

  //   const linelogin = async() => {
  //     ////// // console.log('我點')
  //     // setSheet(1);
  //     const init = await liff.init({
  //         liffId: '1657654086-dmjPoeJg', // Use own liffId
  //         // liffId: '1657653348-OBD562Nn',
  //         // 1657653348-OBD562Nn 公司
  //         // 1657654086-dmjPoeJg me
  //     }).catch((err) => {
  //         // Todo: retry init.
  //         //// // console.log('err',err);
  //     });
  // }

    

    useEffect(() => {
      liffLogin();
    }, []);
  return (
    <div className={style.backgroundColor}>
      <Container>
        <Row>
            <div className={style.verticle}>
               <Col className='col-12'>
                 <h2 className={style.title}>您已參加過完課送瑜珈球的活動
                   
                 </h2>
                 <h2 className={style.title}>感謝分享</h2>
                </Col>
                <Col className='col-12'>
                  <div className={style.buttonSec}>
                      {/* <button className={style.button} onClick={BackToPrepage}>回上一頁</button> */}
                  </div>
                  <div className={style.buttonSec}>
                      <button className={style.button} onClick={LineAuth} >line測試</button>
                  </div>
                </Col>
            </div>
        </Row>
      </Container>
  
 
    </div>
  )
}

export default Forbidden