import React, { useState, useContext, useEffect, useRef } from "react";
import style from "./styles/GroupBuyCreateGroup.module.css";
import tapPayStyle from "../Components/TapPay/styles/TapPay.module.css";
import "../Components/TapPay/styles/TapPay.css";
import "./styles/GroupBuy.css";
import "./styles/AdvertisingPage.css";
import "./styles/antStyle.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import LoadingSpinner from "../Components/Spinner/Spinner";
import Modal from "react-bootstrap/Modal";
import popupStyle from "../Components/styles/popup.module.css";
import LoginParent from "../UaaPage/login/LoginParent";
import "bootstrap/dist/css/bootstrap.min.css";
import CheckMark from "./components/CheckMark";
import PopupBanner from "../UaaPage/login/imgs/PopupBanner.png";
import gbBannerTopPhone1 from "./imgs/gb_banner_top_phone.gif";
import gbBannerTopPhone2 from "./imgs/gb_banner_top_phone_2.gif";
import gbAdd2cartPC from "./imgs/gb_add2cart_phone.webp";
import gbAdd2cartPhone from "./imgs/gb_add2cart_phone.webp";
import gbSI02PC from "./imgs/si0-2_pc.png";
import gbSI02Phone from "./imgs/si0-2_phone.png";
import gbCC02PC from "./imgs/cc0-2_pc.png";
import gbCC02Phone from "./imgs/cc0-2_phone.png";
import gbAE31PC from "./imgs/ae3-1_pc.png";
import gbAE31Phone from "./imgs/ae3-1_phone.png";
import gbSI212PC from "./imgs/si2-12_pc.png";
import gbSI212Phone from "./imgs/si2-12_phone.png";
import gbORAL212PC from "./imgs/oral2-12_pc.png";
import gbORAL212Phone from "./imgs/oral2-12_phone.png";
import orangeLine from "./imgs/orange-line.png";
import * as Scroll from "react-scroll";
import {
  Element,
  Events,
  animateScroll as scroll,
  scroller,
} from "react-scroll";
import jwt_decode from "jwt-decode";

/*** GroupBuy Page ***/
import GroupBuy from "./GroupBuy";
import GroupBuyCheckout from "./GroupBuyCheckoutCreateTeam";
import GroupBuyListTeamsComponent from "./GroupBuyListTeamsComponent";
import * as dayjs from "dayjs";

/*** TapPay ***/
// import TapPayMessagePopup from '../Components/TapPay/TapPayMessagePopup';
import { TapPayMessagePopupCondition } from "../Components/TapPay/TapPayMessagePopupCondition";

/***  antd ***/
import { Steps, Typography, Layout, Collapse, Button as AntButton } from "antd";
import {
  CloseCircleOutlined,
  RightCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import "./styles/antStyle.css";
// import './styles/AdvertisingPage.css';

/* MUI */
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import { useCookies } from "react-cookie"; // 引入useCookies hook
import ReactGA from "react-ga4"; // GA4
import ReactPixel from "react-facebook-pixel"; // FB Pixel

import liff from "@line/liff"; // liff SDK
import AuthContext from "../UaaPage/login/components/AuthContext";

/*** UTM ***/
import UtmConfig from "../Components/Utm/UtmConfig";
import UtmEvents, {
  utmEventsInit,
  utmPageView,
  utmAddToCart,
  utmShare,
  utmPurchase,
} from "../Components/Utm/UtmEvents";

function GroupBuyCreateGroup(props) {
  const {
    gbPage,
    setGbPage,
    pidSelectedArray,
    setPidSelectedArray,
    userProductsArray,
    setUserProductsArray,
    teamId,
    setTeamId,
    orderNum,
    setOrderNum,
  } = props;
  useEffect(() => {
    // // console.log(gbPage)
  }, [gbPage]);
  useEffect(() => {
    // // console.log('userProductsArray',userProductsArray)
  }, [userProductsArray]);
  useEffect(() => {
    // // console.log('pidSelectedArray',pidSelectedArray)
  }, [pidSelectedArray]);
  useEffect(() => {
    // // console.log('teamId',teamId)
  }, [teamId]);
  useEffect(() => {
    // // console.log('orderNum',orderNum)
  }, [orderNum]);
  /***  Ant API ***/
  const { Title } = Typography; // 標題
  const { Content } = Layout; // 主要內容區塊(Layout本身是組件，也可當API使用)
  const { StepItem } = Steps;
  const { Panel } = Collapse;

  const kidprologinURL = process.env.REACT_APP_KIDPROLOGINURL; // KidPro 登入導向URL
  const kidprocertiURL = "https://kid-pro.com/my-account/my-certificates/"; // KidPro 完課證明頁面
  const cc02URL = "https://kid-pro.com/product/cc-0-2/";
  const si02URL = "https://kid-pro.com/product/si0-2/";
  const si212URL = "https://kid-pro.com/product/si2-12/";
  const ae31URL = "https://kid-pro.com/product/ae3-1/";
  let loginToken = window.localStorage.getItem("loginToken")
    ? window.localStorage.getItem("loginToken")
    : ""; // 取得Login Token
  // useEffect(()=>{
  //   // // console.log('loginToken',loginToken)
  // },[]);
  let gotoKidproCerti = window.localStorage.getItem("loginToken")
    ? kidprologinURL + loginToken + "&redirectUrl=" + kidprocertiURL
    : kidprocertiURL; // 若Login Token存在，則KidPro LOGO導向首頁並登入；否則僅導向完課證明頁面
  let cc02 = window.localStorage.getItem("loginToken")
    ? kidprologinURL + loginToken + "&redirectUrl=" + cc02URL
    : cc02URL;
  let si02 = window.localStorage.getItem("loginToken")
    ? kidprologinURL + loginToken + "&redirectUrl=" + si02URL
    : si02URL;
  let si212 = window.localStorage.getItem("loginToken")
    ? kidprologinURL + loginToken + "&redirectUrl=" + si212URL
    : si212URL;
  let ae31 = window.localStorage.getItem("loginToken")
    ? kidprologinURL + loginToken + "&redirectUrl=" + ae31URL
    : ae31URL;

  // 測試用
  // let veriPhoneMod = phone( '+8860911087462' );
  // // // console.log('veriPhoneMod',veriPhoneMod);
  // let santiPhone = veriPhoneMod.phoneNumber;
  // // // console.log('santiPhone',santiPhone);

  const { setSheet } = props;
  const { authorized, setAuth, auth, setEmail, email } =
    useContext(AuthContext);
  const [NOuaabindLine, setNOuaabindLine] = useState(false); // 設定登入頁面line login 無綁定uaa
  const [Loginparent, setLoginparent] = useState(0); // 設定login parent
  const [LoginToken, setLoginToken] = useState(""); // 設定login token
  const [RegxpError, setRegxpError] = useState(""); // 顯示錯誤訊息
  const [userId, setUserId] = useState(""); // 設定使用者ID
  const [showAntLoading, setShowAntLoading] = useState(false); // 設定antd loading icon是否顯示
  // const queryString = window.location.search;
  // // // // console.log('queryString',queryString);
  // const urlParams = new URLSearchParams(queryString);
  // // // // console.log('urlParams.get(liff.state)',urlParams.get('liff.state'));

  const backendURL = process.env.REACT_APP_BACKENDURL; // 首頁URL
  const apiURL = process.env.REACT_APP_APIURL; // API URL
  const microservApiURL = process.env.REACT_APP_MICROSERV_APIURL; // microserve API URL
  const uaaApiURL = process.env.REACT_APP_UAA_APIURL; // uaa API URL
  let LIFF_STORE = window.localStorage.getItem("LIFF_STORE");
  let LIFF_STOREregister = window.localStorage.getItem("LIFF_STOREregister");
  const lineAccessToken = !!window.localStorage.getItem("lat")
    ? window.localStorage.getItem("lat")
    : "";
  const [ShowCheckMark, setShowCheckMark] = useState(""); // 設定copy按鈕反饋checkMark
  const [loginTokenState, setloginTokenState] = useState("");

  /*** GroupBuy State ***/
  const [courseSelectBtnState, setCourseSelectBtnState] = useState([
    "Select",
    "Select",
    "Select",
    "Select",
    "Select",
    "Select",
  ]);
  useEffect(() => {
    // 設定課程選擇按鈕狀態
    // console.log('courseSelectBtnState',courseSelectBtnState)
  }, [courseSelectBtnState]);
  const [
    coursePidDiscountPriceObjectArray,
    setCoursePidDiscountPriceObjectArray,
  ] = useState([{ pid: "", discount: NaN, price: NaN, totalPrice: NaN }]); // 設定單堂課程折扣價格(For開團)
  useEffect(() => {
    // // console.log('coursePidDiscountPriceObjectArray',coursePidDiscountPriceObjectArray)
  }, [coursePidDiscountPriceObjectArray]);
  const [
    coursePidDiscountPriceObjectMultiplyMembershipDiscountArray,
    setCoursePidDiscountPriceObjectMultiplyMembershipDiscountArray,
  ] = useState([{ pid: "", membership: "", discount: NaN, price: NaN }]); // 設定單堂課程折扣價格乘以會員折扣
  const [checkoutPriceInfo, setCheckoutPriceInfo] = useState({
    product: [{ pid: "", discount: NaN, price: NaN, discountPrice: NaN }],
    subtotal: NaN,
    membership: { title: "", discount: NaN, discountPrice: NaN },
    shipping: NaN,
    total: NaN,
  }); // 設定結帳價格資訊
  const [checkoutProductPriceInfoArray, setCheckoutProductPriceInfoArray] =
    useState([{ pid: "", discount: NaN, price: NaN, discountPrice: NaN }]); // 設定結帳價格資訊
  const [checkoutMembershipPriceInfo, setCheckoutMembershipPriceInfo] =
    useState({ title: "", discount: NaN, discountPrice: NaN }); // 設定結帳會員價格資訊
  const [discount, setDiscount] = useState(0); // 設定折扣
  const [price, setPrice] = useState(0); // 設定價格
  const [totalPrice, setTotalPrice] = useState(0); // 設定總價格
  const [billingState, setBillingState] = useState({
    first_name: "",
    last_name: "",
    address_1: "",
    city: "",
    state: "",
    postcode: "",
    country: "TW",
    email: "",
    phone: "",
  }); // 設定帳單資訊
  useEffect(() => {
    // // console.log('billingState',billingState)
  }, [billingState]);
  const [shippingState, setShippingState] = useState({
    first_name: "",
    last_name: "",
    address_1: "",
    city: "",
    state: "",
    postcode: "",
    country: "TW",
    email: "",
    phone: "",
  }); // 設定寄送資訊
  useEffect(() => {
    // // console.log('shippingState',shippingState)
  }, [shippingState]);
  const [checkoutInfoStateInit, setCheckoutInfoStateInit] = useState({}); // 設定API取得的所有結帳資訊
  const [billingStateInit, setBillingStateInit] = useState({
    first_name: "",
    last_name: "",
    address_1: "",
    city: "",
    state: "",
    postcode: "",
    country: "TW",
    email: "",
    phone: "",
  }); // 設定API取得的帳單資訊
  useEffect(() => {
    // // console.log('billingStateInit',billingStateInit)
  }, [billingStateInit]);
  const [shippingStateInit, setShippingStateInit] = useState({
    first_name: "",
    last_name: "",
    address_1: "",
    city: "",
    state: "",
    postcode: "",
    country: "TW",
    email: "",
    phone: "",
  }); // 設定API取得的寄送資訊
  useEffect(() => {
    // // console.log('shippingStateInit',shippingStateInit)
  }, [shippingStateInit]);
  const [newestUserBuyCourses, setNewestUserBuyCourses] = useState([]); // 設定最新購買課程

  const [userTeamArray, setUserTeamArray] = useState([]); // 設定使用者開團資料
  // useEffect(()=>{
  //   console.log('userTeamArray',userTeamArray)
  //   console.log('userTeamArray[0]?.members?.length',userTeamArray[0]?.members?.length)
  // },[userTeamArray]);
  const [userTeamId, setUserTeamId] = useState(""); // 設定使用者開團ID
  const [userTeamIds, setUserTeamIds] = useState([]); // 設定使用者開團IDs
  // const [userProducts, setUserProducts] = useState([]); // 設定使用者購買課程IDs
  const [userMembers, setUserMembers] = useState([]); // 設定使用者開團成員IDs
  const [userName, setUserName] = useState(""); // 設定使用者名稱
  const [userEndDate, setUserEndDate] = useState(""); // 設定結束日期
  const [userEndDates, setUserEndDates] = useState([]); // 設定使用者開團結束日期s
  const [userShortUrl, setUserShortUrl] = useState(""); // 設定短網址
  const [coursePidDiscounts, setCoursePidDiscounts] = useState([]);
  const [userDiscount, setUserDiscount] = useState(0); // 設定使用者折扣
  const [fetchedUserTeam, setFetchedUserTeam] = useState(false); // 設定是否已取得使用者開團資料
  const [newCourseSelectBtnState, setNewCourseSelectBtnState] = useState([]); // 新的課程Select狀態(包含HasCourse)
  useEffect(() => {
    // // console.log('newCourseSelectBtnState',newCourseSelectBtnState)
  }, [newCourseSelectBtnState]);
  const [courseDataArray, setCourseDateArray] = useState({
    name: "",
    price: 9999,
  });
  const [catchErrorBtnState, setCatchErrorBtnState] = useState(false);

  /*** 取得TapPay Url Query***/
  const urlParams = new URLSearchParams(window.location.search);
  let recTradeId, orderNumber, bankTransactionId, status, authCode;
  recTradeId = orderNumber = bankTransactionId = authCode = "";
  status = NaN;
  useEffect(() => {
    // // console.log('urlParams',urlParams)

    // if( urlParams?.size > 0 || typeof(urlParams) === "object" ) {

    recTradeId = urlParams.get("rec_trade_id");
    orderNumber = urlParams.get("order_number");
    bankTransactionId = urlParams.get("bank_transaction_id");
    status = urlParams.get("status");
    status = parseInt(status);
    authCode = urlParams.get("auth_code");

    // }

    // // console.log('recTradeId',recTradeId)
    // // console.log('orderNumber',orderNumber)
    // // console.log('bankTransactionId',bankTransactionId)
    // // console.log('status',status)
    // // console.log('authCode',authCode)
  }, [urlParams]);

  /*** TapPay Messege Popup ***/
  const [tapPayMessagePopupShow, setTapPayMessagePopupShow] = useState(false);
  const [tapPayResult, setTapPayResult] = useState("");
  const [tappayMessage, setTappayMessage] = useState("");

  /*** Login Popup相關 ***/
  const [show, setShow] = useState(false); // 設定Modal 顯示與否
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const Popup = (props) => {
    const { NOuaabindLine, UserData, setNOuaabindLine, LoginToken } = props;
    return (
      <>
        <>
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
          >
            <Col className={popupStyle.modal}>
              <div
                className={popupStyle.modalClose}
                onClick={(event) => {
                  handleClose();
                  clearStorage(event);
                }}
              >
                x
              </div>
              <div className={popupStyle.modalHeader}>
                <Modal.Title className={popupStyle.modalTitle}></Modal.Title>
                <div className={popupStyle.PopupBanner}>
                  <img src={PopupBanner}></img>
                </div>
              </div>
              <Modal.Body className={popupStyle.modalBody}>
                <LoginParent
                  className={popupStyle.modalLoginParent}
                  NOuaabindLine={NOuaabindLine}
                  loginTokenState={loginTokenState}
                  Loginparent={Loginparent}
                  UserData={email}
                  setNOuaabindLine={setNOuaabindLine}
                  LoginToken={LoginToken}
                ></LoginParent>
              </Modal.Body>
            </Col>
          </Modal>
        </>
      </>
    );
  }; /*** END Login Popup相關 ***/

  /*** 取得登入者資料 ***/
  const getUserData = async () => {
    const decoded = jwt_decode(loginToken);
    // // console.log(decoded);
    const uid = decoded?.id || "";
    const email = decoded?.email || "";
    const atIndex = email.indexOf("@");
    const adminUsername = atIndex !== -1 ? email.substring(0, atIndex) : "";
    // // console.log(adminUsername);
    setUserId(uid);
    return uid;
  };
  useEffect(() => {
    getUserData();
  }, []);
  useEffect(() => {
    getUserData();
  }, [loginToken]);
  /*** END 取得使用者資料 ***/

  /*** UTM Events ***/
  const [utm, setUtm] = useState({});
  const [utmKidPro, setUtmKidPro] = useState({});
  const [utmKidPro2, setUtmKidPro2] = useState({});
  const [utmPageViewEventId, setUtmPageViewEventId] = useState("");
  const [utmAddToCartEventId, setUtmAddToCartEventId] = useState("");
  const [utmCheckoutEventId, setUtmCheckoutEventId] = useState("");
  const [utmPurchaseEventId, setUtmPurchaseEventId] = useState("");
  const configUTM = async () => {
    try {
      const pixelId = process.env.REACT_APP_FB_PIXEL_ID ?? "";
      const gaId = process.env.REACT_APP_GA4_ID ?? "";
      const pixelIdKidpro = process.env.REACT_APP_FB_PIXEL_ID_KIDPRO ?? "";
      const pixelIdKidpro2 = process.env.REACT_APP_FB_PIXEL_ID_KIDPRO2 ?? "";
      const gaIdKidpro = process.env.REACT_APP_GA4_ID_KIDPRO ?? "";
      const Utm = await UtmConfig(pixelId, gaId);
      const UtmKidPro = await UtmConfig(pixelIdKidpro, gaIdKidpro);
      const UtmKidPro2 = await UtmConfig(pixelIdKidpro2, "");
      setUtm(Utm);
      setUtmKidPro(UtmKidPro);
      setUtmKidPro2(UtmKidPro2);
      return { Utm, UtmKidPro, UtmKidPro2 };
    } catch (error) {
      throw new Error(error);
    }
  };
  useEffect(() => {
    configUTM();
  }, []);
  const [contentName, setContentName] = useState("三人成團購課－選課/創團頁面");
  const [contentCategory, setContentCategory] = useState(
    "kidpro_marketing_groupbuy"
  );
  const [itemIds, setItemIds] = useState([]); // product id array
  const [items, setItems] = useState([]); // product object array
  const [currency, setCurrency] = useState("TWD");
  const [value, setValue] = useState(NaN);
  const [utmOrderNumber, setUtmOrderNumber] = useState(NaN);
  const [shareMethod, setShareMethod] = useState("");
  useEffect(() => {
    if (!recTradeId && isNaN(status)) {
      // 若非點選結帳後導向此頁面 => utmPageView, utmAddToCart, utmShare
      setContentName("三人成團購課－選課/創團頁面");
      setContentCategory("kidpro_marketing_groupbuy");
      setItemIds(pidSelectedArray); // product id array
      setItems(
        pidSelectedArray?.map((pid, index) => {
          // product object array
          const itemObj = {
            id: pid,
            quantity: 1,
            price: coursePidDiscountPriceObjectArray[index]?.price,
          };
          return itemObj;
        })
      );
      setCurrency("TWD");
      setValue(coursePidDiscountPriceObjectArray[0]?.totalPrice);
      setUtmOrderNumber(
        Array.isArray(pidSelectedArray) ? pidSelectedArray?.length : NaN
      );
    } else {
      // 若點完結帳導向此頁面 => utmPageView, utmPurchase
      setContentName("三人成團購課－選課/創團頁面");
      setContentCategory("kidpro_marketing_groupbuy");
      const pIds = JSON.parse(window.localStorage.getItem("pIds"));
      if (Array.isArray(pIds)) {
        setItemIds(pIds); // product id array
        setItems(
          pIds?.map((pid, index) => {
            // product object array
            const itemObj = {
              id: pid,
              quantity: 1,
              price: coursePidDiscountPriceObjectArray[index]?.price,
            };
            return itemObj;
          })
        );
      }
      setCurrency("TWD");
      const totalPrice = window.localStorage.getItem("tPrc");
      setValue(totalPrice);
      setUtmOrderNumber(
        Array.isArray(pidSelectedArray) ? pidSelectedArray?.length : NaN
      );
    }
  }, [pidSelectedArray, coursePidDiscountPriceObjectArray]);
  UtmEvents(
    contentName,
    contentCategory,
    itemIds,
    items,
    currency,
    value,
    utmOrderNumber,
    shareMethod
  ); // UTM事件全域參數設定，UtmEvents Component內已有useEffect，外層不需再用useEffect
  const utmPageViewAsync = async () => {
    const { Utm, UtmKidPro, UtmKidPro2 } = await configUTM();
    // await utmPageView(Utm, `瀏覽【${contentName}】`); // 瀏覽頁面事件送出
    const eventID = await conversionApiViewPage();
    eventID.toString();
    // console.log('utmPageViewAsync', Utm, UtmKidPro, eventID);
    await utmPageView(Utm, eventID, `瀏覽【${contentName}】`); // 瀏覽頁面事件送出
    await utmPageView(UtmKidPro, eventID, `瀏覽【${contentName}】`); // 瀏覽頁面事件送出
    await utmPageView(UtmKidPro2, eventID, `瀏覽【${contentName}】`); // 瀏覽頁面事件送出
  };
  useEffect(() => {
    utmPageViewAsync();
  }, []);
  /*** END UTM Events ***/

  // /*** 取得UTM ***/
  // const getUtmParams = async(event) => {
  //   const urlParams = window.location.search;
  //   const urlParamsObj = new URLSearchParams(urlParams);
  //   if( urlParamsObj.has("utm_id") ) {
  //     const queryString = urlParamsObj.toString();
  //     window.localStorage.setItem("utm", queryString);
  //     const queryEventString = queryString + "&utm_event=" + event;
  //     return queryEventString;
  //   }
  //   return "";
  // }

  // /*** 第一次進入頁面傳UTM ***/
  // const sendUtmParams = async() => {
  //   const queryEventString = await getUtmParams("view");
  //   if(queryEventString) {
  //     await fetch('https://' + apiURL + '/api/marketing/v1/utm-recorder?' + queryEventString, {
  //       method: 'POST',
  //     })
  //     .then((r) => {
  //       if( ! r.ok ) {
  //         // // console.log("utm sending error");
  //       }
  //     })
  //     .catch((error) => {
  //       // // console.log("utm sending error", error);
  //     });
  //   }
  // }
  // useEffect(()=>{sendUtmParams()},[]);

  // const scrollToBabyFormFunc = () => {
  //   // // // console.log(window.location.search);
  //   if(window.location.search.indexOf('baby-form-div')>-1) {
  //     // scroller.scrollTo('scrollToBabyForm');
  //     let scroll = Scroll.animateScroll;
  //     scroll.scrollToBottom();
  //   }
  // }
  // useEffect(()=>{
  //   scrollToBabyFormFunc();
  // },[])

  // /*** FB Pixel 相關 ***/
  // const FbPixelID = process.env.REACT_APP_FB_PIXEL_ID;
  // // Pixel組態設定
  // const options = {
  //   autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  //   debug: false, // enable logs
  // };
  // ReactPixel.init(FbPixelID, {}, options);
  // const pixelPageView = () => {
  //   ReactPixel.trackCustom('瀏覽【三人成團購課－選課/創團頁面】', {content_category: 'kidpro_marketing_page', content_ids: 'view_groupbuy_creategroup', content_name: '三人成團購課－選課/創團頁面' });
  // }
  // // const pixelSubmitForm = () => {
  // //   ReactPixel.trackCustom('點選【確認更新】送出寶寶表單', {content_category: 'kidpro_marketing_submit', content_ids: 'submit_babyform', content_name: '完成LINE綁定送銀卡會員－活動頁－確認更新'});
  // // }
  // /*** END FB Pixel 相關 ***/

  // const [cookies, setCookie] = useCookies(['utm']); // useCookies hook
  // // console.log('cookies.cid', cookies.cid);
  // // const [cookies, setCookie] = useCookies(['presence']); // useCookies hook
  // // console.log('cookies._ga', cookies._ga);

  // /*** GA4相關 ***/
  //  // ReactGA.send({ hitType: "pageview", page: '/' }); // GA pageview設定
  // /*  page_view event parameters
  //  * page_location	string	No	https://example.com	The URL of the page.
  //  * client_id	string	No	ABC_123	The client ID.
  //  * language	string	No	en_us	The language for the client. See Language tags and codes.
  //  * page_encoding	string	No	UTF-8	The encoding of the page.
  //  * page_title	string	No	Home	The title of the page.
  //  * user_agent	string	No		The client's user agent.
  //  */
  // /* 瀏覽頁面時，送出事件給GA4 */
  // const sendGa4 = () => {
  //   ReactGA.event( 'page_view', {
  //     page_location: window.location.pathname,
  //     client_id: cookies._ga,
  //     language: 'zh_tw',
  //     page_encoding: 'UTF-8',
  //     page_title: '三人成團購－選課/創團頁面',
  //   });
  //   ReactGA.send({ hitType: "event" });
  //   // // // console.log('ReactGA',ReactGA);
  // }
  // /* 瀏覽頁面的事件只在渲染時執行一次 */
  // useEffect(() => {
  //   pixelPageView();
  //   sendGa4();
  // }, []);
  // /*** END GA4相關 ***/

  /*** Conversion API ***/
  const conversionApiViewPage = async () => {
    let BearerToken = undefined;
    if (loginToken) {
      BearerToken = "Bearer " + loginToken;
    }
    try {
      const response = await fetch(
        "https://" +
          microservApiURL +
          "/view-content?sourceUrl=" +
          encodeURI(window.location.href),
        {
          method: "GET",
          credentials: "include",
          headers: {
            Authorization: BearerToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Request failed: conversionApiViewPage()");
      }
      const result = await response.json();
      // console.log(result)
      if (result.success) {
        const eventID = result?.trackerData?.eventId;
        return eventID;
      } else {
        throw new Error("Request failed: conversionApiViewPage()");
        // return '';
      }
    } catch (error) {
      console.log("ConversionApiViewPage Error");
      // throw new Error('ConversionApiViewPage Error: ', error);
      // return '';
    }
  };
  // const conversionApiViewPage = async() => {
  //   let BearerToken = undefined;
  //   if( loginToken ) {
  //     BearerToken = 'Bearer ' + loginToken;
  //   }
  //   await fetch('https://' + microservApiURL + '/view-content?sourceUrl=' + encodeURI(window.location.href), {
  //     method: 'GET',
  //     credentials: "include",
  //     headers: {
  //       'Authorization': BearerToken,
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/json'
  //     }
  //   })
  //   .then((r) => r.json())
  //   .then((result) => {
  //     if(result.success) {
  //       console.log('ConversionApiViewPage Success.');
  //       return result?.trackerData?.eventId
  //     } else {
  //       console.log('ConversionApiViewPage Failure.', result);
  //       return ''
  //     }
  //   })
  //   .catch((error) => {
  //     console.log('ConversionApiViewPage Error');
  //     return ''
  //   });
  // }
  // useEffect(()=>{
  //   conversionApiViewPage();
  // },[]);
  const conversionApiAddToCart = async (pids) => {
    // const BearerToken = 'Bearer ' + loginToken;
    const data = {
      products: pids,
    };
    // console.log('data',data);
    await fetch("https://" + microservApiURL + "/add-to-cart", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        // 'Authorization': BearerToken,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json())
      .then((result) => {
        if (result.success) {
          console.log("ConversionApiAddToCart Success.");
          const addToCartEventId = result?.trackerData?.eventId;
          // setUtmAddToCartEventId(addToCartEventId);
          sendUtmAddToCart(addToCartEventId);
        } else {
          console.log("ConversionApiAddToCart Failure.", result);
        }
      })
      .catch((error) => {
        console.log("ConversionApiAddToCart Error: ", error);
      });
  };
  /*** 送出加入購物車UTM ***/
  const sendUtmAddToCart = async (utmAddToCartEventId) => {
    if (
      utmEventsInit &&
      utm &&
      utmKidPro &&
      utmKidPro2 &&
      utmAddToCartEventId
    ) {
      // utmAddToCart(utm, `加入購物車【${contentName}】`);
      const utmAddToCartAsync = async () => {
        // const eventID = await conversionApiViewPage();
        // console.log('utmAddToCartEventId', utmAddToCartEventId)
        utmAddToCartEventId.toString();
        // console.log('sendUtmAddToCart', utm, utmKidPro, utmAddToCartEventId);
        utmAddToCart(utm, utmAddToCartEventId, `加入購物車【${contentName}】`);
        utmAddToCart(
          utmKidPro,
          utmAddToCartEventId,
          `加入購物車【${contentName}】`
        );
        utmAddToCart(
          utmKidPro2,
          utmAddToCartEventId,
          `加入購物車【${contentName}】`
        );
      };
      utmAddToCartAsync();
    }
  };
  // useEffect(() => {
  //   console.log('utmAddToCartEventId', utmAddToCartEventId)
  //   sendUtmAddToCart();
  // }, [utmAddToCartEventId]);
  /*** END Conversion API ***/

  /* 返回上一頁清除使用者資料 */
  const previousPageClearStorage = () => {
    // window.addEventListener("popstate", function (e) {
    window.localStorage.removeItem("username");
    window.localStorage.removeItem("smsVeriStatus");
    window.localStorage.removeItem("phoneNumber");
    window.localStorage.removeItem("url");
    // }, false);
  };

  /* 案X清除Local Storage並登出LINE、FB */
  const clearStorage = (event) => {
    event.preventDefault();
    // 如果LINE有登入，執行LINE登出
    const isLoggedIn = liff.isLoggedIn();
    if (isLoggedIn) {
      liff.logout();
    }
    // 如果FB物件存在，且為登入中，將FB登出
    if (!!window.FB) {
      window.FB.getLoginStatus(function (response) {
        if (response.status === "connected") {
          window.FB.logout(function (response) {
            // // // // console.log('handleFBLogout', response);
          });
        }
      });
    }
    localStorage.clear();
    setNOuaabindLine(false);
    setEmail(null);
    setSheet("LoginIndex");
  };

  //設定loading 轉圈圈
  const [isLoading, setIsLoading] = useState(false);

  /* 檢查使用者是否參加過活動或已登記活動表單(由官網登入返回後再做一次檢查並導向) */
  const checkActivityAvailable = () => {
    const loginToken = window.localStorage.getItem("loginToken")
      ? window.localStorage.getItem("loginToken")
      : "";
    const BearerToken = "Bearer " + loginToken;
    // handleShow();
    setIsLoading(true);
    // POST送出手機資料，驗證是否成功
    fetch("https://" + apiURL + "/api/marketing/event_user/share_yoga", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: BearerToken,
      },
    })
      .then((r) => r.json())
      .then((result) => {
        if (result.status === 200 && !!result.data) {
          setIsLoading(false);
          handleClose();
          alert("您已經登記過此活動囉！");
          window.location.assign(
            window.location.protocol +
              "//" +
              window.location.host +
              "/CountingPage"
          );
        } else if (result.status === 400 && result.errorCode === 1001) {
          setIsLoading(false);
          handleClose();
          alert("您已經參加過上次活動囉！");
          window.location.assign(
            window.location.protocol +
              "//" +
              window.location.host +
              "/Forbidden"
          );
        } else if (result.status === 404 && result.errorCode === 1002) {
          setIsLoading(false);
          handleClose();
          window.location.assign(
            window.location.protocol +
              "//" +
              window.location.host +
              "/SubmitPage"
          );
        } else if (result.status === 403 && result.errorCode === 1003) {
          setIsLoading(false);
          handleClose();
          alert("您還沒有取得完課證明喔！");
        } else {
          setIsLoading(false);
          alert("伺服器錯誤，請稍後再試！");
          handleClose();
          window.location.assign(
            window.location.protocol + "//" + window.location.host + "/"
          );
        }
      })
      .catch((error) => {
        setIsLoading(false);
        alert("伺服器錯誤，請稍後再試！");
        handleClose();
        window.location.assign(
          window.location.protocol + "//" + window.location.host + "/"
        );
      }); // END Fetch
  };

  /* 檢查登入狀態，有JWT直接跳到填寫表單頁面；無JWT則彈出登入視窗 */
  function checkJWT() {
    // // // // console.log(loginToken);
    if (!!loginToken) {
      checkActivityAvailable();
      // window.location.assign( "/CheckActivity?redir=kidpro" ); // 檢查活動並導向相應頁面
      // Hide loading screen
    } else {
      // window.location.reload();
      // 請先完成登入，才能前往登記活動！
      handleShow();
      // window.location.assign('/#');
    }
  }

  const lineid = process.env.REACT_APP_LINE_APP_ID;
  // // // // console.log('lineid',lineid);
  let line_liff_loginData = {};

  /* 點LINE註冊，檢驗使用者是否已綁定過LINE。 (LINE登入走LoginIndex->LoginEncode->CheckActivity->導向KidPro官網登入－> ( 1.有JWT: 回CheckActivity檢驗活動狀態－>導向首頁/submitpage/countingpage ) ( 2.無JWT： 導向首頁繼續下方判斷 ) */
  const linelogin = async () => {
    // console.log('linelogin')
    const init = await liff
      .init({
        liffId: lineid, // Use own liffId
        // withLoginOnExternalBrowser: true, // Enable automatic login process
      })
      .catch((err) => {
        // Todo: retry init.
        // // // // console.log('err',err);
      });
    const isLoggedIn = liff.isLoggedIn();
    // // // // console.log('line',isLoggedIn);
    if (isLoggedIn === true) {
      let redirectUrl = window.location.href;
      const accessToken = liff.getAccessToken();
      line_liff_loginData = {
        accessToken: accessToken,
      };
      fetch("https://uaa.kid-pro.com/api/uaa/line_liff_login", {
        method: "POST",
        body: JSON.stringify(line_liff_loginData),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((r) => r.json())
        .then((result) => {
          // // // console.log('result',result);
          if (result.status === 200 && !LIFF_STOREregister) {
            // 1. LINE登入/其他狀況，使用者已綁定，導向CheckActivity進行登入並檢查活動
            // 從line登入頁面進來
            // navbar show 登出 有token  NOuaabindLine false(有綁定line and uaa)
            window.localStorage.setItem("loginToken", result.data.loginToken);
            setNOuaabindLine(false);
            setAuth({ ...auth, authorized: true });
            if (!!loginToken) {
              // window.location.assign( "/CheckActivity?redir=kidpro" );
            }
            // // // console.log('01');
          } else if (result.status === 200 && LIFF_STOREregister) {
            // 2. LINE註冊，使用者已綁定
            // onsole.log('02');
            // 從註冊頁面line登入進來，有帳號uaa+line，進入帳號已存在popup
            window.localStorage.setItem(
              "lineRegisterLoginToken",
              result.data.loginToken
            ); // 只記錄login token，而不做登入；待使用者點選登入才登入
            const BearerToken = "Bearer " + result.data.loginToken;
            fetch("https://uaa.kid-pro.com/api/uaa/user_pofile", {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: BearerToken,
              },
            })
              .then((r) => r.json())
              .then((result) => {
                if (!window.localStorage.getItem("loginToken")) {
                  // setSheet('accountExist');
                  setEmail(result.data.email);
                  handleShow(); // 未登入則顯示帳號已存在，問使用者是否登入
                } else {
                  handleClose(); // 已登入則不彈出登入視窗
                }
              })
              .catch((error) => {
                // // // // console.log('error', error);
                alert("伺服器錯誤，請稍候再試！");
              });
          } else if (result.statusCode === 400 && LIFF_STOREregister) {
            // 3. LINE註冊，使用者未綁定且無帳號
            //從註冊頁面line登入進來，無帳號，進入註冊
            // // // // console.log('03');
            handleShow();
            // setSheet('registerPhone');
            setSheet("NOuaabindLine");
          } else if (result.statusCode === 400 && !LIFF_STOREregister) {
            // 4. LINE登入，使用者未綁定
            //從line登入頁面進來 無綁定 進入NoLineDataLogin popup
            handleShow();
            //setNOuaabindLine(false);
            setSheet("NOuaabindLine");
            // // // // console.log('04');
            //show 登入
          } else if (result.statusCode === 403 && LIFF_STOREregister) {
            // 3. LINE註冊，使用者未綁定且無帳號
            //404 資料庫找不到line 資料
            //從註冊頁面line登入進來，無帳號，進入註冊
            // // // // console.log('05');
            handleShow();
            setSheet("NOuaabindLine");
          } else if (result.statusCode === 403 && !LIFF_STOREregister) {
            // 4. LINE登入，使用者未綁定
            //404 資料庫找不到line 資料
            //從line登入頁面進來 無綁定 進入NoLineDataLogin popup
            handleShow(); //show 登入
            // setNOuaabindLine(true);
            setSheet("NOuaabindLine");
            // // // // console.log('06');
          } else {
            alert("伺服器錯誤，請稍候再試！");
            // // // // console.log('LINE登入失敗！')
            //show 登入
          }
        });
    }
  };

  /* 導向瑜珈球頁面 */
  const redirToYogaball = () => {
    const loginToken = window.localStorage.getItem("loginToken")
      ? window.localStorage.getItem("loginToken")
      : ""; // 由localStorage取得loginToken(設置登入狀態)
    const yogaballUrl = window.localStorage.getItem("yogaballUrl")
      ? window.localStorage.getItem("yogaballUrl")
      : ""; // 由localStorage取得瑜珈球頁面連結
    if (!!loginToken && !!yogaballUrl) {
      // 若以https://liff.line.me/{LineDevID}?redir=...進行登入，取得先前存入localStorage的連結，加入JWT進行官網登入導向
      const kidproLoginYogaballUrl =
        "https://kid-pro.com/wp-json/simple-jwt-login/v1/autologin?JWT=" +
        loginToken +
        "&redirectUrl=" +
        yogaballUrl;
      // // // // console.log('kidproLoginYogaballUrl',kidproLoginYogaballUrl);
      // // // // console.log('導向瑜珈球頁面前');
      window.localStorage.setItem("yogaballUrl", ""); // 清空導向值
      window.location.assign(kidproLoginYogaballUrl);
    }
  };

  /* 彈出註冊成功 */
  const popupRegisterSuccess = () => {
    let queryString = window.location.search; // 由驗證Email之連結取得url query字串
    let urlParams = new URLSearchParams(queryString); // 分析url query字串
    let regStatus = urlParams.get("register"); // 取得url query字串中的註冊狀態值
    // // // // console.log('redir', redir);
    if (regStatus === "true") {
      // // // // console.log('regStatus', regStatus );
      setSheet("registerSucess");
      handleShow();
    }
  };

  /* 看別人怎麼寫連結 */
  const seeOtherPeopleWhrite = () => {
    window.open("https://kid-pro.com/6qtv", "_blank");
  };

  const collapseToggle = (e, idx) => {
    e?.preventDefault();
    const panelI = document?.getElementsByClassName(`coursePanel-${idx}`)[0];
    if (!!panelI && panelI.style.display === "block") {
      panelI.style.display = "none";
    } else if (!!panelI) {
      panelI.style.display = "block";
    }
  };

  /*** 改變課程選擇按鈕狀態 ***/
  const changeCourseSelectBtnState = (e, SN, state) => {
    // console.log({e, SN, state})
    e?.preventDefault();
    let courseSelectBtnStateTemp = [...courseSelectBtnState];
    courseSelectBtnStateTemp = courseSelectBtnStateTemp.map((el, idx) => {
      if (idx === SN) {
        return state;
      } else {
        return el;
      }
    });
    setCourseSelectBtnState(courseSelectBtnStateTemp);
  };

  /*** 查詢特定ID的ShortUrlCode ***/
  const findShortUrlCodeById = (targetId) => {
    // 使用 find 函式來遍歷 userTeamArray
    const targetJoinTeam = userTeamArray.find((team) => team?._id === targetId);
    if (targetJoinTeam) {
      // 找到符合 _id 條件的 joinTeam，回傳其 shortUrlCode
      return targetJoinTeam.shortUrlCode;
    } else {
      // 若未找到，回傳空字串或其他您希望的預設值
      return "";
    }
  };

  /*** 複製文字 ***/
  const copyText = (e, _id) => {
    e?.preventDefault();
    // let copy = userShortUrl;
    let copy = findShortUrlCodeById(_id);
    navigator.clipboard
      .writeText(copy)
      .then(() => {
        //複製完成顯示checkMark
        // setShowCheckMark(true);
        setShowCheckMark(_id);
        //複製完成三秒後checkMark消失
        setTimeout(() => {
          // setShowCheckMark(false);
          setShowCheckMark("");
        }, 3000);
      })
      .catch(() => {
        // setShowCheckMark(false);
        setShowCheckMark("");
      });
  };

  /*** 選擇課程後重置pidSelectedArray State ***/
  const resetPidSelectedArray = () => {
    // // console.log('courseSelectBtnState',courseSelectBtnState)
    const pidSelectedArrayInit = [
      "33589",
      "20307",
      "100358",
      "60464",
      "231206",
    ];
    const pidSelectedArrayTemp = courseSelectBtnState
      .map((value, index) =>
        value === "Selected" ? pidSelectedArrayInit[index] : null
      )
      .filter((value) => value !== null);
    setPidSelectedArray(pidSelectedArrayTemp);
  };
  useEffect(() => {
    // // console.log('courseSelectBtnState',courseSelectBtnState)
    // // console.log('pidSelectedArray',pidSelectedArray)
    // // console.log('recTradeId',recTradeId)
    // // console.log('status',status)
    // // console.log('! recTradeId && status === -1',! recTradeId && status === -1)
    if (!recTradeId && isNaN(status)) {
      resetPidSelectedArray();
    }
  }, [courseSelectBtnState]);

  /**
   * 計算單堂課程折扣價格及總價(開團)
   * ids of courses
   *   33589 KidPro 嬰幼兒父母必修課
   *   20307 KidPro 嬰幼兒感覺統合啟蒙課程
   *   100358 兒童感覺統合訓練課程
   *   60464 KidPro 兒童注意力與執行力訓練課程
   *   231206 KidPro 全方位口肌訓練課程
   */
  const getCourseDiscountPriceStateCreateTeam = () => {
    const coursePriceMap = {
      20307: 5800,
      33589: 8800,
      100358: 5800,
      60464: 5800,
      231206: 8800,
    };
    const prcentageDiscount = [0.4, 0.35, 0.33, 0.32, 0.3];
    const courseLength = pidSelectedArray?.length;
    const discount = prcentageDiscount[courseLength - 1];
    const discountShow = Math.round(discount * 100) / 10;
    const totalPrice = pidSelectedArray?.reduce((total, pid) => {
      return total + Math.round(coursePriceMap[pid] * discount);
    }, 0);
    if (Array.isArray(pidSelectedArray)) {
      const pidSelectedArrayTemp = pidSelectedArray.map((pid) => {
        return {
          pid: pid,
          discount: discountShow,
          price: Math.round(coursePriceMap[pid] * discount),
          totalPrice: totalPrice,
        };
      });
      setCoursePidDiscountPriceObjectArray(pidSelectedArrayTemp);
    }
    // return [
    //   Math.round(discount*100) / 10,
    //   Math.round(coursePriceMap[courseId]*discount),
    //   coursePriceMap[courseId]
    // ];
  };
  /**
   * 計算單堂課程折扣價格及總價(加團)
   * ids of courses
   *   33589 KidPro 嬰幼兒父母必修課
   *   20307 KidPro 嬰幼兒感覺統合啟蒙課程
   *   100358 兒童感覺統合訓練課程
   *   60464 KidPro 兒童注意力與執行力訓練課程
   *   231206 KidPro 全方位口肌訓練課程
   */
  const getCourseDiscountPriceStateAddTeam = () => {
    const coursePriceMap = {
      20307: 5800,
      33589: 8800,
      100358: 5800,
      60464: 5800,
      231206: 8800,
    };
    const prcentageDiscount = [0.4, 0.35, 0.33, 0.32, 0.3];
    const courseLength = userProductsArray?.length;
    const discount = prcentageDiscount[courseLength - 1];
    const discountShow = Math.round(discount * 100) / 10;
    const totalPrice = userProductsArray?.reduce((total, pid) => {
      return total + Math.round(coursePriceMap[pid] * discount);
    }, 0);
    const userProductsArrayTemp = userProductsArray.map((pid) => {
      return {
        pid: pid,
        discount: discountShow,
        price: Math.round(coursePriceMap[pid] * discount),
        totalPrice: totalPrice,
      };
    });
    setCoursePidDiscountPriceObjectArray(userProductsArrayTemp);
    // return [
    //   Math.round(discount*100) / 10,
    //   Math.round(coursePriceMap[courseId]*discount),
    //   coursePriceMap[courseId]
    // ];
  };
  /**
   * 計算單堂課程折扣價格及總價(discount加入fetch data)
   * ids of courses
   *   33589 KidPro 嬰幼兒父母必修課
   *   20307 KidPro 嬰幼兒感覺統合啟蒙課程
   *   100358 兒童感覺統合訓練課程
   *   60464 KidPro 兒童注意力與執行力訓練課程
   *   231206 KidPro 全方位口肌訓練課程
   */
  // 將計算邏輯移到外部，這樣可以在渲染前計算折扣並設置狀態
  const calculateCourseDiscounts = (teams) => {
    if (Array.isArray(teams)) {
      const prcentageDiscount = [0.4, 0.35, 0.33, 0.32, 0.3];
      const teamsWithDiscount = teams.map((team) => {
        const productsLength = team?.products?.length || 0;
        const discount = prcentageDiscount[productsLength - 1] || 0;
        return {
          ...team,
          discount: discount,
        };
      });
      return teamsWithDiscount;
    }
    return {};
  };
  //  const calculateDiscount = (productsLength) => {
  //   const prcentageDiscount = [0.4, 0.35, 0.33, 0.32, 0.3];
  //   // const discounts = teams.map((team) => {
  //     // const productsLength = team?.products?.length || 0;
  //   const discount = prcentageDiscount[productsLength - 1] || 0;
  //   return discount;
  //   // });
  //   setDiscount(discount);
  // };

  const getCourseDiscountPrice = (pidBoughtArray) => {
    const coursePriceMap = {
      20307: 5800,
      33589: 8800,
      100358: 5800,
      60464: 5800,
      231206: 8800,
    };
    const prcentageDiscount = [0.4, 0.35, 0.33, 0.32, 0.3];
    const courseLength = pidBoughtArray?.length;
    const discount = prcentageDiscount[courseLength - 1];
    const discountShow = Math.round(discount * 100) / 10;
    const totalPrice = pidBoughtArray?.reduce((total, pid) => {
      return total + Math.round(coursePriceMap[pid] * discount);
    }, 0);
    const pidBoughtArrayTemp = pidBoughtArray.map((pid) => {
      return {
        pid: pid,
        discount: discountShow,
        price: Math.round(coursePriceMap[pid] * discount),
        totalPrice: totalPrice,
      };
    });

    setCoursePidDiscountPriceObjectArray(pidBoughtArrayTemp);
    // return [
    //   Math.round(discount*100) / 10,
    //   Math.round(coursePriceMap[courseId]*discount),
    //   coursePriceMap[courseId]
    // ];
  };

  // useEffect(() => {
  //   if (item?.products?.length) {
  //     getCourseDiscountPrice(item?.products);
  //   }
  // }, [item?.products]);

  /*** 顯示所有Team資訊 ***/
  const getAllTeamInfo = async () => {
    const BearerToken = "Bearer " + loginToken;
    await fetch("https://" + microservApiURL + "/team", {
      method: "GET",
      headers: {
        Authorization: BearerToken,
        Accept: "application/json",
      },
    })
      .then((r) => r.json())
      .then((result) => {
        // // console.log('顯示所有Team資訊',result);
      })
      .catch((error) => {
        // setIsLoading(false);
        // // console.log('error',error);
      });
  };

  /*** 顯示所有未成團Team資訊 ***/
  const getUnfullTeamInfo = async () => {
    const BearerToken = "Bearer " + loginToken;
    await fetch("https://" + microservApiURL + "/team/notfull", {
      method: "GET",
      headers: {
        Authorization: BearerToken,
        Accept: "application/json",
      },
    })
      .then((r) => r.json())
      .then((result) => {
        // // console.log('顯示所有未成團Team資訊',result);
      })
      .catch((error) => {
        // setIsLoading(false);
        // // console.log('error',error);
      });
  };

  /*** 顯示當前使用者已購課資訊 ***/
  const getUserRegisterCourseInfo = async (n) => {
    // console.log(n)
    if (n === undefined) {
      n = 0;
    }
    // console.log(n)
    if (n < 2 && n !== -1) {
      if (Array.isArray(pidSelectedArray)) {
        const BearerToken = "Bearer " + loginToken;
        await fetch("https://" + microservApiURL + "/woocommerce/have-course", {
          method: "GET",
          headers: {
            Authorization: BearerToken,
            Accept: "application/json",
          },
        })
          .then((r) => r.json())
          .then((result) => {
            // // console.log('顯示當前使用者已購課資訊', result);
            const userHasRegisteredCourseArray = result.courseIds;
            if (Array.isArray(userHasRegisteredCourseArray)) {
              // // console.log('userHasRegisteredCourseArray',userHasRegisteredCourseArray);
              // const newCourseSelectBtnStateTemp = filterHasCourse(userHasRegisteredCourseArray);
              const pidSelectedArrayInit = [
                "33589",
                "20307",
                "100358",
                "60464",
                "231206",
              ];
              // 創造第三個 array，記錄已購課或未購課
              const hasCourseArray = pidSelectedArrayInit.map((pid) => {
                // // console.log("pid", pid);
                const pidInt = parseInt(pid);
                // // console.log("userHasRegisteredCourseArray", userHasRegisteredCourseArray);
                // // console.log("pidInt", pidInt);
                // // console.log("userHasRegisteredCourseArray.includes(pidInt)",userHasRegisteredCourseArray.includes(pidInt))
                return userHasRegisteredCourseArray.includes(pidInt)
                  ? "HasCourse"
                  : "NoCourse";
              });
              // // console.log('hasCourseArray',hasCourseArray);
              // 創造第五個 array，使用 "HasCourse" 覆蓋掉第四個 array 中的 "Select" 或 "Selected"
              const newCourseSelectBtnStateTemp = courseSelectBtnState.map(
                (status, index) =>
                  hasCourseArray[index] === "HasCourse" ? "HasCourse" : status
              );
              // // console.log('newCourseSelectBtnStateTemp',newCourseSelectBtnStateTemp);
              setNewCourseSelectBtnState(newCourseSelectBtnStateTemp);
              setCatchErrorBtnState(false);
              // calculateDiscount(result?.joinTeam);
            } else {
              // alert(`無法取得已購課資訊，請稍後再試！`);
              setCatchErrorBtnState(true);
              if (n === 1) {
                n = -2;
                // alert(`請稍後再試！`);
                // console.log(n)
                return;
              }
              setTimeout(() => {
                getUserRegisterCourseInfo(++n);
              }, 3000);
              setNewCourseSelectBtnState(courseSelectBtnState);
            }
          })
          .catch((error) => {
            // alert(`無法取得已購課資訊，請稍後再試！`);
            setCatchErrorBtnState(true);
            // console.log(error);
            if (n === 1) {
              n = -2;
              // alert(`請稍後再試！`);
              // console.log(n)
              return;
            }
            setTimeout(() => {
              getUserRegisterCourseInfo(++n);
            }, 3000);
            // setIsLoading(false);
            // console.log('error',error);
          });
      }
    } else {
      // alert(`請稍後再試！`);
      setCatchErrorBtnState(true);
    }
  };

  /*** 產生一個新的已選擇課程Array，濾掉已購課的課程 ***/
  const filterHasCourse = (userHasRegisteredCourseArray) => {
    const pidSelectedArrayInit = [
      "33589",
      "20307",
      "100358",
      "60464",
      "231206",
    ];
    // 創造第三個 array，記錄已購課或未購課
    const hasCourseArray = pidSelectedArrayInit.map((pid) =>
      userHasRegisteredCourseArray.includes(pid) ? "HasCourse" : "NoCourse"
    );

    // 創造第五個 array，使用 "HasCourse" 覆蓋掉第四個 array 中的 "Select" 或 "Selected"
    const newCourseSelectBtnState = courseSelectBtnState.map((status, index) =>
      hasCourseArray[index] === "HasCourse" ? "HasCourse" : status
    );
    return newCourseSelectBtnState;
  };

  useEffect(() => {
    setCourseSelectBtnState(newCourseSelectBtnState);
  }, [newCourseSelectBtnState]);

  /***  定义修改 createdAt 值的函数 ***/
  const updateCreatedAt = (teamId, newCreatedAt) => {
    // // console.log('updateCreatedAt',teamId, newCreatedAt);
    if (!!teamId && !!newCreatedAt) {
      const teamIndex = userTeamArray?.joinTeam?.findIndex(
        (team) => team?._id === teamId
      );
      // // console.log('teamIndex',teamIndex);
      // if (teamIndex !== -1) {
      //   const updatedTeam = { ...userTeamArray.joinTeam[teamIndex], createdAt: newCreatedAt };
      //   const updatedJoinTeam = [...userTeamArray.joinTeam];
      //   updatedJoinTeam[teamIndex] = updatedTeam;
      //   setUserTeamArray({ ...userTeamArray, joinTeam: updatedJoinTeam });
      // }
    }
  };

  /*** 顯示特定登入使用者的Team資訊 ***/
  const getUserTeamInfo = async () => {
    const BearerToken = "Bearer " + loginToken;
    await fetch("https://" + microservApiURL + "/team/userstatus", {
      method: "GET",
      headers: {
        Authorization: BearerToken,
        Accept: "application/json",
      },
    })
      .then((r) => r.json())
      .then((result) => {
        // // console.log('顯示特定登入使用者的Team資訊', result);
        // setUserTeamArray(result?.joinTeam);
        const teamsWithDiscount = calculateCourseDiscounts(result?.joinTeam); // 會將discount加入回傳的result array中
        // // console.log('teamsWithDiscount',teamsWithDiscount);

        setUserTeamArray(teamsWithDiscount);
        // 20230821 測試
        // const test = [{
        //   _id: '64df51f09bc857599d4f5a6a',
        //   products: [
        //       '33589',
        //       '20307',
        //       '100358',
        //       '60464'
        //   ],
        //   amount: 8384,
        //   members: [
        //       {
        //           userId: '9606',
        //           userName: '傑儒洪',
        //           userPhone: '+886978076260',
        //           userEmail: 'jack@lightup.tw',
        //           isVIP: false,
        //           orderId: '248702',
        //           orderNum: 'K0045058',
        //           recordId: '64df51cb9bc857599d4f5a60',
        //           recTradeId: 'D20230818n2GYKO',
        //           bankTransactionId: 'K0045058X1',
        //           lineName: '洪傑儒'
        //       },
        //       {
        //         userId: '9606',
        //         userName: '傑儒洪',
        //         userPhone: '+886978076260',
        //         userEmail: 'jack@lightup.tw',
        //         isVIP: false,
        //         orderId: '248702',
        //         orderNum: 'K0045058',
        //         recordId: '64df51cb9bc857599d4f5a60',
        //         recTradeId: 'D20230818n2GYKO',
        //         bankTransactionId: 'K0045058X1',
        //         lineName: '洪傑儒'
        //       },
        //       {
        //         userId: '9606',
        //         userName: '傑儒洪',
        //         userPhone: '+886978076260',
        //         userEmail: 'jack@lightup.tw',
        //         isVIP: false,
        //         orderId: '248702',
        //         orderNum: 'K0045058',
        //         recordId: '64df51cb9bc857599d4f5a60',
        //         recTradeId: 'D20230818n2GYKO',
        //         bankTransactionId: 'K0045058X1',
        //         lineName: '洪傑儒'
        //       }
        //   ],
        //   orderNum: 'K0045058',
        //   shortUrlCode: 'https://kidp.ro/QbuEo',
        //   discount: 0.32
        // }];
        // setUserTeamArray(test);

        setFetchedUserTeam(true);
        if (!!result?.joinTeam) {
          for (let el of result?.joinTeam) {
            if (el?._id) {
              const userTeamIdTemp = el?._id;
              const userOrderNumTemp = el?.orderNum;
              const userProductsTemp = el?.products;
              const startDateTemp = dayjs(el?.createdAt);
              const endDateTemp = startDateTemp.add(7, "day");
              const endDateFormatTemp =
                endDateTemp.format("YYYY年MM月DD日 HH時mm分");
              // setUserTeamIds([...userTeamIds, userTeamIdTemp]);
              setNewestUserBuyCourses(userProductsTemp);
              // setUserEndDates([...userEndDates, endDateFormatTemp]);
              // // // console.log('userTeamIdTemp',userTeamIdTemp);
              // // // console.log('endDateFormatTemp',endDateFormatTemp);
              // updateCreatedAt(userTeamIdTemp, endDateFormatTemp);
            }
          }
        }

        // const userTeamIdTemp = result?.joinTeam[0]?._id;
        // const userProductsTemp = result?.joinTeam[0]?.products;
        // const userMembersTemp = result?.joinTeam[0]?.members;
        // const userNameTemp = result?.joinTeam[0]?.userId; // 測試用
        // // const userNameTemp = result?.joinTeam[0]?.userName;
        // const startDateTemp = dayjs(result?.joinTeam[0]?.createdAt);
        // const endDateTemp = startDateTemp.add(7, 'day');
        // const endDateFormatTemp = endDateTemp.format('YYYY年MM月DD日 HH時mm分');
        // const userShortUrlTemp = result?.joinTeam[0]?.shortUrlCode; // 測試用
        // // const userShortUrlTemp = result?.joinTeam[0]?.shortUrlCode;
        // setUserTeamId(userTeamIdTemp);
        // setUserProducts(userProductsTemp);
        // setUserMembers(userMembersTemp);
        // setUserName(userNameTemp);
        // setUserEndDate(endDateFormatTemp);
        // setUserShortUrl(userShortUrlTemp);
      })
      .catch((error) => {
        // alert('無法取得團購資訊，請稍後再試！');
        // setIsLoading(false);
        // // console.log('error',error);
      });
  };
  // useEffect(()=>{
  //   updateCreatedAt(userTeamId, userEndDate); // 更新createdAt
  // },[userTeamId, userEndDate]);

  /*** 使用者uaa資訊 ***/
  const getUserUaaInfo = async () => {
    const BearerToken = "Bearer " + loginToken;
    await fetch("https://" + uaaApiURL + "/api/uaa/user_pofile", {
      method: "GET",
      headers: {
        Authorization: BearerToken,
        Accept: "application/json",
      },
    })
      .then((r) => r.json())
      .then((result) => {
        // // console.log('顯示特定登入使用者的Team',result);
        if (result.status === 200) {
          // setUserUaaInfo(result.data);
          // // console.log('使用者uaa資訊', result);
        } else {
          // // console.log('無法取得使用者資料');
        }
      })
      .catch((error) => {
        // setIsLoading(false);
        // // console.log('error',error);
      });
  };

  /*** 開團記錄選擇的課程商品的價格資訊 ***/
  useEffect(() => {
    getCourseDiscountPriceStateCreateTeam();
  }, [pidSelectedArray]);

  /*** 加團記錄開團者已購買課程商品的價格資訊  ***/
  useEffect(() => {
    getCourseDiscountPriceStateAddTeam();
  }, [userProductsArray]);

  /*** 分享至FB ***/
  const shareToFB = (d, s, id) => {
    let js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s);
    js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.0";
    fjs.parentNode.insertBefore(js, fjs);
  };

  /*** 分享至LINE ***/
  // <script type="text/javascript">LineIt.loadButton();</script>
  // <script async="async" defer="defer" src="https://www.line-website.com/social-plugins/js/thirdparty/loader.min.js"></script>
  // const shareToLine = () => {
  //   const script = document.createElement('script');
  //   script.src = "https://www.line-website.com/social-plugins/js/thirdparty/loader.min.js";
  //   script.defer = true;
  //   script.async = true;
  //   document.body.appendChild(script);
  //   return () => {
  //     document.body.removeChild(script);
  //   }
  // }

  /*** 分享至Messenger ***/
  // const shareToMessenger = (e) => {
  //   e?.preventDefault();
  //   window.open("https://www.messenger.com");
  // }
  const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  };
  const callApp = (url) => {
    if (isMobileDevice()) {
      window.open(
        `fb-messenger://share/?app_id=310181630132324&link=${url}&redirect_uri=https%3A%2F%2Fwww.facebook.com`
      );
    } else {
      window.open(
        `https://www.facebook.com/dialog/send?app_id=310181630132324&link=${url}&redirect_uri=https%3A%2F%2Fwww.facebook.com`
      );
    }
  };
  const gotoMessengerUrl = (e, url) => {
    e?.preventDefault();
    callApp(url);
  }; /*** END 分享至Messenger ***/

  /*** 彈出交易結果訊息 ***/
  // const showTapPayMessagePopup = () => {
  //   if (tapPayMessagePopupShow) {
  //     return (
  //       <TapPayMessagePopup
  //         tapPayMessagePopupShow={tapPayMessagePopupShow}
  //         setTapPayMessagePopupShow={setTapPayMessagePopupShow}
  //         tappaySwitchContentString={tapPayResult}
  //         tappayMessage={tappayMessage}
  //         setTappayBtnState={setGbPage}
  //         tappayParam1={teamId}
  //       ></TapPayMessagePopup>
  //     );
  //   }
  //   return null;
  // }
  // const PopupTapPayResult = ( props ) => {
  //   const {tapPayResult, tappayMessage, teamId, setGbPage} = props;
  //   return (
  //       <Modal
  //           show={showTapPayResult}
  //           onHide={handleCloseTapPayResult}
  //           backdrop="static"
  //           keyboard={false}
  //           centered
  //       >
  //           <Col className={tapPayStyle.modal}>
  //               <div className={tapPayStyle.modalClose} onClick={(event)=>{handleCloseTapPayResult()}}>x</div>
  //               <div className={tapPayStyle.modalHeader} >
  //                 <Modal.Title className={tapPayStyle.modalTitle}></Modal.Title>
  //                 <div className={tapPayStyle.PopupBanner}>
  //                     <img src={"https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/307b61fa-e830-473f-55f4-c2446e462c00/public"}></img>
  //                 </div>
  //               </div>
  //               <Modal.Body className={tapPayStyle.modalBody} >
  //                 { tapPayResult === "Success" ? (
  //                   <>
  //                     <Title level={4} className={tapPayStyle.titleH4}>信用卡授權成功，您已成功建立或加入團購！</Title>
  //                     <Title level={4} className={tapPayStyle.titleH4}>請將短網址發送給您的親朋好友，讓他們可以加入您的團購群組。</Title>
  //                   </>
  //                 ) : tapPayResult === "Failure" ? (
  //                   <>
  //                     <Title level={4} className={tapPayStyle.titleH4}>信用卡授權失敗！</Title>
  //                     <Title level={4} className={tapPayStyle.titleH4}>{tappayMessage}</Title>
  //                     { ! teamId ? (
  //                       <Button onClick={(e) => {
  //                         setGbPage('CheckoutPageCreateTeam');
  //                       }}>OK</Button>
  //                     ) : (
  //                       <Button onClick={(e) => {
  //                         setGbPage('CheckoutPageAddTeam');
  //                       }}>OK</Button>
  //                     )}
  //                   </>
  //                 ) : tapPayResult === "FailureOther" ? (
  //                   <>
  //                     <Title level={4} className={tapPayStyle.titleH4}>信用卡授權失敗！</Title>
  //                     <Title level={4} className={tapPayStyle.titleH4}>{tappayMessage}</Title>
  //                   </>
  //                 ) : (
  //                   <LoadingSpinner></LoadingSpinner>
  //                 )}
  //               </Modal.Body>
  //           </Col>
  //       </Modal>
  //   );
  // }
  /*** 彈出交易結果訊息 ***/
  const popupTransactionResult = async () => {
    const BearerToken = "Bearer " + loginToken;
    const data = {
      recTradeId,
      orderNumber,
      bankTransactionId,
      status,
      authCode,
    };
    // // console.log('data',data);
    await fetch("https://" + microservApiURL + "/traderesult", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Authorization: BearerToken,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json())
      .then(async (result) => {
        // let result = { code: 201, message: '授權成功', teamId:'12345', courseIds: ['33589', '20307'] }; // 測試用
        // // console.log('成功送出授權參數', result);
        const teamId = result?.teamId;
        const courseIds = result?.courseIds;
        const orderNum = result?.orderNum;
        // // console.log('teamId',teamId);
        // // console.log('courseIds',courseIds);
        // // console.log('orderNum',orderNum);
        if (result.code === 201) {
          const purchaseEventId = result?.trackerData?.eventId;
          // setUtmPurchaseEventId(purchaseEventId);
          sendUtmPurchase(purchaseEventId);
          // // console.log('授權參數送出成功');
          // alert('信用卡授權成功，您已成功建立或加入團購！請將短網址發送給您的親朋好友，讓他們可以加入您的團購群組。');

          setUserProductsArray([]);
          setPidSelectedArray([]);
          setOrderNum(undefined);

          setTapPayResult("Success");
          setTappayMessage("");
          // setTimeout(() => {
          setTapPayMessagePopupShow(true);
          // }, 3000);
          // setErrMsg('信用卡授權成功，您已成功建立或加入團購！請將短網址發送給您的親朋好友，讓他們可以加入您的團購群組。');
          // // console.log(result);
          // setShowAntLoading(false);
        } else {
          // console.log('授權參數送出失敗');
          // alert(`授權失敗，Prime錯誤！\n${JSON.stringify(result?.message)}`);

          /*** UTM Purchase(test) ***/
          // if( utmEventsInit ) {
          //   const {UtmKidPro} = await configUTM();
          //   // utmPurchase(Utm, `完成購買【${contentName}】`);
          //   const eventID = await conversionApiViewPage();
          // utmPurchase(UtmKidPro, eventID, `完成購買【${contentName}】`);
          // }
          const purchaseEventId = result?.trackerData?.eventId;
          sendUtmPurchase(purchaseEventId);

          setTapPayResult("Failure");
          setTappayMessage(result?.message);
          setTapPayMessagePopupShow(true);
          if (!teamId) {
            setPidSelectedArray(courseIds);
            setOrderNum(orderNum);
          } else {
            setTeamId(teamId);
            setUserProductsArray(courseIds);
            setOrderNum(orderNum);
          }
          // setErrMsg(`授權失敗，Prime錯誤！\n${JSON.stringify(result?.message)}`);
          // setShowAntLoading(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        // alert(`授權失敗，Prime錯誤！\n${JSON.stringify(error)}`);
        setTapPayResult("Failure");
        setTappayMessage(error);
        setTapPayMessagePopupShow(true);
        // setShowAntLoading(false);
      });
    clearAddTeamCoursesStorage(); // clear teamId & pIds in localStorage
  };
  useEffect(() => {
    // // console.log('recTradeId',recTradeId);
    // // console.log('status',status);
    if (!!recTradeId && !isNaN(status)) {
      popupTransactionResult();
    }
  }, [recTradeId, status]);
  /*** UTM Purchase ***/
  const sendUtmPurchase = async (utmPurchaseEventId) => {
    // const {Utm, UtmKidPro} = await configUTM(); // test
    // console.log('sendUtmPurchase', Utm, UtmKidPro, utmPurchaseEventId); // test
    if (utmEventsInit && utm && utmKidPro && utmKidPro2 && utmPurchaseEventId) {
      const { Utm, UtmKidPro, UtmKidPro2 } = await configUTM();
      // utmPurchase(Utm, `完成購買【${contentName}】`);
      // const eventID = await conversionApiViewPage();
      // console.log({UtmKidPro, utmPurchaseEventId, contentName})
      // console.log('sendUtmPurchase', Utm, UtmKidPro, utmPurchaseEventId);
      utmPurchase(Utm, utmPurchaseEventId, `完成購買【${contentName}】`);
      utmPurchase(UtmKidPro, utmPurchaseEventId, `完成購買【${contentName}】`);
      utmPurchase(UtmKidPro2, utmPurchaseEventId, `完成購買【${contentName}】`);
    }
  };
  // useEffect(() => {
  //   console.log('utmPurchaseEventId', utmPurchaseEventId)
  //   sendUtmPurchase();
  // }, [utmPurchaseEventId]);

  /*** 滑至特定ID區塊 ***/
  const scrollToId = () => {
    const urlSplit = window.location?.href?.split("#");
    if (Array.isArray(urlSplit) && urlSplit?.length >= 2) {
      const urlAnchor = urlSplit[1];
      // // console.log(urlAnchor);
      const anchorElement = document.getElementById(urlAnchor);
      setTimeout(() => {
        anchorElement.scrollIntoView({ behavior: "smooth", block: "end" });
      }, 1000);
    }
  };

  /*** 清除加團選課storage ***/
  const clearAddTeamCoursesStorage = () => {
    window.localStorage.removeItem("teamId");
    window.localStorage.removeItem("pIds");
  };

  useEffect(() => {
    linelogin();
    // redirToYogaball();
    previousPageClearStorage();

    getAllTeamInfo();
    getUserRegisterCourseInfo();
    getUserTeamInfo();

    shareToFB(document, "script", "facebook-jssdk");

    scrollToId();
    // shareToLine();
    // collapseToggle();
    // checkUserLineBind();
    // popupRegisterSuccess();
  }, []);

  return (
    <>
      {gbPage === "CreateGroupPage" && !!loginToken ? (
        <Container id="anchor" fluid className={style.container_fluid}>
          <Row className={style.gbContainerRow}>
            <Col className={style.container_customMargin}>
              <Col className={style.bannerColPhone}>
                <Layout className={style.layoutPhone}>
                  <img
                    style={{ width: "100%" }}
                    src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/e2ed971b-03d4-4b0e-c76a-93c0e869c600/public"
                  ></img>
                </Layout>
              </Col>
              <Col className={style.bannerColPC}>
                <div className={style.bannerSec}>
                  <div className={style.bannerTitleImgDiv}>
                    <Layout className={style.layoutComp}>
                      <img
                        style={{ width: "100%", paddingBottom: 20 }}
                        src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/95edfbd2-a682-4a1e-27f5-eecd37c6cd00/public"
                      ></img>
                    </Layout>
                  </div>
                </div>
              </Col>
              <Col className={style.gbMain}>
                <Col className={style.memberTableCol}>
                  <div className={style.memberDescriptDiv}>
                    <Title level={3} className={style.titleH3_6}>
                      湊滿3人合購線上課程
                    </Title>
                    <Title level={5} className={style.titleH5}>
                      依團購課程數量 享有不同折扣
                    </Title>
                    <Title level={5} className={style.titleH5}>
                      1堂4折，2堂3.5折，3堂3.3折，4堂3.2折，5堂3折
                    </Title>
                  </div>
                </Col>
                <Row className={style.gbRowCourse}>
                  <Col className={style.gbColCourse02Title}>
                    <Title level={3} className={style.titleH3_3}>
                      新生兒必買
                    </Title>
                  </Col>
                  <Col className={style.gbColCourse02Content}>
                    <Row className={style.gbRowCourseImg}>
                      <Col className={style.gbColCourseImg}>
                        <img
                          className={`${style.gbImgCoursePhone}`}
                          src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/8d6b5aa3-343e-4635-7133-c77801ab8200/public"
                          onClick={(e) => {
                            e?.preventDefault();
                            window.open("https://kid-pro.com/product/cc-0-2/");
                          }}
                        ></img>
                        <div
                          className={style.gbColCourseDescription}
                          style={{ marginRight: 12 }}
                          onClick={(e) => {
                            collapseToggle(e, 0);
                          }}
                        >
                          <Title level={4} className={style.titleH4}>
                            嬰幼兒 父母必修課
                          </Title>
                          {courseSelectBtnState[0] === "Selected" ? (
                            <Title
                              level={4}
                              className={style.titleH4_2_lineThrough}
                            >
                              原價：8800元
                            </Title>
                          ) : (
                            <Title level={4} className={style.titleH4_2}>
                              原價：8800元
                            </Title>
                          )}
                          {coursePidDiscountPriceObjectArray
                            ?.filter((item) => item.pid === "33589")
                            ?.map((item, idx) => {
                              return (
                                <Title level={4} className={style.titleH3_2}>
                                  團購價：{item?.discount}折 {item?.price}元
                                </Title>
                              );
                            })}
                          <Title
                            level={4}
                            className={`${style.titleH4_3} courseCollapse courseCollapse-0`}
                          >
                            課程介紹
                          </Title>
                        </div>
                      </Col>
                      <div
                        className={`${style.gbPanel} coursePanel coursePanel-0`}
                      >
                        　　本課程整合父母在孩子出生到三歲前應具備的相關知識，以及教養技巧，建立正確的科學教養知識，未來可以分辨市面上各種廣告或教養方式的能力。這堂課，由醫學領域中各類育兒專家共同企劃包含心理、語言、職能等，內容涵蓋父母心理、語言萌發技巧、動作發展處進、正確進食喝水觀念、副食品、幼兒情緒、嬰兒按摩等。影片實際示範教學，實際好操作，深入淺出。未來將陸續將課程內容將進行升級，將陸續加入兒科醫師、泌乳相關、護理等知識，是一堂活的線上課程，課程中有任何疑問都可以透過LINE與老師溝通詢問。是新生兒父母必備的育兒秘笈，已服務超過6400名學員，是全台灣最暢銷的育兒線上課程。
                        <div className={`${style.gbDivAntButtonCourseContent}`}>
                          <AntButton
                            className={`${style.gbAntButtonCourseContent}`}
                            onClick={(e) => {
                              e?.preventDefault();
                              window.open("https://kid-pro.com/product/cc-0-2");
                            }}
                          >
                            詳細介紹
                          </AntButton>
                        </div>
                      </div>
                    </Row>
                    <Row className={style.gbRowCourseImg}>
                      <Col className={style.gbColCourseImg}>
                        <img
                          className={style.gbImgCoursePhone}
                          src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/8b0380e3-4473-41f5-40df-5f19d1262200/public"
                          onClick={() => {
                            window.open("https://kid-pro.com/product/si0-2/");
                          }}
                        ></img>
                        <div
                          className={style.gbColCourseDescription}
                          style={{ marginRight: 12 }}
                          onClick={(e) => {
                            collapseToggle(e, 1);
                          }}
                        >
                          <Title level={4} className={style.titleH4}>
                            嬰幼兒 感覺統合啟蒙課程
                          </Title>
                          {courseSelectBtnState[1] === "Selected" ? (
                            <Title
                              level={4}
                              className={style.titleH4_2_lineThrough}
                            >
                              原價：5800元
                            </Title>
                          ) : (
                            <Title level={4} className={style.titleH4_2}>
                              原價：5800元
                            </Title>
                          )}
                          {coursePidDiscountPriceObjectArray
                            ?.filter((item) => item.pid === "20307")
                            ?.map((item, idx) => {
                              return (
                                <Title level={4} className={style.titleH3_2}>
                                  團購價：{item?.discount}折 {item?.price}元
                                </Title>
                              );
                            })}
                          <Title
                            level={4}
                            className={`${style.titleH4_3} courseCollapse courseCollapse-0`}
                          >
                            課程介紹
                          </Title>
                        </div>
                      </Col>
                      <div
                        className={`${style.gbPanel} coursePanel coursePanel-1`}
                      >
                        　　本課程適用三歲前嬰幼兒家長或保姆，感覺統合理論由美國南加大A.
                        Jean
                        Ayres博士提出，結合神經生理、神經心理學的理論，透過七感的輸入調整幫助孩子大腦神經對於各類刺激做出合適的反應，進而促進孩子大腦健全發展，職能治療師常透過感統來改善孩子常跌倒、力道控制不得宜、衝動、情緒起伏大、專注力差、學習障礙、行為問題、語言發展遲緩、坐立不安、感到沮喪、具有攻擊傾向、與別人談話時會靠得過近等問題。課程包含70種以上遊戲，搭配不同月齡有不同玩法，透過遊戲幫助孩子發展，促進親子互動，寓教於樂。遊戲設計取材自生活中、教室裡常見的資源與用品，非常便利！已服務超過4100名學員，歡迎加入！
                        <div className={`${style.gbDivAntButtonCourseContent}`}>
                          <AntButton
                            className={`${style.gbAntButtonCourseContent}`}
                            onClick={(e) => {
                              e?.preventDefault();
                              window.open("https://kid-pro.com/product/si0-2");
                            }}
                          >
                            詳細介紹
                          </AntButton>
                        </div>
                      </div>
                    </Row>
                  </Col>
                </Row>
                <Row className={style.gbRowCourse}>
                  <Col className={style.gbColCourse212Title}>
                    <Title level={3} className={style.titleH3_4}>
                      2歲以上必修
                    </Title>
                  </Col>
                  <Col className={style.gbColCourse212Content}>
                    <Row className={style.gbRowCourseImg}>
                      <Col className={style.gbColCourseImg}>
                        <img
                          className={style.gbImgCoursePhone}
                          src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/16fe41e6-39b5-4739-ec3f-b9014e474800/public"
                          onClick={() => {
                            window.open("https://kid-pro.com/product/si-2-12/");
                          }}
                        ></img>
                        <div
                          className={style.gbColCourseDescription}
                          style={{ marginRight: 12 }}
                          onClick={(e) => {
                            collapseToggle(e, 2);
                          }}
                        >
                          <Title level={4} className={style.titleH4}>
                            兒童 感覺統合訓練課程
                          </Title>
                          {courseSelectBtnState[2] === "Selected" ? (
                            <Title
                              level={4}
                              className={style.titleH4_2_lineThrough}
                            >
                              原價：5800元
                            </Title>
                          ) : (
                            <Title level={4} className={style.titleH4_2}>
                              原價：5800元
                            </Title>
                          )}
                          {coursePidDiscountPriceObjectArray
                            ?.filter((item) => item.pid === "100358")
                            ?.map((item, idx) => {
                              return (
                                <Title level={4} className={style.titleH3_2}>
                                  團購價：{item?.discount}折 {item?.price}元
                                </Title>
                              );
                            })}
                          <Title
                            level={4}
                            className={`${style.titleH4_3} courseCollapse courseCollapse-0`}
                          >
                            課程介紹
                          </Title>
                        </div>
                      </Col>
                      <div
                        className={`${style.gbPanel} coursePanel coursePanel-2`}
                      >
                        　　本課程適用2-12歲兒童家長與幼教人員，內含61個遊戲，160種玩法，遊戲道具多數取材自日常生活常見的資源與道具，是促進親子感情、幫助孩子感統發展的最佳課程。感覺統合理論由美國南加大A.
                        Jean
                        Ayres博士提出，結合神經生理、神經心理學的理論，透過七感的輸入調整幫助孩子大腦神經對於各類刺激做出合適的反應，進而促進孩子大腦健全發展，職能治療師常透過感統來改善孩子常跌倒、力道控制不得宜、衝動、情緒起伏大、專注力差、學習障礙、行為問題、語言發展遲緩、坐立不安、感到沮喪、具有攻擊傾向、與別人談話時會靠得過近等問題。我們教您如何不必花大錢促進感統，有趣又有用！適用年齡廣泛，隨時可以和老師討論，透過感覺輸入量的調整，量身打造最適合孩子的遊戲。學員超過2500名，深受老師、保母、家長們的喜愛。
                        <div className={`${style.gbDivAntButtonCourseContent}`}>
                          <AntButton
                            className={`${style.gbAntButtonCourseContent}`}
                            onClick={(e) => {
                              e?.preventDefault();
                              window.open(
                                "https://kid-pro.com/product/si-2-12"
                              );
                            }}
                          >
                            詳細介紹
                          </AntButton>
                        </div>
                      </div>
                    </Row>
                    <Row className={style.gbRowCourseImg}>
                      <Col className={style.gbColCourseImg}>
                        <img
                          className={style.gbImgCoursePhone}
                          src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/f75dbe6d-2a89-457b-3018-e10af6302700/public"
                          onClick={() => {
                            window.open("https://kid-pro.com/product/ae3-1/");
                          }}
                        ></img>
                        <div
                          className={style.gbColCourseDescription}
                          style={{ marginRight: 12 }}
                          onClick={(e) => {
                            collapseToggle(e, 3);
                          }}
                        >
                          <Title level={4} className={style.titleH4}>
                            兒童 注意力與執行力訓練課程
                          </Title>
                          {courseSelectBtnState[3] === "Selected" ? (
                            <Title
                              level={4}
                              className={style.titleH4_2_lineThrough}
                            >
                              原價：5800元
                            </Title>
                          ) : (
                            <Title level={4} className={style.titleH4_2}>
                              原價：5800元
                            </Title>
                          )}
                          {coursePidDiscountPriceObjectArray
                            ?.filter((item) => item.pid === "60464")
                            ?.map((item, idx) => {
                              return (
                                <Title level={4} className={style.titleH3_2}>
                                  團購價：{item?.discount}折 {item?.price}元
                                </Title>
                              );
                            })}
                          <Title
                            level={4}
                            className={`${style.titleH4_3} courseCollapse courseCollapse-0`}
                          >
                            課程介紹
                          </Title>
                        </div>
                      </Col>
                      <div
                        className={`${style.gbPanel} coursePanel coursePanel-3`}
                      >
                        　　注意力與執行功能常影響孩子學習表現以及生活品質，研究證實我們可以透過一系列相關遊戲，我們可以幫助孩子提供注意力與執行功能，本課程適用3-12歲兒童家長與幼教人員，內容將介紹何謂5大注意力、何謂3大執行功能、並提供您一套教具，透過簡單又好玩的教具，幫助您與孩子一起玩注意力、執行功能的訓練遊戲，我們已經根據孩子年齡設計難度，您更可以根據孩子的能力調整難度，與孩子一起挑戰難關。一套遊戲可以玩好幾年，透過我們的實際示範影片，完整呈現現實狀況。除了遊戲訓練，也提供在生活中實際提高注意力的方法！學員超過2700名，歡迎一同加入！
                        <div className={`${style.gbDivAntButtonCourseContent}`}>
                          <AntButton
                            className={`${style.gbAntButtonCourseContent}`}
                            onClick={(e) => {
                              e?.preventDefault();
                              window.open("https://kid-pro.com/product/ae3-1");
                            }}
                          >
                            詳細介紹
                          </AntButton>
                        </div>
                      </div>
                    </Row>
                    <Row className={style.gbRowCourseImg}>
                      <Col
                        className={style.gbColCourseImg}
                        style={{ paddingBottom: "5px" }}
                      >
                        <img
                          className={style.gbImgCoursePhone}
                          src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/ac858e5e-5d4f-4d8f-31d2-9f5a64842800/public"
                          onClick={() => {
                            window.open(
                              "https://kid-pro.com/product/oral-2-12/"
                            );
                          }}
                        ></img>
                        <div
                          className={style.gbColCourseDescription}
                          style={{ marginRight: 12 }}
                          onClick={(e) => {
                            collapseToggle(e, 4);
                          }}
                        >
                          <Title level={4} className={style.titleH4}>
                            兒童/成人 玩美臉型自信開口
                          </Title>
                          <Title level={4} className={style.titleH4}>
                            全方位口肌訓練課程
                          </Title>
                          {courseSelectBtnState[4] === "Selected" ? (
                            <Title
                              level={4}
                              className={style.titleH4_2_lineThrough}
                            >
                              原價：8800元
                            </Title>
                          ) : (
                            <Title level={4} className={style.titleH4_2}>
                              原價：8800元
                            </Title>
                          )}
                          {coursePidDiscountPriceObjectArray
                            ?.filter((item) => item.pid === "231206")
                            ?.map((item, idx) => {
                              return (
                                <Title level={4} className={style.titleH3_2}>
                                  團購價：{item?.discount}折 {item?.price}元
                                </Title>
                              );
                            })}
                          <Title
                            level={4}
                            className={`${style.titleH4_3} courseCollapse courseCollapse-0`}
                          >
                            課程介紹
                          </Title>
                        </div>
                      </Col>
                      <div
                        className={`${style.gbPanel} coursePanel coursePanel-4`}
                      >
                        　　本課程由對口肌訓練專家王亦群語言治療師設計，適合大人與小孩，耳鼻喉科、牙科醫師、家長、希望臉型更玩美的朋友。有健康的口肌對於健康十分重要，更幫助您的臉型漸趨玩美。透過口腔與臉部肌肉的訓練，可以從根本改善臉型、口呼吸，咬字發音、咀嚼、打呼、流口水等等問題。
                        我們幫每位學員準備了價值超過1200元的專業教具，您只要跟著老師的影片一步步練習，持之以恆，可以鍛鍊口腔與臉部肌肉。解決因口腔肌肉力量不足所產生的一系列問題，讓臉型更美觀、更健康自信。2023年7月上架已超過650名學員搶購！
                        <div className={`${style.gbDivAntButtonCourseContent}`}>
                          <AntButton
                            className={`${style.gbAntButtonCourseContent}`}
                            onClick={(e) => {
                              e?.preventDefault();
                              window.open(
                                "https://kid-pro.com/product/oral-2-12"
                              );
                            }}
                          >
                            詳細介紹
                          </AntButton>
                        </div>
                      </div>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col className={style.gbMain} style={{ padding: 24 }}>
                <Row style={{ marginInline: 0 }}>
                  <Col
                    className={style.memberTableCol}
                    style={{ background: "#FFF" }}
                  >
                    <div className={style.memberDescriptDiv}>
                      <Title level={3} className={style.titleH3_6}>
                        自己揪團
                      </Title>
                    </div>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={courseSelectBtnState[0] === "Selected"}
                            disabled={courseSelectBtnState[0] === "HasCourse"}
                            onChange={(e) => {
                              if (courseSelectBtnState[0] === "Select")
                                changeCourseSelectBtnState(e, 0, "Selected");
                              else changeCourseSelectBtnState(e, 0, "Select");
                            }}
                          />
                        }
                        label="嬰幼兒 父母必修課"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={courseSelectBtnState[1] === "Selected"}
                            disabled={courseSelectBtnState[1] === "HasCourse"}
                            onChange={(e) => {
                              if (courseSelectBtnState[1] === "Select")
                                changeCourseSelectBtnState(e, 1, "Selected");
                              else changeCourseSelectBtnState(e, 1, "Select");
                            }}
                          />
                        }
                        label="嬰幼兒 感覺統合啟蒙課程"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={courseSelectBtnState[2] === "Selected"}
                            disabled={courseSelectBtnState[2] === "HasCourse"}
                            onChange={(e) => {
                              if (courseSelectBtnState[2] === "Select")
                                changeCourseSelectBtnState(e, 2, "Selected");
                              else changeCourseSelectBtnState(e, 2, "Select");
                            }}
                          />
                        }
                        label="兒童 感覺統合訓練課程"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={courseSelectBtnState[3] === "Selected"}
                            disabled={courseSelectBtnState[3] === "HasCourse"}
                            onChange={(e) => {
                              if (courseSelectBtnState[3] === "Select")
                                changeCourseSelectBtnState(e, 3, "Selected");
                              else changeCourseSelectBtnState(e, 3, "Select");
                            }}
                          />
                        }
                        label="兒童 注意力與執行力訓練課程"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={courseSelectBtnState[4] === "Selected"}
                            disabled={courseSelectBtnState[4] === "HasCourse"}
                            onChange={(e) => {
                              if (courseSelectBtnState[4] === "Select")
                                changeCourseSelectBtnState(e, 4, "Selected");
                              else changeCourseSelectBtnState(e, 4, "Select");
                            }}
                          />
                        }
                        label="兒童/成人 玩美臉型自信開口 全方位口肌訓練課程"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row
                  className={`${style.gbRowAntButton}`}
                  style={{ top: 0, marginInline: 0, background: "#FFF" }}
                >
                  <AntButton
                    className={style.gbAntButton}
                    onClick={(e) => {
                      e?.preventDefault();
                      if (pidSelectedArray?.length < 1 && !catchErrorBtnState) {
                        alert("請選擇至少一門課程！");
                      } else if (catchErrorBtnState) {
                        alert("目前無法取得您的購課資訊，請稍後再嘗試！");
                      } else {
                        conversionApiAddToCart(pidSelectedArray);
                        setGbPage("CheckoutPageCreateTeam");
                        setTeamId("");
                      }
                    }}
                  >
                    揪新團＋
                  </AntButton>
                </Row>
              </Col>
              <GroupBuyListTeamsComponent
                gbPage={gbPage}
                setGbPage={setGbPage}
              ></GroupBuyListTeamsComponent>
              <Row className={style.gbBottomRow0}>
                <Col className={style.gbBottomRow0Col}></Col>
              </Row>

              <Row className={style.gbBottomRow1}>
                <Col className={style.gbBottomRow1Col}></Col>
              </Row>

              {userTeamArray?.length > 0 ? (
                userTeamArray?.map((item, idx) => {
                  if (item?.members?.length < 3) {
                    return (
                      <section id="createTeam" className={style.gbSecNewGroup}>
                        <Row id={item?._id} className={style.gbRowNewGroup}>
                          <Title level={3} className={style.titleH3_7}>
                            我的團
                          </Title>
                          <section
                            className={style.gbRowSecNewGroupDescription}
                            key={idx}
                          >
                            <Row className={style.gbRowSecNewGroupOrderNum}>
                              <Row
                                className={style.gbRowSecNewGroupOrderNumLine1}
                              >
                                <Title
                                  level={3}
                                  className={`${style.titleH3_8} ${style.titleH3_8_OrderNum}`}
                                >
                                  團購編號：{item?.orderNum}
                                </Title>
                              </Row>
                            </Row>
                            <Row className={style.gbRowSecNewGroupDescription1}>
                              <Row
                                className={
                                  style.gbRowSecNewGroupDescription1Line1
                                }
                              >
                                {item?.products?.includes("33589") && (
                                  <img
                                    className={
                                      style.gbRowSecNewGroupDescription1Img
                                    }
                                    src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/8d6b5aa3-343e-4635-7133-c77801ab8200/public"
                                    alt="圖片1"
                                    onClick={(e) => {
                                      e?.preventDefault();
                                      window.open(
                                        "https://kid-pro.com/product/cc-0-2/"
                                      );
                                    }}
                                  />
                                )}
                                {item?.products?.includes("20307") && (
                                  <img
                                    className={
                                      style.gbRowSecNewGroupDescription1Img
                                    }
                                    src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/8b0380e3-4473-41f5-40df-5f19d1262200/public"
                                    alt="圖片2"
                                    onClick={(e) => {
                                      e?.preventDefault();
                                      window.open(
                                        "https://kid-pro.com/product/si0-2/"
                                      );
                                    }}
                                  />
                                )}
                                {item?.products?.includes("100358") && (
                                  <img
                                    className={
                                      style.gbRowSecNewGroupDescription1Img
                                    }
                                    src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/16fe41e6-39b5-4739-ec3f-b9014e474800/public"
                                    alt="圖片3"
                                    onClick={(e) => {
                                      e?.preventDefault();
                                      window.open(
                                        "https://kid-pro.com/product/si-2-12/"
                                      );
                                    }}
                                  />
                                )}
                                {item?.products?.includes("60464") && (
                                  <img
                                    className={
                                      style.gbRowSecNewGroupDescription1Img
                                    }
                                    src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/f75dbe6d-2a89-457b-3018-e10af6302700/public"
                                    alt="圖片4"
                                    onClick={(e) => {
                                      e?.preventDefault();
                                      window.open(
                                        "https://kid-pro.com/product/ae3-1/"
                                      );
                                    }}
                                  />
                                )}
                                {item?.products?.includes("231206") && (
                                  <img
                                    className={
                                      style.gbRowSecNewGroupDescription1Img
                                    }
                                    src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/ac858e5e-5d4f-4d8f-31d2-9f5a64842800/public"
                                    alt="圖片5"
                                    onClick={(e) => {
                                      e?.preventDefault();
                                      window.open(
                                        "https://kid-pro.com/product/oral-2-12/"
                                      );
                                    }}
                                  />
                                )}
                                {!!item?.discount && (
                                  <Title level={3} className={style.titleH3_8}>
                                    享{Math.round(item?.discount * 100) / 10}
                                    折優惠
                                  </Title>
                                )}
                              </Row>
                              <Row
                                className={
                                  style.gbRowSecNewGroupDescription1Line2
                                }
                              >
                                <Title level={4} className={style.titleH4_4}>
                                  您尚未擁有此產品，將於成團時向您的信用卡請款，方可觀看課程
                                </Title>
                              </Row>
                            </Row>
                            <Row
                              className={style.gbRowSecNewGroupDescription2}
                              key={idx}
                            >
                              {item?.members?.map((item3, idx3) => (
                                <div
                                  className={`${style.gbTeamMemberDiv}`}
                                  key={idx3}
                                >
                                  {item3?.lineName ? (
                                    <>
                                      {item3?.linePictureUrl ? (
                                        <img
                                          className={
                                            style.gbRowSecNewGroupDescription3Img
                                          }
                                          src={item3?.linePictureUrl}
                                          alt="成員圖片"
                                          // onClick={(e) => {
                                          //   e?.preventDefault();
                                          //   window.open(`${item?.shortUrlCode}`);
                                          // }}
                                        />
                                      ) : (
                                        <img
                                          className={
                                            style.gbRowSecNewGroupDescription3Img
                                          }
                                          src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/88f2db74-a472-429c-30e5-25d299d2a400/public"
                                          alt="預設圖片"
                                          // onClick={(e) => {
                                          //   e?.preventDefault();
                                          //   window.open(`${item?.shortUrlCode}`);
                                          // }}
                                        />
                                      )}
                                    </>
                                  ) : (
                                    // <div id="PlusCircleOutlined" onClick={(e) => {
                                    //   e?.preventDefault();
                                    //   window.open(`${item?.shortUrlCode}`);
                                    // }}>
                                    //   <PlusCircleOutlined className={`${style.gbRowSecNewGroupDescription3Img} ${style.gbPlusIcon}`} />
                                    // </div>
                                    <img
                                      className={
                                        style.gbRowSecNewGroupDescription3Img
                                      }
                                      src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/88f2db74-a472-429c-30e5-25d299d2a400/public"
                                      alt="預設圖片"
                                      // onClick={(e) => {
                                      //   e?.preventDefault();
                                      //   window.open(`${item?.shortUrlCode}`);
                                      // }}
                                    />
                                  )}
                                  <Title
                                    level={4}
                                    className={`${style.titleH4_5} ${style.titleH4_8}`}
                                  >
                                    {item3?.lineName
                                      ? item3?.lineName
                                      : `成員#${item3?.userId}`}
                                  </Title>
                                </div>
                              ))}
                              {Array.from(
                                { length: 3 - item?.members?.length },
                                (_, i) => (
                                  <PlusCircleOutlined
                                    className={`${style.gbRowSecNewGroupDescription3Img} ${style.gbPlusIcon}`}
                                    key={i}
                                    // onClick={(e) => {
                                    //   e?.preventDefault();
                                    //   setGbPage("CheckoutPageAddTeam");
                                    //   setTeamId(item?._id);
                                    // }}
                                    onClick={(e) => {
                                      e?.preventDefault();
                                      window.open(`${item?.shortUrlCode}`);
                                    }}
                                  />
                                )
                              )}
                            </Row>
                            <Row
                              className={`${style.gbRowSecNewGroupDescription3} ${style.gbRowSecNewGroupDescription3Invite1}`}
                            >
                              <Title level={2} className={style.titleH2}>
                                請於
                                {dayjs(item?.createdAt)
                                  ?.add(7, "day")
                                  ?.format("YYYY年MM月DD日HH時mm分")}
                                前找到您的同伴
                              </Title>
                            </Row>
                            {/* <Row className={`${style.gbRowSecNewGroupDescription3} ${style.gbRowSecNewGroupDescription3Invite}`}> */}
                            <Row
                              className={`${style.gbRowSecNewGroupDescription3} ${style.gbRowSecNewGroupDescription3Invite2}`}
                            >
                              <Title level={3} className={style.titleH3_8}>
                                邀請同伴
                              </Title>
                              {/* <section className={`${style.gbRowSecRowSecInvite}`}> */}
                              {/* <div className={`${style.gbRowSecNewGroupDescription5Img} fb-share-button`} data-href={item?.shortUrlCode} data-action="like" data-layout="button" data-mobile-iframe="true" data-size="large">
                                <a target="_blank" href="" class="fb-xfbml-parse-ignore"></a>
                                <a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdevelopers.facebook.com%2Fdocs%2Fplugins%2F&amp;src=sdkpreparse" class="fb-xfbml-parse-ignore">分享</a>
                            </div> */}
                              {/* FB */}
                              <img
                                className={
                                  style.gbRowSecNewGroupDescription5Img
                                }
                                src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/671446d7-267e-4700-0338-59f4eff82000/public"
                                onClick={async (e) => {
                                  e?.preventDefault();
                                  if (utmEventsInit) {
                                    // utmShare(utm, `Facebook分享【${contentName}】`, `Facebook分享`);
                                    utmShare(
                                      utmKidPro,
                                      "",
                                      `Facebook分享【${contentName}】`,
                                      `Facebook分享`
                                    );
                                    utmShare(
                                      utmKidPro2,
                                      "",
                                      `Facebook分享【${contentName}】`,
                                      `Facebook分享`
                                    );
                                  }
                                  window.open(
                                    `https://www.facebook.com/sharer/sharer.php?u=${item?.shortUrlCode}&amp;src=sdkpreparse`
                                  );
                                }}
                              ></img>
                              {/* Messenger */}
                              <img
                                className={`${style.gbRowSecNewGroupDescription5Img} ms-share-button`}
                                onClick={async (e) => {
                                  e?.preventDefault();
                                  if (utmEventsInit) {
                                    // utmShare(utm, `Messenger分享【${contentName}】`, `Messenger分享`);
                                    utmShare(
                                      utmKidPro,
                                      "",
                                      `Messenger分享【${contentName}】`,
                                      `Messenger分享`
                                    );
                                    utmShare(
                                      utmKidPro2,
                                      "",
                                      `Messenger分享【${contentName}】`,
                                      `Messenger分享【`
                                    );
                                  }
                                  gotoMessengerUrl(e, item?.shortUrlCode);
                                }}
                                src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/83daa982-14ec-4af4-f9dd-36b3b9df3800/public"
                              ></img>
                              {/* <div className={`${style.gbRowSecNewGroupDescription5Img} line-it-button`} width="200px" height="200px" data-color="default" data-count="false" data-env="REAL" data-lang="zh_Hant" data-size="large" data-type="share-c" data-url={item?.shortUrlCode} data-ver="3"></div> */}
                              {/* Line */}
                              <img
                                className={
                                  style.gbRowSecNewGroupDescription5Img
                                }
                                src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/067b9e68-1de8-49fe-2b53-dc4c7e8ea700/public"
                                onClick={async (e) => {
                                  e?.preventDefault();
                                  if (utmEventsInit) {
                                    // utmShare(utm, `LINE分享【${contentName}】`,`LINE分享`);
                                    utmShare(
                                      utmKidPro,
                                      "",
                                      `LINE分享【${contentName}】`,
                                      `LINE分享`
                                    );
                                    utmShare(
                                      utmKidPro2,
                                      "",
                                      `LINE分享【${contentName}】`,
                                      `LINE分享`
                                    );
                                  }
                                  window.open(
                                    `https://social-plugins.line.me/lineit/share?url=${item?.shortUrlCode}`
                                  );
                                }}
                              ></img>
                              {/* IG */}
                              {/* <img className={style.gbRowSecNewGroupDescription5Img} src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/53a65ada-3533-45e1-683f-5c4a71ebe500/public" onClick={(e)=>{
                              e?.preventDefault();
                              window.open(`http://instagram.com/sharer.php?u=${item?.shortUrlCode}&media=https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/53a65ada-3533-45e1-683f-5c4a71ebe500/public&description=igShare${item?.shortUrlCode}`);
                            }}></img> */}
                              {/* </section> */}
                            </Row>
                            {/* 社群圖片測試 */}
                            {/* <img
                              className={style.gbRowSecNewGroupDescription5Img}
                              src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/671446d7-267e-4700-0338-59f4eff82000/public"
                            ></img>
                            <img
                              className={style.gbRowSecNewGroupDescription5Img}
                              src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/83daa982-14ec-4af4-f9dd-36b3b9df3800/public"
                            ></img>
                            <img
                              className={style.gbRowSecNewGroupDescription5Img}
                              src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/067b9e68-1de8-49fe-2b53-dc4c7e8ea700/public"
                            ></img>
                            <img
                              className={style.gbRowSecNewGroupDescription5Img}
                              src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/53a65ada-3533-45e1-683f-5c4a71ebe500/public"
                            ></img> */}
                            <Row className={style.gbRowSecNewGroupDescription4}>
                              <Col className={style.gbCopyCol}>
                                <p>{item?.shortUrlCode}</p>
                                <div className={style.gbCopyDiv}>
                                  {ShowCheckMark === item?._id ? (
                                    <img
                                      id={item?._id}
                                      className={style.gbRowSecHasCourseChecked}
                                      src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/18768ad9-f4be-4799-3418-f228ae341700/public"
                                      alt="已複製"
                                    />
                                  ) : (
                                    <button
                                      type="button"
                                      className={style.gbCopyButton}
                                      onClick={(e) => {
                                        copyText(e, item?._id);
                                      }}
                                    >
                                      複製連結
                                    </button>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </section>
                        </Row>
                      </section>
                    );
                  } else if (item?.members?.length === 3) {
                    return (
                      <section id="createTeam" className={style.gbSecNewGroup}>
                        <Row className={`${style.gbRowHasCourse}`}>
                          <section
                            className={`${style.gbRowSecHasCourseTitle}`}
                          >
                            <Title level={3} className={style.titleH3_7}>
                              已成團
                            </Title>
                            <Title
                              level={3}
                              className={`${style.titleH3_8} ${style.titleH3_8_OrderNum_HasCourse}`}
                            >
                              （團購編號：{item?.orderNum}）
                            </Title>
                          </section>
                          <section
                            className={`${style.gbRowSecHasCourseDescription}`}
                          >
                            <Row
                              className={`${style.gbRowSecHasCourseDescription1}`}
                            >
                              <Row
                                className={`${style.gbRowSecHasCourseDescription1Line1}`}
                              >
                                <Title level={2} className={style.titleH2_3}>
                                  恭喜您～用
                                  <div
                                    className={`${style.titleH2_3} ${style.titleH2_3_gbDiscount}`}
                                  >
                                    {Math.round(item?.discount * 100) / 10}
                                  </div>
                                  折優惠獲得課程
                                </Title>
                              </Row>
                              <Row
                                className={`${style.gbRowSecHasCourseDescription1Line2}`}
                              >
                                {item?.products?.includes("33589") && (
                                  <img
                                    className={`${style.gbRowSecHasCourseDescription1Img}`}
                                    src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/8d6b5aa3-343e-4635-7133-c77801ab8200/public"
                                    alt="圖片1"
                                    onClick={(e) => {
                                      e?.preventDefault();
                                      window.open(
                                        "https://kid-pro.com/product/cc-0-2/"
                                      );
                                    }}
                                  />
                                )}
                                {item?.products?.includes("20307") && (
                                  <img
                                    className={`${style.gbRowSecHasCourseDescription1Img}`}
                                    src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/8b0380e3-4473-41f5-40df-5f19d1262200/public"
                                    alt="圖片2"
                                    onClick={(e) => {
                                      e?.preventDefault();
                                      window.open(
                                        "https://kid-pro.com/product/si0-2/"
                                      );
                                    }}
                                  />
                                )}
                                {item?.products?.includes("100358") && (
                                  <img
                                    className={`${style.gbRowSecHasCourseDescription1Img}`}
                                    src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/16fe41e6-39b5-4739-ec3f-b9014e474800/public"
                                    alt="圖片3"
                                    onClick={(e) => {
                                      e?.preventDefault();
                                      window.open(
                                        "https://kid-pro.com/product/si-2-12/"
                                      );
                                    }}
                                  />
                                )}
                                {item?.products?.includes("60464") && (
                                  <img
                                    className={`${style.gbRowSecHasCourseDescription1Img}`}
                                    src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/f75dbe6d-2a89-457b-3018-e10af6302700/public"
                                    alt="圖片4"
                                    onClick={(e) => {
                                      e?.preventDefault();
                                      window.open(
                                        "https://kid-pro.com/product/ae3-1/"
                                      );
                                    }}
                                  />
                                )}
                                {item?.products?.includes("231206") && (
                                  <img
                                    className={`${style.gbRowSecHasCourseDescription1Img}`}
                                    src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/ac858e5e-5d4f-4d8f-31d2-9f5a64842800/public"
                                    alt="圖片5"
                                    onClick={(e) => {
                                      e?.preventDefault();
                                      window.open(
                                        "https://kid-pro.com/product/oral-2-12/"
                                      );
                                    }}
                                  />
                                )}
                              </Row>
                              <Row
                                className={`${style.gbRowSecHasCourseDescription1Line3}`}
                                onClick={(e) => {
                                  e?.preventDefault();
                                  window.open(
                                    `https://kid-pro.com/my-account/my-courses/`
                                  );
                                }}
                              >
                                <RightCircleOutlined
                                  className={style.gbAntIcon}
                                ></RightCircleOutlined>
                                <Title level={4} className={style.titleH4_6}>
                                  立即上課
                                </Title>
                              </Row>
                            </Row>
                          </section>
                        </Row>
                      </section>
                    );
                  } else {
                    return <></>;
                  }
                })
              ) : (
                <></>
              )}

              {/* <Row className={style.gbRowHasCourse}>
                <section className={style.gbRowSecHasCourseDescription}>
                  <Row className={style.gbRowSecHasCourseDescription1}>
                    <Row className={style.gbRowSecHasCourseDescription1Line1}>
                      <Title level={1} className={style.titleH1}>您已離開此團購</Title>
                    </Row>
                    <Row className={style.gbRowSecHasCourseDescription1Line2}>
                      <Title level={4} className={style.titleH4_5}>您已經擁有這些課程了<br></br>另外開團吧</Title>
                      <img className={style.gbRowSecHasCourseDescriptionImg} src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/ac858e5e-5d4f-4d8f-31d2-9f5a64842800/public"></img>
                    </Row>
                    <Row className={style.gbRowSecHasCourseDescription1Line3}>
                      <CloseCircleOutlined className={style.gbAntIcon}></CloseCircleOutlined><Title level={4} className={style.titleH4_6}>關閉</Title>
                    </Row>
                  </Row>
                </section>
              </Row> */}
              {/* <Row className={style.gbBottomRow1}>
                <Title
                  level={4}
                  className={style.titleH4_7}
                  onClick={(e) => {
                    const anchor = document.getElementById("anchor");
                    anchor.scrollIntoView({ behavior: "smooth" }, true);
                  }}
                >
                  ＋揪新團
                </Title>
              </Row> */}
              <Row className={`${style.gbRowNote}`}>
                <ul className={style.gbUlNote}>
                  <li className={`${style.gbLiNote}`}>
                    團購時間將於2024/3/7 23:59截止。
                  </li>
                  <li className={`${style.gbLiNote}`}>
                    本活動在組團時只會進行授權，並不會請款。您的卡號將會安全的保存在第三方刷卡公司，只有在成團時才會請款並且正式完成訂單，完成訂單後您馬上就可以觀看課程了！
                  </li>
                  <li className={`${style.gbLiNote}`}>
                    由於您無法重複購買同一門課程，當您加入多個團購，其中一團成團時，其他未成單的團當中，若和已成的團有重複的課程，這些未成的團，都會自動退出，您不用擔心會重複購課。
                  </li>
                  {/* <li className={`${style.gbLiNote}`}>本次合購屬於特別活動，不適用金銀卡會員優惠。</li> */}
                </ul>
              </Row>
              {/* <Row className={style.gbBottomRow2}>
                <Title level={4} className={style.titleH4}>KidPro團隊保留活動最終解釋權</Title>
              </Row> */}
              <div style={{ padding: "24px" }}></div>
            </Col>
            <Popup
              NOuaabindLine={NOuaabindLine}
              LoginToken={LoginToken}
            ></Popup>
          </Row>
        </Container>
      ) : gbPage === "CheckoutPage" && !!loginToken ? (
        <GroupBuyCheckout
          gbPage={gbPage}
          setGbPage={setGbPage}
        ></GroupBuyCheckout>
      ) : gbPage === "LandingPage" && !loginToken ? (
        <GroupBuy gbPage={gbPage} setGbPage={setGbPage}></GroupBuy>
      ) : (
        <></>
      )}
      {/* {showTapPayMessagePopup()} */}
      {TapPayMessagePopupCondition(
        tapPayMessagePopupShow,
        setTapPayMessagePopupShow,
        tapPayResult,
        tappayMessage,
        setGbPage,
        teamId,
        pidSelectedArray,
        userProductsArray
      )}
    </>
  );
}

export default GroupBuyCreateGroup;
