import React from 'react'
import './Footer.css'

function Footer() {
  return (
	<footer className="site-footer" id="colophon" itemID="#colophon" itemScope="itemScope" itemType="https://schema.org/WPFooter">
		<section className="site-footer-container" id="site-footer-container">
			<div className="site-primary-footer-wrap ast-builder-grid-row-container site-footer-focus-item ast-builder-grid-row-3-rheavy ast-builder-grid-row-tablet-3-equal ast-builder-grid-row-mobile-full ast-footer-row-inline ast-footer-row-tablet-stack ast-footer-row-mobile-stack" data-section="section-primary-footer-builder">
				<div className="ast-builder-grid-row-container-inner">
					<div className="ast-builder-footer-grid-columns site-primary-footer-inner-wrap ast-builder-grid-row">
						<div className="site-footer-primary-section-1 site-footer-section site-footer-section-1">
							<div className="footer-widget-area widget-area site-footer-focus-item" data-section="section-footer-menu">
								<div className="footer-bar-navigation">
									<nav aria-label="網站導覽" className="site-navigation ast-flex-grow-1 navigation-accessibility footer-navigation" id="footer-site-navigation" itemScope="itemScope" itemType="https://schema.org/SiteNavigationElement">
										<div className="footer-nav-wrap">
											<ul className="ast-nav-menu ast-flex astra-footer-vertical-menu astra-footer-tablet-horizontal-menu astra-footer-mobile-horizontal-menu" id="astra-footer-menu">
												<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-149181" id="menu-item-149181">
													<a className="menu-link" href="https://kid-pro.com/termanduse/" target='_blank'>使用者條款</a>
												</li>
												<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-149182" id="menu-item-149182">
													<a className="menu-link" href="https://kid-pro.com/privacy/" target='_blank'>隱私權條款</a>
												</li>
												<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-149183" id="menu-item-149183">
													<a className="menu-link" href="https://kid-pro.com/membership-right/" target='_blank'>會員權利</a>
												</li>
												<li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-149184" id="menu-item-149184">
													<a className="menu-link" href="https://kid-pro.com/refund/" target='_blank'>退費政策</a>
												</li>
											</ul>
										</div>
									</nav>
								</div>
							</div>
						</div>
						<div className="site-footer-primary-section-2 site-footer-section site-footer-section-2">
							<div className="footer-widget-area widget-area site-footer-focus-item ast-footer-html-2" data-section="section-fb-html-2">
								<div className="ast-header-html inner-link-style-">
									<div className="ast-builder-html-element">
										<div className="footer-nav-wrap">
											<ul className="ast-nav-menu ast-flex astra-footer-vertical-menu astra-footer-tablet-horizontal-menu astra-footer-mobile-horizontal-menu" id="astra-footer-menu">
												<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-131126" id="menu-item-131126">
													<a className="footer-2" href="https://kid-pro.com/dvsatpe" target='_blank'>社會責任</a>
												</li>
												<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-135746" id="menu-item-135746">
													<a className="footer-2" href="https://www.104.com.tw/company/1a2x6bm1rz?jobsource=tab_job_to_cs" rel="noreferrer nofollow" target="_blank">徵才資訊</a>
												</li>
												<li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-135747" id="menu-item-135747">
													<a className="footer-2" href="https://kid-pro.com/lec-huashan/" rel="nofollow noopener noreferrer" target="_blank">華山中心</a>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="site-footer-primary-section-3 site-footer-section site-footer-section-3"></div>
					</div>
				</div>
			</div>
			<div className="site-below-footer-wrap ast-builder-grid-row-container site-footer-focus-item ast-builder-grid-row-3-rheavy ast-builder-grid-row-tablet-3-equal ast-builder-grid-row-mobile-full ast-footer-row-inline ast-footer-row-tablet-stack ast-footer-row-mobile-stack" data-section="section-below-footer-builder">
				<div className="ast-builder-grid-row-container-inner">
					<div className="ast-builder-footer-grid-columns site-below-footer-inner-wrap ast-builder-grid-row">
						<div className="site-footer-below-section-1 site-footer-section site-footer-section-1"></div>
						<div className="site-footer-below-section-2 site-footer-section site-footer-section-2"></div>
						<div className="site-footer-below-section-3 site-footer-section site-footer-section-3">
							<div className="ast-builder-layout-element ast-flex site-footer-focus-item ast-footer-copyright" data-section="section-footer-builder">
								<div className="ast-footer-copyright">
									<div>
										Copyright © 2018-2023<br></br>
										KidPro醫學級兒童發展教育團隊
									</div>
									<div>
										華山教室：10049 台灣台北市中正區忠孝東路一段138號3樓(7-11樓上)<br></br>
										辦公室：10049 台灣台北市中正區忠孝東路一段83號15樓之2
									</div>
									<div>
										電話：+886-2-7756-6368<br></br>
										商務營運及專業內容：智寶教育股份有限公司-90849836｜技術開發：光立資訊有限公司-90699908
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</footer>
  )
}

export default Footer


