import React, { useState, useContext, useEffect, useRef } from 'react'
import AuthContext from '../login/components/AuthContext';
import LoginIndex from './LoginIndex'
import NoLineDataLogin from './NoLineDataLogin'
import spinner from '../../Components/Spinner/Spinner'
import Register from './Register'
import RegisterPhone from './RegisterPhone'
import RegisterPassword from './RegisterPassword'
import RegisterEmail from './RegisterEmail'
import RegisterSuccess from './RegisterSuccess'
import AccountExist from './AccountExist'
import AddFriend from './AddFriend';
import { Form } from 'react-bootstrap'
import FormPhoneUnlink from './FormPhoneUnlink'
import { set } from 'lodash';

function LoginParent(props) {
  const { authorized, email, setEmail, auth, setAuth, userNormalLoggedIn, setUserNormalLoggedIn, addFriendStatus, setAddFriendStatus } = useContext(AuthContext);
  // 此props NOuaabindLine from advertisingPage line_liff_login api uaa 與 line帳號未綁定
  const {NOuaabindLine,setNOuaabindLine,setClearData,ClearData,LoginToken} = props;
  const loginToken = window.localStorage.getItem('loginToken');
  let LIFF_STOREregister = window.localStorage.getItem('LIFF_STOREregister')
  const addFriend = window.localStorage.getItem('addFriend') ?? ''; // 記錄是否已加入官方帳號

  const [Sheet, setSheet] = useState('LoginIndex'); // 設定進入哪張表單
  const [smsveristatus, setsmsveristatus] = useState(false); // 檢查使用者是否有通過簡訊驗證，以啟用下一步按鈕
  // const [lineRegister, setLineRegister] = useState(false); // 使用者是否是以LINE註冊
  const userNormalLoggedInStorage = window.localStorage.getItem('userNormalLoggedIn'); // 使用者是否是以一般登入
  // const ifLineFriendStatus = false; //測試
  // useEffect(() => {
  //   // // // console.log('userNormalLoggedInStorage: ', userNormalLoggedInStorage);
  // }, [userNormalLoggedInStorage]);
  
  // const [userNormalLoggedIn, setUserNormalLoggedIn] = useState(false); // 使用者是否已登入
  // useEffect(() => {
  //   const query = new URLSearchParams(window.location.search);
  //   const userNormalLoggedIn = query.get('usrnmllogin');
  //   if(userNormalLoggedIn==='true') {
  //     setUserNormalLoggedIn(true);
  //   }
  // }, []);


  useEffect(() => { 
    // // console.log('Sheet: ', Sheet, '!! loginToken', !! loginToken, 'LIFF_STOREregister', LIFF_STOREregister, 'userNormalLoggedInStorage: ', userNormalLoggedInStorage, 'email: ',  email );
    // // console.log(! loginToken && LIFF_STOREregister==="true" && userNormalLoggedInStorage==="false" && email===null);
  }, [Sheet, loginToken, email, userNormalLoggedInStorage, LIFF_STOREregister]);

  // useEffect(() => {
  //   if( ! Sheet ) {
  //     setSheet('LoginIndex');
  //   }
  // }, []);

  return (
    <>
      {
        (() => {
            
            {/* 20230802之前的代碼 */}
            // if( Sheet === 'LoginIndex' && ! NOuaabindLine && email === null ) {
            //     return (
            //         <>
            //             {/* 預設sheet LoginIndex 為登入表單 */}
            //             <LoginIndex setSheet={setSheet} spinner={spinner}></LoginIndex>
            //         </>
            //     )
            // } else if( Sheet === 'NOuaabindLine' ) {
            //     return (
            //         <>
            //           {/* 登入表單，進行line登入後 liff_loginapi 回傳無uaa & line帳號未綁定，顯示要綁定舊Wordpress帳號，或新建Wordpress帳號並綁定 */}
            //           <NoLineDataLogin setSheet={setSheet} Sheet={Sheet} setNOuaabindLine={setNOuaabindLine} ></NoLineDataLogin>
            //         </>
            //     )
            // } else if( Sheet === 'register' ) {
            //   return(
            //     <> 
            //       {/* 註冊頁面(20230802改為只能用LINE註冊) */}
            //       <Register setSheet={setSheet} setsmsveristatus={setsmsveristatus} smsveristatus={smsveristatus}></Register>
            //     </>

            //   )
            // } else if( Sheet === 'registerPhone' ) {
            //   return(
            //     <> 
            //       {/* 手機註冊頁面 */}
            //       <RegisterPhone setSheet={setSheet} setsmsveristatus={setsmsveristatus} smsveristatus={smsveristatus}></RegisterPhone>
            //     </>

            //   )
            // } else if( Sheet === 'registerPassword' ) {
            //   return(
            //     <> 
            //       {/* 設定密碼頁面 */}
            //       <RegisterPassword setSheet={setSheet} setsmsveristatus={setsmsveristatus} smsveristatus={smsveristatus}></RegisterPassword>
            //     </>

            //   )
            // } else if( Sheet === 'registerEmail' ) {
            //   return(
            //     <> 
            //       {/* 設定Email頁面 */}
            //       <RegisterEmail setSheet={setSheet} setsmsveristatus={setsmsveristatus} smsveristatus={smsveristatus}></RegisterEmail>
            //     </>

            //   )
            // } else if( Sheet === 'registerSuccess' ) {
            //   return(
            //     <> 
            //       {/* 設定Email頁面 */}
            //       <RegisterSuccess setSheet={setSheet} setsmsveristatus={setsmsveristatus} smsveristatus={smsveristatus}></RegisterSuccess>
            //     </>
            //   )
            // } else if( Sheet !== 'register' && LIFF_STOREregister && email !== null ) {
            //   return(
            //     <> 
            //       {/* 登入表單，進行line登入後 liff_login api 回傳200，代表老會員進入帳號已存在  */}
            //       <AccountExist setSheet={setSheet} setClearData={setClearData} LoginToken={LoginToken}></AccountExist>
            //     </>
            //   )
            // } 
            // // else if ( Sheet==='formPhoneUnlink' ) {
            // //   <>
            // //     {/* 進入解除手機綁定表單 */}
            // //     <FormPhoneUnlink setSheet={setSheet} LoginToken={LoginToken}></FormPhoneUnlink>
            // //   </>
            // // }
            {/* END 20230802之前的代碼 */}

            {/* 20230802之後的代碼：強迫使用者用LINE登入 */}
            {/* ! LIFF_STOREregister => 不是點LINE註冊進來的，即由手機、Email、FB、Google登入進入； email === null => 有Wordpress Email帳號(LINE已綁定Wordpress帳號，判定是否進入AccountExist頁面，沒有此值立刻進入LoginIndex頁面) */}
            {/* 要強迫使用者使用LINE登入，需要對LIFF_STOREregistery做判定，使用者是點選LINE註冊，讓使用者跳過一般登入，進入LINE登入/註冊表單(Sheet='register') */}
            {/* 要強迫使用者使用LINE登入，需要對Wordpress email"是否存在"做判定。email不存在，表示未對使用者是否綁定LINE做驗證(還不知道使用者是否有綁定LINE)；有則表示已驗證過使用者有綁定LINE，才會進入帳號已存在是否要綁定表單 */}
            {/* line register必須優先判斷(上移)，Sheet改用是否為LoginIndex做判斷(原本是用是否點擊"立即註冊"來判斷是否進入register頁面) */}
            {/* 新增一個判斷是否使用者先用一般登入進入的參數userNormalLoggedIn，以決定使用者是進入Login Form或Register Form */}
            // console.log('Sheet', Sheet)
            // console.log('loginToken', loginToken)            
            // console.log('LIFF_STOREregister', LIFF_STOREregister)
            // console.log('userNormalLoggedInStorage', userNormalLoggedInStorage)
            // console.log('email', email)
            // console.log('addFriend', addFriend)
            // console.log('addFriendStatus', addFriendStatus)
            if( Sheet === 'LoginIndex' && ! loginToken && ! LIFF_STOREregister && ! userNormalLoggedInStorage && email === null ) {  
                return (
                    <>
                      {/* 預設sheet LoginIndex 為登入表單 */}
                      <LoginIndex setSheet={setSheet} Sheet={Sheet} spinner={spinner}></LoginIndex>
                    </>
                )
            // } else if( Sheet === 'register' && email === null && userNormalLoggedInStorage === "true" ) {  
            } else if( Sheet === 'NOuaabindLine' || ( Sheet === 'LoginIndex' && ! loginToken && LIFF_STOREregister === "true" && ! userNormalLoggedInStorage && email === null ) ) {
              return ( 
                  <>
                    {/* 登入表單，進行line登入後 liff_loginapi 回傳無uaa & line帳號未綁定，顯示要綁定舊Wordpress帳號，或新建Wordpress帳號並綁定 */}
                    <NoLineDataLogin setSheet={setSheet} Sheet={Sheet} setNOuaabindLine={setNOuaabindLine} ></NoLineDataLogin>
                  </>
              )
            // } else if( Sheet === 'register' || ( Sheet === 'LoginIndex' && ! loginToken && email === null && ( userNormalLoggedInStorage === "true" || userNormalLoggedInStorage === "false" ) ) ) {
            } else if( Sheet === 'register' || ( Sheet === 'LoginIndex' && ! loginToken && ! LIFF_STOREregister  && userNormalLoggedInStorage === "true" && email === null ) 
              // || ( Sheet === 'LoginIndex' && !! loginToken && ! LIFF_STOREregister  && userNormalLoggedInStorage === "true" && email === null && ( ! addFriend || addFriend === "false" ) ) 
            ) {
              return (
                  <>
                      {/* 註冊頁面(20230802改為只能用LINE註冊) */}
                      <Register Sheet={Sheet} setSheet={setSheet} setsmsveristatus={setsmsveristatus} smsveristatus={smsveristatus}></Register>
                  </>
              )
            } else if( Sheet === 'registerPhone' ) {
              return(
                <> 
                  {/* 手機註冊頁面 */}
                  <RegisterPhone setSheet={setSheet} setsmsveristatus={setsmsveristatus} smsveristatus={smsveristatus}></RegisterPhone>
                </>

              )
            } else if( Sheet === 'registerPassword' ) {
              return(
                <> 
                  {/* 設定密碼頁面 */}
                  <RegisterPassword setSheet={setSheet} setsmsveristatus={setsmsveristatus} smsveristatus={smsveristatus}></RegisterPassword>
                </>

              )
            } else if( Sheet === 'registerEmail' ) {
              return(
                <> 
                  {/* 設定Email頁面 */}
                  <RegisterEmail setSheet={setSheet} setsmsveristatus={setsmsveristatus} smsveristatus={smsveristatus}></RegisterEmail>
                </>

              )
            } else if( Sheet === 'registerSuccess' ) {
              return(
                <> 
                  {/* 設定Email頁面 */}
                  <RegisterSuccess setSheet={setSheet} setsmsveristatus={setsmsveristatus} smsveristatus={smsveristatus}></RegisterSuccess>
                </>
              )
            {/* LIFF_STOREregister 有值，表示使用者有點選LINE註冊；Email有值，表示後台已有此使用者資料(已完成LINE綁定) */}
            } else if( Sheet !== 'register' && LIFF_STOREregister && email !== null ) {
              return(
                <> 
                  {/* 登入表單，進行line登入後 liff_login api 回傳200，代表老會員進入帳號已存在  */}
                  <AccountExist setSheet={setSheet} setClearData={setClearData} LoginToken={LoginToken}></AccountExist>
                </>
              )
            // } else if( ! addFriend || addFriend === "false" ) {  // 未加入官方帳號
            //   return (
            //     <>
            //         {/* 預設sheet LoginIndex 為登入表單 */}
            //         <AddFriend setSheet={setSheet} spinner={spinner}></AddFriend>
            //     </>
            //   )
            } else {  // ( 預設不顯示，設定放於主頁 )
              return (
                <>
                    {/* 預設sheet LoginIndex 為登入表單 */}
                    <LoginIndex setSheet={setSheet} spinner={spinner}></LoginIndex>
                </>
              )
            }
            {/* END 20230802之後的代碼：強迫使用者用LINE登入 */}
            
        })()
      }
    </>
  )
}

export default LoginParent