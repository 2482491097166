import React, { useState, useContext, useRef } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";

/* 這裡是由uaa login表單整合過來的 */
import { Formik } from "formik";
import * as yup from "yup";
import { Col, Container, Form, Row } from "react-bootstrap";
import style from "../UaaPage/login/styles/LoginIndex.module.css";
import KidProLoginMark from "../UaaPage/login/imgs/KidProLoginMark.svg";
import divLine from "../UaaPage/login/imgs/divLine.svg";
import FBIcon from "../UaaPage/login/imgs/FBIcon.svg";
import LINEIcon from "../UaaPage/login/imgs/LINEIcon.svg";
import GoogleIcon from "../UaaPage/login/imgs/GoogleIcon.svg";
import Dropdown from "react-bootstrap/Dropdown";
import CountryData from "../UaaPage/login/coutryCode.json";
// import { sendRegisterVeriLink } from '../components/ModuleSendEmail'
import { sha256 } from "js-sha256";
import LoginParent from "../UaaPage/login/LoginParent";
// import cart from './shopping-cart.svg'
import popupStyle from "./styles/popup.module.css";
import liff from "@line/liff/dist/lib";
import AuthContext from "../UaaPage/login/components/AuthContext";
// import PopupBanner from '../UaaPage/login/imgs/PopupBanner.png';
import { useCookies } from "react-cookie"; // 引入useCookies hook
import ReactGA from "react-ga4"; // GA4
import ReactPixel from "react-facebook-pixel"; // FB Pixel

/* MUI */
import LoginIcon from "@mui/icons-material/Login";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";

/* Constants */
const isLineBrowser = /Line/i.test(navigator.userAgent); // 是否為Line瀏覽器

const Popup = (props) => {
  const { authorized, setAuth, auth } = useContext(AuthContext);
  const { NOuaabindLine } = props;

  const [show, setShow] = useState(false);
  const handleClose = () => {
    window.localStorage.removeItem("LIFF_STOREregister");
    setShow(false);
  };
  const handleShow = () => setShow(true);
  let loginToken = window.localStorage.getItem("loginToken");
  const userNormalLoggedInStorage =
    window.localStorage.getItem("userNormalLoggedIn"); // 知道使用者是否先前是用一般登入

  /* 案X清除Local Storage並登出LINE、FB */
  const clearStorage = (event) => {
    event.preventDefault();
    // 如果LINE有登入，執行LINE登出
    const isLoggedIn = liff.isLoggedIn();
    if (isLoggedIn) {
      liff.logout();
    }
    // 如果FB物件存在，且為登入中，將FB登出
    if (!!window.FB) {
      window.FB.getLoginStatus(function (response) {
        if (response.status === "connected") {
          window.FB.logout(function (response) {
            // console.log('handleFBLogout', response);
          });
        }
      });
    }
    // localStorage.clear(); // 20230802 註解此段，避免自動登出
  };

  /*** FB Pixel 相關 ***/
  const FbPixelID = process.env.REACT_APP_FB_PIXEL_ID;
  // Pixel組態設定
  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };
  ReactPixel.init(FbPixelID, {}, options);
  // ReactPixel.pageView(); // For tracking page view
  // ReactPixel.trackCustom('瀏覽【完成LINE綁定送銀卡會員－活動頁】', {content_category: 'kidpro_marketing_page', content_ids: 'GrantMembership', content_name: '完成LINE綁定送銀卡會員－活動頁' });
  const pixelLogout = () => {
    ReactPixel.trackCustom("【登出】", {
      content_category: "kidpro_marketing_submit",
      content_ids: "logout",
      content_name: "登出",
    });
  };
  // const clickCC02 = () => {
  //   ReactPixel.trackCustom('點選【嬰幼兒父母必修課】', {content_category: 'kidpro_online_course', content_ids: 'cc-0-2', content_name: '嬰幼兒父母必修課', content_type: 'product',
  //   currency: 'TWD', value: 3990 });
  // }
  /*** END FB Pixel 相關 ***/

  /* 登出時，送資料給GA4 */
  const gaLogout = () => {
    ReactGA.event({
      category: "LogoutCategory",
      action: "logout",
      label: "Logout",
    });
    ReactGA.send({ hitType: "event" });
    // console.log('ReactGA',ReactGA);
  };

  /* 登出 */
  const logout = () => {
    gaLogout(); // 送出登出事件給GA4
    pixelLogout(); // 送出登出事件給Pixel
    // 如果FB物件存在，且為登入中，將FB登出
    if (!!window.FB) {
      window.FB.getLoginStatus(function (response) {
        if (response.status === "connected") {
          window.FB.logout(function (response) {
            // console.log('handleFBLogout', response);
          });
        }
      });
    }
    const isInClient = liff.isInClient();
    if (!isInClient) {
      // 以外部伺服器開啟網頁
      // 如果LINE有登入，執行LINE登出
      const isLoggedIn = liff.isLoggedIn();
      if (isLoggedIn) {
        liff.logout();
        window.localStorage.setItem("LIFF_STORE", "");
      }
      // 清除所有localStorage
      localStorage.clear();
      // window.location.assign( window.location.protocol + "//" + window.location.host );
      // 設定Navigator登出後的導向位置
      // console.log("window.location.pathname.toLowerCase().indexOf('groupbuy') > -1",window.location.pathname.toLowerCase().indexOf('groupbuy') > -1)
      if (
        window.location.pathname.toLowerCase().indexOf("grantmembership") > -1
      ) {
        // console.log('grantmembership');
        window.location.href = "/GrantMembership";
      } else if (
        window.location.pathname.toLowerCase().indexOf("groupbuy") > -1 &&
        window.location.pathname.toLowerCase().indexOf("groupbuylistteams") ===
          -1
      ) {
        // console.log('grantmembership');
        window.location.href = "/GroupBuy";
      } else if (
        window.location.pathname.toLowerCase().indexOf("groupbuylistteams") > -1
      ) {
        // console.log('grantmembership');
        window.location.href = "/GroupBuyListTeams";
      } else {
        // console.log('/');
        window.location.href = "/";
      }
    } else {
      // 以Liff開啟網頁
      window.localStorage.removeItem("username");
      window.localStorage.removeItem("smsVeriStatus");
      window.localStorage.removeItem("phoneNumber");
      window.localStorage.removeItem("url");
      if (
        window.confirm(
          "此頁面由LINE預設瀏覽器開啟，無法登出LINE。 請問是否要關閉LINE瀏覽器？"
        )
      ) {
        liff.closeWindow(); // 關閉Liff
      } else {
        // 設定Navigator登出後的導向位置
        if (
          window.location.pathname.toLowerCase().indexOf("grantmembership") > -1
        ) {
          window.location.href = "/GrantMembership";
        } else if (
          window.location.pathname.toLowerCase().indexOf("groupbuy") > -1 &&
          window.location.pathname
            .toLowerCase()
            .indexOf("groupbuylistteams") === -1
        ) {
          // console.log('grantmembership');
          window.location.href = "/GroupBuy";
        } else if (
          window.location.pathname.toLowerCase().indexOf("groupbuylistteams") >
          -1
        ) {
          // console.log('grantmembership');
          window.location.href = "/GroupBuyListTeams";
        } else {
          window.location.href = "/";
        }
      }
      // window.location.href = '/?openExternalBrowser=1';
    }
  };

  return (
    <>
      {/* 20230802強迫使用者用LINE登入以前的版本 */}
      {/* { ! loginToken && authorized !== true ? ( */}

      {/* 20230802強迫使用者用LINE登入以後的版本 */}
      {(!loginToken && authorized !== true) ||
      (!loginToken && userNormalLoggedInStorage === "true") ? (
        <>
          {/* 舊版本(登入 / 註冊) */}
          {/* <Button
            className={`btn btn-light border border-secondary ${popupStyle.loginButton}`}
            variant="primary"
            onClick={handleShow}
          >
            <img className={popupStyle.cartIcon} src={cart} />
            <span className={popupStyle.loginText}> 登入 / 註冊</span>
          </Button> */}
          <div
            style={{
              marginBlock: "4px",
              display: "flex",
              justifyContent: "flex-end",
              flexWrap: "wrap",
            }}
          >
            <a
              style={{ marginBlock: "4px" }}
              href="https://kid-pro.com/my-account/#registration-form"
              target="_blank"
              rel="noreferrer"
            >
              <Button
                className={`btn btn-light border border-secondary ${popupStyle.loginButton}`}
                variant="primary"
              >
                <AppRegistrationIcon style={{ color: "#666" }} />
                <span className={popupStyle.loginText}> 註冊</span>
              </Button>
            </a>
            <Button
              style={{ marginBlock: "4px" }}
              className={`btn btn-light border border-secondary ${popupStyle.loginButton}`}
              variant="primary"
              onClick={handleShow}
            >
              <LoginIcon style={{ color: "#666" }} />
              <span className={popupStyle.loginText}> 登入</span>
            </Button>
          </div>
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
          >
            <Col className={popupStyle.modal}>
              {/* <Modal.Header className={popupStyle.modalHeader} closeButton>
          <Modal.Title className={popupStyle.modalTitle} ></Modal.Title>
        </Modal.Header> */}
              <div
                className={popupStyle.modalClose}
                onClick={(event) => {
                  handleClose();
                  clearStorage(event);
                }}
              >
                x
              </div>
              <div className={popupStyle.modalHeader}>
                <Modal.Title className={popupStyle.modalTitle}></Modal.Title>
                <div className={popupStyle.PopupBanner}>
                  <img
                    src={
                      "https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/307b61fa-e830-473f-55f4-c2446e462c00/public"
                    }
                  ></img>
                </div>
              </div>
              <Modal.Body className={popupStyle.modalBody}>
                <LoginParent
                  className={popupStyle.modalLoginParent}
                  handleShow={handleShow}
                  handleClose={handleClose}
                  setShow={setShow}
                ></LoginParent>
              </Modal.Body>
            </Col>
          </Modal>
        </>
      ) : !isLineBrowser ? (
        <>
          <Button
            className={`btn btn-light border border-secondary ${popupStyle.loginButton}`}
            variant="primary"
            onClick={logout}
          >
            <img
              className={popupStyle.cartIcon}
              alt="logout"
              src={
                "https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/54bb9d2c-4bbc-4c66-3136-a6bec42b1f00/public"
              }
            />
            <span className={popupStyle.loginText}> 登出</span>
          </Button>
        </>
      ) : (
        <>
          <Button
            className={`btn btn-light border border-secondary ${popupStyle.loginButton}`}
            variant="primary"
            onClick={() => {
              window.sessionStorage.setItem("autoLogin", "false");
              logout();
            }}
          >
            <img
              className={popupStyle.cartIcon}
              alt="logout"
              src={
                "https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/54bb9d2c-4bbc-4c66-3136-a6bec42b1f00/public"
              }
            />
            <span className={popupStyle.loginText}> 登出</span>
          </Button>
        </>
      )}
    </>
  );
};
// }

export default Popup;
